import React, { useState } from "react";
import NavBar from "./Navbar/NavBar.jsx";
import FooterComponent from "./Footer/FooterComponent.jsx";
import "./PageLayoutStyles.css";
import { Outlet } from "react-router";
import { SearchContext } from "../contexts/SearchContext.jsx";

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */

export const HomePageLayout = (props) => {
  const [query, setQuery] = useState("");
  return (
    <>
      <SearchContext.Provider value={{ query, setQuery }}>
        <NavBar />
        <Outlet />
        <FooterComponent />
      </SearchContext.Provider>
    </>
  );
};
