import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../reducers/sessionSlice";
import { Envelope } from "react-bootstrap-icons";

export default function LoginError() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  return (
    <div
      id="section0"
      className="py-4 bg-main d-flex flex-column justify-content-center align-items-center main-section vh-lg-100">
      <div className="my-auto">
        <img
          alt="logo"
          id="main-image"
          className="mx-auto d-block"
          src="https://language-profile.azurewebsites.net/static/images/main.png"
        />
        <h1 className="text-center pt-5 fw-bold mb-0 pb-4">
          Oops! What happened?
        </h1>
        {user && (
          <div className="card p-4 mb-4">
            <p className="text-danger text-center fw-bold">Error Message</p>
            <p className="text-center mb-2">{user.error}</p>
          </div>
        )}

        {/* <p className="text-center m-0">
          It's likely your email address was incorrectly inputted, or that you
          don't have an account.
        </p> */}

        <div className="d-flex justify-content-center">
          <button
            type="submit"
            onClick={() => navigate("/support")}
            class="btn btn-primary d-flex align-items-center">
            <Envelope className="my-auto me-2" />
            Request Help
          </button>
        </div>
      </div>
    </div>
  );
}
