import React, { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-main px-1 px-lg-5 py-5">
      <div className="text-center mb-4">
        <div className="bg-main my-auto">
          <a href="/">
            <img
              alt="Logo"
              id="main-image"
              className="mx-auto d-block"
              src="https://language-profile.azurewebsites.net/static/images/main.png"
            />
          </a>

          <h1 className="text-center title">
            <span>Privacy Policy</span>
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="card shadow p-4 mx-0 mx-lg-5">
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">1. INTRODUCTION</h4>
            <p>Effective: June 6, 2023</p>
            <p>
              At Pongo ("us", "we", "our" or the "Company") we value your
              privacy and recognize the importance of safeguarding your data.
              This Privacy Policy (the "Policy") describes our privacy practices
              for the data we collect, store, access, use and otherwise disclose
              from the individuals with whom we interact (“you” or “your”).
            </p>
            <p>
              Ultimately, we only use your Personal Data, as it is defined
              below, to further the purposes of our Company – specifically to:
            </p>
            <ul>
              Teach children by facilitating motivation, self-assessment,
              formative assessment, and agency
            </ul>
            <ul>
              Enahnce edcators' ability to tailor teaching through
              individualized goals, targeted assessment, facilitated diagnostic
              and formative assessment
            </ul>
            <ul>Inform families of learner progress</ul>
            <ul>Grow our user base, and</ul>
            <ul>
              • Ensure the ongoing functionality of our product and service
              offering.
            </ul>
            <p>
              You can contact us anytime with questions about this policy at
              anthony@pongolearning.com
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              2. What is personal data?
            </h4>
            <p>
              In this Policy, personal data (“Personal Data”) refers to any
              information that on its own, or in combination with other
              available information, can identify you as an individual. For us,
              Personal Data includes, but is not limited to:
            </p>
            <ol>
              <li>Demographic: Name, age, usernames, profile photos</li>
              <li>Educational: grade level, languages taught</li>
              <li>Technological: devices used, email addresses</li>
            </ol>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              3. WHAT DO WE DO TO ENSURE WE’RE ACCOUNTABLE?
            </h4>
            <p>
              We maintain internal practices and procedures to ensure that we
              comply with this Policy as well as update it as our service and
              activities change. We endeavour to maintain, and periodically
              update, practices and procedures that:
            </p>
            <ol>
              <li>
                Define the purposes of our collection, use, and disclosure of
                your Personal Data;
              </li>
              <li>
                Obtain valid and meaningful consent for that collection, use,
                and disclosure;
              </li>
              <li>
                Limit collection, use, and disclosure of your personal
                information to only what is necessary for our purposes;
              </li>
              <li>
                Ensure your personal information is correct, complete and
                current;
              </li>
              <li>
                Ensure security measures are adequate to protect your Personal
                Data;
              </li>
              <li>
                Response to complaints, inquiries and requests to access your
                Personal Data;
              </li>
              <li>Address breach and incident-management protocols;</li>
              <li>Assess potential risks to your Personal Data;</li>
              <li>
                Ensure third-parties only use your Personal Data in accordance
                with this Policy; and
              </li>
              <li>Deliver appropriate privacy training to our personnel</li>
            </ol>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              4. WHO DO WE COLLECT PERSONAL DATA FROM?
            </h4>
            <p>
              We collect Personal Data from users who create accounts with us,
              including teachers, administrators, students and family members.
              Most of the time we collect Personal Data directly from you
              through the applications and software we provide.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              5. HOW DO WE OBTAIN CONSENT TO COLLECT PERSONAL DATA AND HOW DO WE
              COLLECT IT?
            </h4>
            <p>
              When you use Pongo, your consent will be implied in certain
              circumstances, most often when you willingly and intentionally
              provide us with your Personal Data, including electronically.
            </p>
            <p>
              We also rely on third parties for your consent when you access our
              offering using other services that you have disclosed your
              personal information to, and those services are permitted to
              disclose it to us. Examples of this include a social network
              account you use to log into your account or devices on which
              you’ve enabled Personal Data to be shared.
            </p>
            <p>
              We may also seek your express consent to the collection, use or
              disclosure of your personal information. We will seek your express
              consent when it is highly sensitive or required for a new purpose.
              We may obtain your consent by your checking of a box, your
              acquiescence to an agreement or by you taking another affirmative
              action.
            </p>
            <p>
              The content, including Personal Data, that you provide, as well as
              the content provided about you from related users, is collected.
              When using a third-party sign-in service, such as Google and
              Microsoft, your email address is collected.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              6. HOW DO WE USE PERSONAL DATA?
            </h4>
            <p>
              We use the Personal Data we collect to further the purposes we
              mentioned above. Your Personal Data is used for the following:
            </p>
            <ol>
              <li>Allowing you to sign-in by verifying your credentials;</li>
              <li>
                Delivering tailored lessons to help you attain your educational
                goals;
              </li>
              <li>Informing parents and family members of your progress;</li>
              <li>
                Providing support and information to teachers and
                administrators;
              </li>
              <li>
                Show administrators how the app is preforming in schools; and
              </li>
              <li>Research and to improve and market the application.</li>
            </ol>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              7. DOES PONGO ALLOW THIRD-PARTY ADVERTISING OR SHARE DATA FOR
              ADVERTISING THIRD-PARTY PRODUCTS?
            </h4>
            <p>No.</p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              8. WHEN WOULD PONGO SHARE MY INFORMATION?
            </h4>
            <p>
              To fulfill the purposes we list above, we share student Personal
              Data with teachers, parents, family members, and school
              administrators.
            </p>
            <p>
              We may also provide school administrators and researchers usage
              data, such as number of posts, likes and journal reflections. This
              will allow them to better judge the efficacy of the application in
              their schools or districts. We may also use this information to
              market Pongo to other schools, districts, and jurisdictions.
            </p>
            <p>
              To the maximum extent possible, we try to anonymize the Personal
              Data before we disclose it to or share it with anyone. However,
              when class sizes, schools, or groups are small, there is always
              the possibility that this anonymized information could be used to
              identify your Personal Data.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              8. WHO CAN VIEW PERSONAL DATA & CONTENT POSTED IN Pongo?
            </h4>
            <p>
              <span className="fw-bold">Students and Parents:</span>Teachers,
              Family Members and Administrators. Educators within the same
              education system may view teacher and student profiles during
              demos.
            </p>
            <p>
              <span className="fw-bold">Teachers:</span>Family Members and
              Administrators linked to the account
            </p>
            <p>
              Our web developers can view content posted in all profiles. This
              is to allow them to improve the application, but only to the
              extent necessary.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              10. WHO OWNS POSTED CONTENT?
            </h4>
            <p>
              Users maintain ownership of and are in control of the content they
              create and upload to Pongo.
            </p>
            <p>
              Content is, however, deleted permanently after 1-year of
              inactivity.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              11. DO YOU WORK WITH THIRD-PARTY ANALYTICS SERVICES AND USE
              COOKIES?
            </h4>
            <p>
              Cookies are used to store users' language preferences. Third-party
              analytics services are not used.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">12. ABANDONED ACCOUNTS</h4>
            <p>
              Accounts that are inactive for more than a year will be deleted.
              Pongo will attempt to notify these users.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              13. ACCOUNT SUSPENSION AND TERMINATION
            </h4>
            <p>
              Please contact anthony@pongolearning.com to have your account
              permanently deleted. If you terminate your account, all
              information associated with your account will be lost.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              14. ADDITIONAL ITEMS ON USE, DISCLOSURE & RETENTION
            </h4>
            <p>
              Pongo uses, discloses and retains personal information only for
              the purpose of providing apo service. It does not benefit or
              profit from student personal information. It does not profile
              children for marketing purposes or in ways that lead to unfair,
              unethical or discriminatory treatment. It does not repurpose
              student data or use it for research without express consent,
              unless authorized by statute or anonymized. Pongo securely
              destroys or makes anonymous in a timely manner all personal
              information that is no longer required to provide the app and
              explicitly identifies the 1-year retention timeline.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">
              15. HOW DO WE KEEP YOUR DATA SAFE?
            </h4>
            <p>
              Pongo has measures in place to protect your information, including
              the use of access-controlled data centers, parameterized database
              queries, restricted personnel access to user information, and data
              encryption in transit. Pongo does not collect any username or
              password combinations as a security measure. In the event of a
              security breach, Pongo will notify affected account holders within
              the amount of time, if required by law, so that you can take steps
              to keep your data safe.
            </p>
          </div>
          <div className="mb-5">
            <h4 className="fw-bold text-uppercase">15. CONTACT INFORMATION</h4>
            <p>
              If you have any question or feedback about or privacy policy,
              contact anthony@pongolearning.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
