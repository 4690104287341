import React from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import { selectAllProgress } from "../../reducers/progressSlice";
import ProgressBar from "react-bootstrap/ProgressBar";
import { evalScaleEn, evalScaleFr } from "../AddGoal/AddGoalForm";
import { selectAllPosts } from "../../reducers/postsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import { useTranslation } from "react-i18next";

export default function GoalCardModal(props) {
  const { t, i18n } = useTranslation();
  const evalScale = i18n.language === "en" ? evalScaleEn : evalScaleFr;
  const user = useSelector(selectUser);
  const progress = useSelector((state) => selectAllProgress(state, user.id));
  let posts = useSelector(selectAllPosts);
  posts = posts?.filter((item) => item.post.goal_id === props.goalselected);
  const goals = props.goals;
  const goal = goals?.filter((goal) => goal.id === props.goalselected);
  const strand = goal[0]?.strand;
  const strand_fr = goal[0]?.strand_fr;
  const level = progress?.filter((item) => item.strand === strand);
  let selfEval = parseInt(goal[0]?.selfeval);
  const onPost = (goalId) => {
    props.setgoalselected(goalId);
    props.setmakepostmodalshow(true);
    props.setShowModal(false);
  };
  const onFilter = (goalId) => {
    props.setfilterposts({ strand: "All", goalId: goalId });
    props.setShowModal(false);
  };
  // Test updated grades against initial grade
  for (let post of posts) {
    if (post.post.updated_grade > selfEval) {
      selfEval = post.post.updated_grade;
    }
  }

  const img = strand
    ? `https://language-profile.azurewebsites.net/static/images/goal_icons/${strand}_goals/${strand}1.png`
    : null;

  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className="m-1">
        <Card className="border-0">
          <Card.Body>
            <div className="d-flex justify-content-center">
              <img
                alt="Profile"
                src={img}
                className="mx-auto"
                style={{ width: "150px" }}
              />
            </div>
            <Card.Title className="pt-3 text-center">
              {level[0]?.overall_cefr_grade}{" "}
              {i18n.language === "en" ? strand : strand_fr}
            </Card.Title>
            <Card.Text className="pb-2 text-wrap" style={{ hyphens: "auto" }}>
              {i18n.language === "en"
                ? goal[0]?.statement
                : goal[0]?.statement_fr}
            </Card.Text>
            <ProgressBar
              now={(selfEval / Object.keys(evalScale).length) * 100}
              className="m-0 p-0 mx-auto"
              striped
              style={{ height: "10px", width: "67%" }}
            />
            <div className="text-xs text-center pt-1">
              <span>{evalScale[selfEval]}</span>
              <p className="text-secondary mb-0">
                {selfEval} {t("goalsCarousel.out of")} 4
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center mt-3">
              <button
                className="btn btn-outline-primary me-1"
                onClick={() => onPost(props.goalselected)}>
                {t("makePost.Post")}
              </button>
              <button
                className="btn btn-outline-primary"
                onClick={() => onFilter(props.goalselected)}>
                {t("makePost.Sort Posts")}
              </button>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}
