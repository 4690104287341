import React, { useState, useEffect } from "react";
import MetricsCards from "../../Metrics/MetricsCards";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../reducers/sessionSlice";

import {
  fetchAdminMetrics,
  selectAdminMetrics,
} from "../../../reducers/adminMetricsSlice";
import BarGraph from "../../Metrics/BarGraph";
import LineGraph from "../../Metrics/LineGraph";

export default function AdminMetricsSkeleton() {
  const dispatch = useDispatch();
  const [flashing, setFlashing] = useState(true);
  const user = useSelector(selectUser);
  const adminMetricsStatus = useSelector((state) => state.adminMetrics.status);
  const adminMetrics = useSelector(selectAdminMetrics);
  const [passData, setPassData] = useState([]);
  const [userCountData, setUserCountData] = useState([]);
  const [inProgressData, setInProgressData] = useState([]);
  const [teacherEngagementData, setTeacherEngagementData] = useState([]);
  const [studentEngagementData, setStudentEngagementData] = useState([]);
  const [loginTimestamps, setLoginTimestamps] = useState([]);

  useEffect(() => {
    if (adminMetrics) {
      let passCount = 0;
      let inProgressCount = 0;
      let userCount = 0;
      let studentEngagement = [];
      let teacherEngagement = [];
      let loginData = [];

      for (const [key, value] of Object.entries(adminMetrics)) {
        passCount += value.pass;
        inProgressCount += value.in_progress;
        if (value.school_users) {
          userCount += value.school_users;
        }
        try {
          for (let timestamp of value.login_data) {
            loginData.push(timestamp);
          }
        } catch {
          console.log(`No login data for school. Key: ${key}`);
        }

        for (let timestamp of value.posts) {
          studentEngagement.push(timestamp);
        }
        for (let timestamp of value.student_replies) {
          studentEngagement.push(timestamp);
        }
        for (let timestamp of value.teacher_replies) {
          teacherEngagement.push(timestamp);
        }
      }
      setFlashing(false);
      setPassData(passCount);
      setInProgressData(inProgressCount);
      setStudentEngagementData(studentEngagement);
      setTeacherEngagementData(teacherEngagement);
      setUserCountData(userCount);
      setLoginTimestamps(loginData);
    }
  }, [adminMetrics, setPassData, setInProgressData]);
  useEffect(() => {
    if (adminMetricsStatus === "idle" && user) {
      dispatch(fetchAdminMetrics(user?.district_id));
    }
  }, [adminMetricsStatus, user, dispatch]);
  return (
    <>
      {/* <h5 className="text-secondary text-uppercase text-center">
        {user.district_name} Metrics
      </h5> */}
      {/* Message for Small and Extra-small Screens */}
      <div className="d-block d-md-none">
        <p className="text-center text-danger text-small mt-3">
          All metrics data cannot be shown on mobile devices. Login using a
          laptop or desktop to see all your district data.
        </p>
      </div>
      <MetricsCards
        goalsCompleted={passData}
        goalsInProgress={inProgressData}
        postCount={studentEngagementData.length + teacherEngagementData.length}
        userCount={userCountData}
        flashing={flashing}
      />
      {/* LineGraph */}
      <div className="d-none d-md-block">
        <div className="d-flex flex-row justify-content-around mb-3 me-2">
          <div className="col-12 col-lg-7">
            <LineGraph data={loginTimestamps} loading={flashing} />
          </div>
          <div className="col-12 col-lg-5">
            <BarGraph
              label={"Student"}
              postData={studentEngagementData}
              loading={flashing}
            />
          </div>
        </div>
      </div>

      {/* BarGraphs */}
      <div className="d-none d-md-block">
        <div className="d-flex flex-row justify-content-between">
          <div className="col-auto">
            <BarGraph
              label={"Teacher"}
              postData={teacherEngagementData}
              loading={flashing}
            />
          </div>
        </div>
      </div>
    </>
  );
}
