import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./CameraButtonStyles.css";

export default function CameraButton(props) {
  return (
    <div
      className="background ms-2 d-flex "
      onClick={() => {
        if (props.showimageupload) {
          props.setshowimageupload(false);
        } else {
          props.setshowimageupload(true);
        }
      }}>
      <div className="camera-icon text-dark d-flex justify-content-center ">
        <FontAwesomeIcon icon={faCamera} />
      </div>
    </div>
  );
}
