import React from "react";
import {
  Check2Circle,
  Clipboard2Check,
  GraphUpArrow,
  PencilSquare,
  Tools,
  Trophy,
} from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

export default function LessonPlanTemplate(props) {
  const iconClass = "fa-2x inline-block";
  const { t } = useTranslation();
  const TextArea = (
    <textarea
      value={props.criteria}
      disabled={props.searchActive}
      onChange={(e) => props.setCriteria(e.target.value)}
      className="w-100 rounded p-2"
      rows={3} // Set the number of rows (adjust as needed)
      placeholder="Students are working in a unit about rights and responsibilities, the activity should include cooperative learning, ensure that the lesson is appropriate for 14 year olds, etc."
    />
  );

  const firstRow = [
    {
      icon: <Trophy className={iconClass} />,
      title: t("lessonGenerator.Objective"),
      content: props.statement,
      col: "col-12 col-lg-8",
      loadingSkeleton: false,
      plan: "",
    },
    {
      icon: <GraphUpArrow className={iconClass} />,
      title: t("progress.Level"),
      content: props.level,
      col: "col-12 col-lg-4",
      loadingSkeleton: false,
      plan: "",
    },
    {
      icon: <Tools className={iconClass} />,
      title: t("lessonGenerator.Materials"),
      content: props.activity?.materials,
      loadingSkeleton: true,
      col: "col-12 col-lg-6",
      plan: "",
    },
    {
      icon: <Check2Circle className={iconClass} />,
      title: t("lessonGenerator.Assessment"),
      content: props.activity?.assessment,
      loadingSkeleton: true,
      col: "col-12 col-lg-6",
      plan: "",
    },
    {
      icon: <Clipboard2Check className={iconClass} />,
      title: "Plan",
      content: "",
      loadingSkeleton: true,
      plan: props.activity?.plan?.map((item, i) => {
        return (
          <div key={i} className="mt-3">
            <p className="fw-bold text-uppercase text-small mb-0">
              <span>{i + 1}. </span>
              <span>{item.task}</span>
            </p>
            <p>{item.description}</p>
          </div>
        );
      }),
      col: "col-12 justify-content-start",
    },
    {
      icon: <PencilSquare className={iconClass} />,
      title: t("lessonGenerator.Additional Criteria"),
      content: "",
      col: "col-12",
      loadingSkeleton: false,
      plan: TextArea,
    },
  ];

  return (
    <>
      <h3 className="text-center text-uppercase fw-bold pb-2">
        {t("lessonGenerator.Lesson Plan")}
      </h3>
      <div className="row justify-content-between">
        {firstRow.map((item, i) => {
          return (
            <div key={i} className={`${item.col} card p-4`}>
              <div className={`d-flex flex-row align-items-center`}>
                <div className="me-4">{item.icon}</div>
                <div className={`d-flex flex-column justify-content-center`}>
                  <div className="text-uppercase fw-bold">{item.title}</div>
                  {props.searchActive && item.loadingSkeleton ? (
                    <Skeleton className="w-100" height={"2rem"} />
                  ) : (
                    <div className="text-small">{item.content}</div>
                  )}
                </div>
              </div>
              {item.plan && <div className="mt-2">{item.plan}</div>}
            </div>
          );
        })}
        <div></div>
      </div>
    </>
  );
}
