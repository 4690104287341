import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faComment } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectAllPosts } from "../../reducers/postsSlice";
import { MDBTooltip } from "mdb-react-ui-kit";

export default function PostCommentCounter(props) {
  const posts = useSelector(selectAllPosts);
  const postIndex = posts.findIndex((item) => item.post.id === props.id);
  const replies = posts[postIndex]?.replies;
  const replyCount = replies?.length;
  let usersWhoReplied = replies?.map((item) => item.first_name);
  // Removes duplicates
  usersWhoReplied = [...new Set(usersWhoReplied)];
  const replyList = usersWhoReplied?.map((item, i) => (
    <p key={i} className="m-0">
      {item}
    </p>
  ));

  const usersWhoLiked = posts[postIndex]?.post_likes?.users_who_liked;
  const likeCount = usersWhoLiked?.length;
  const likeList = usersWhoLiked?.map((item, i) => (
    <p key={i} className="m-0">
      {item}
    </p>
  ));

  return (
    <div className="d-flex flex-row justify-content-end align-content-center pb-2">
      {likeCount !== 0 && likeCount && (
        <MDBTooltip tag="div" placement="bottom" title={likeList}>
          <div className="d-flex flex-row pe-2">
            <p className="mb-0 pe-1">{likeCount}</p>
            <FontAwesomeIcon
              className="my-auto"
              style={{ color: "#0165E1" }}
              icon={faThumbsUp}
            />
          </div>
        </MDBTooltip>
      )}

      {replyCount !== 0 && replyCount !== null && (
        <MDBTooltip tag="div" placement="bottom" title={replyList}>
          <div className="d-flex flex-row ">
            <p className="mb-0 pe-1">{replyCount}</p>
            <FontAwesomeIcon
              className="text-secondary my-auto text-success"
              icon={faComment}
            />
          </div>
        </MDBTooltip>
      )}
    </div>
  );
}
