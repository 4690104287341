const alphabetize = (a, b) => {
  const lastNameA = a.last_name.toUpperCase();
  const lastNameB = b.last_name.toUpperCase();

  if (lastNameA < lastNameB) {
    return -1;
  }
  if (lastNameA > lastNameB) {
    return 1;
  }
  return 0;
};

export default alphabetize;
