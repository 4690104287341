import React from "react";
import ContactForm from "../../components/Contact/ContactForm";

export default function Support() {
  return (
    <div>
      <ContactForm />
    </div>
  );
}
