export const riskActivities = [
  {
    risk: 1,
    strand: "Speaking",
    risk_en: "I spoke to my teacher",
    risk_fr: "J’ai parlé à mon enseignant",
    status: 2,
  },
  {
    risk: 2,
    strand: "Speaking",
    risk_en: "I ordered food",
    risk_fr: "J'ai commandé à manger",
    status: 2,
  },
  {
    risk: 3,
    strand: "Speaking",
    risk_en: "I asked the time",
    risk_fr: "J’ai demandé l’heure",
    status: 1,
  },
  {
    risk: 4,
    strand: "Speaking",
    risk_en: "I asked for directions",
    risk_fr: "J'ai demandé mon chemin",
    status: 2,
  },
  {
    risk: 5,
    strand: "Speaking",
    risk_en:
      "I asked for information at an information desk (e.g., at a library)",
    risk_fr: "J'ai demandé de l’information à un comptoir d’information",
    status: 2,
  },
  {
    risk: 6,
    strand: "Speaking",
    risk_en: "I spoke to teacher outside of class hours",
    risk_fr: "J'ai parlé à un enseignant dehors des heures du cours",
    status: 1,
  },
  {
    risk: 7,
    strand: "Speaking",
    risk_en: "I spoke to a friend during class",
    risk_fr: "J’ai parlé à un.e ami.e pendant les heures du cours",
    status: 2,
  },
  {
    risk: 8,
    strand: "Speaking",
    risk_en: "I spoke to a friend outside of class hours",
    risk_fr: "J’ai parlé à un.e ami.e dehors des heures du cours",
    status: 0,
  },
  {
    risk: 9,
    strand: "Speaking",
    risk_en: "I spoke on the phone",
    risk_fr: "J'ai passé un appel téléphonique",
    status: 1,
  },
  {
    risk: 10,
    strand: "Speaking",
    risk_en: "I bought something",
    risk_fr: "J'ai acheté quelquechose",
    status: 2,
  },
  {
    risk: 11,
    strand: "Writing",
    risk_en: "I sent a letter",
    risk_fr: "J'ai envoyé une lettre",
    status: 0,
  },
  {
    risk: 12,
    strand: "Writing",
    risk_en: "I sent a text",
    risk_fr: "J’ai envoyé un texto",
    status: 1,
  },
  {
    risk: 13,
    strand: "Writing",
    risk_en: "I filled a form",
    risk_fr: "J’ai rempli un formulaire",
    status: 0,
  },
  {
    risk: 14,
    strand: "Writing",
    risk_en: "I posted something on social media",
    risk_fr: "J'ai posté quelque chose sur les réseaux sociaux.",
    status: 0,
  },
  {
    risk: 15,
    strand: "Writing",
    risk_en: "I did my homework",
    risk_fr: "J'ai remis mon devoir",
    status: 0,
  },
  {
    risk: 16,
    strand: "Reading",
    risk_en: "I read a book or magazine",
    risk_fr: "J'ai lu un livre ou une magazine",
    status: 1,
  },
  {
    risk: 17,
    strand: "Reading",
    risk_en: "I read an article",
    risk_fr: "J'ai lu un article",
    status: 2,
  },
  {
    risk: 18,
    strand: "Reading",
    risk_en: "I changed my phone or tablet settings for one day",
    risk_fr:
      "J'ai changé les réglages de mon portable ou ma tablette pendant une journée",
    status: 0,
  },
  {
    risk: 19,
    strand: "Reading",
    risk_en: "I changed my phone or tablet settings for one week",
    risk_fr:
      "J'ai changé les réglages de mon portable ou ma tablette pendant une semaine",
    status: 0,
  },
  {
    risk: 20,
    strand: "Reading",
    risk_en: "I searched for 10 new words",
    risk_fr: "J'ai recherché 10 nouveaux mots",
  },
  {
    risk: 21,
    strand: "Reading",
    risk_en: "I followed a recipe or procedure",
    risk_fr: "J'ai suivi une recette ou une texte procédural",
  },
  {
    risk: 22,
    strand: "Listening",
    risk_en: "I listened to music for an hour",
    risk_fr: "J'ai écouté la musique pendant une heure",
  },
  {
    risk: 23,
    strand: "Listening",
    risk_en: "I listened to 5 different artists",
    risk_fr: "J’ai écouté cinq artistes différentes",
    status: 2,
  },
  {
    risk: 24,
    strand: "Listening",
    risk_en: "I watched a show",
    risk_fr: "J'ai écouté une émission",
    status: 1,
  },
  {
    risk: 25,
    strand: "Listening",
    risk_en: "I watched a movie",
    risk_fr: "J’ai écouté un film",
    status: 2,
  },
];
