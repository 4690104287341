import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectAllPosts } from "../../reducers/postsSlice";
import Button from "react-bootstrap/Button";
import { selectUser } from "../../reducers/sessionSlice";

import {
  changeProgress,
  fetchProgressTeacher,
  selectAllProgressTeacher,
} from "../../reducers/progressTeacherSlice";

export default function PassModal(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const posts = useSelector(selectAllPosts);
  const data = posts?.filter((item) => item.post.id === props.id)[0].post;
  const studentId = data.author_id;
  const level = data.level;
  const strand = data.strand;
  const strand_fr = data.strand_fr;
  let progressObj = useSelector(selectAllProgressTeacher);
  progressObj = progressObj.filter((item) => item.studentId === studentId);
  // Select last entry in case goals were completed
  progressObj = progressObj[progressObj.length - 1];
  const progress = progressObj?.data;
  const goalsRemaining = progress?.find((item) => item.strand === strand)
    .remaining_goals.length;

  const passHandler = () => {
    let levelUp = false;
    // 0. If progress not already fetched, get progress
    if (!progress) {
      dispatch(fetchProgressTeacher({ userId: studentId })).unwrap();
    }

    // .remaining_goals.length;
    if (goalsRemaining === 1) {
      levelUp = true;
    }

    // 2. Post request to set to complete, with information from step #1 and get new stats
    dispatch(
      changeProgress({
        student_id: data.author_id,
        teacher_id: user?.id,
        attribute_name: "level",
        level: level,
        goal_id: data.goal_id,
        level_up: levelUp,
        manual_grade_change: false,
        change_goal_status: true,
        strand: strand,
        post_id: props.id,
        status: "Pass",
      })
    ).unwrap();
    props.handleClose();
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("passModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fw-bold m-0">{t("passModal.Student")}</p>
        <p>
          {props.first_name} {props.last_name}
        </p>
        <p className="fw-bold m-0">{t("passModal.Strand")}</p>
        <p>
          {level} {i18n.language === "en" ? strand : strand_fr}
        </p>
        <p className="fw-bold m-0">{t("passModal.Goal")}</p>
        <p> {i18n.language === "en" ? props.statement : props.statement_fr}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={passHandler}>
          {t("passModal.title")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
