import React from "react";
import Popover from "react-bootstrap/Popover";
import PopoverHeader from "react-bootstrap/PopoverHeader";
import PopoverBody from "react-bootstrap/PopoverBody";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function TextArea(props) {
  const { t } = useTranslation();
  const limit = 1000;
  const setReflection = props.setReflection;
  const reflection = props.reflection;
  const setFormattedContent = React.useCallback(
    (text) => {
      setReflection(text.slice(0, limit));
      if (reflection.length > 15) {
        setReflection(text);
      }
    },
    [limit, setReflection, reflection.length]
  );
  const popoverHoverFocus = (
    <Popover id="popover-basic" style={{ zIndex: 1151 }}>
      <PopoverHeader>{t("makePostModal.I need help")}!</PopoverHeader>
      <PopoverBody>
        <p className="fw-bold mb-0">{t("makePostModal.You can start with")}:</p>
        <p>{t("makePostModal.I am meeting my goal by")}...</p>
        <p className="fw-bold mb-0">{t("makePostModal.You can end with")}:</p>
        <p>{t("makePostModal.To improve, I will")}...</p>
      </PopoverBody>
    </Popover>
  );
  return (
    <div>
      <div className="row">
        <div className="col-auto">
          <label htmlFor="writeReflection" className="fw-bold">
            2. {t("makePostModal.Write a reflection")}
          </label>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={popoverHoverFocus}>
            <FontAwesomeIcon icon={faCircleQuestion} className="ms-2" />
          </OverlayTrigger>
        </div>
        <div className="col-auto ms-auto">
          {props.reflection.length < 15 ? (
            <span className={"text-danger text-small ms-auto"}>
              Min: {props.reflection.length}/15
            </span>
          ) : (
            <span className={"text-success text-small ms-auto"}>
              Max: {props.reflection.length}/{limit}
            </span>
          )}
        </div>
      </div>
      <textarea
        id="writeReflection"
        name="postContent"
        className="w-100 text-large p-2 m-1"
        value={props.reflection ? props.reflection : ""}
        onChange={(event) => {
          setFormattedContent(event.target.value);
        }}
        style={{ resize: "none" }}
        rows={3}
      />
    </div>
  );
}
