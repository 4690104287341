import React, { useContext, useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import logoLightbulbBlue from "../../assets/logo/logo_lighbulb_blue.png";
import { useNavigate } from "react-router";
import { CheckCircleFill, CircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import baseUrl from "../../utils/baseUrl";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate } from "react-router";

const DemoLogin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const navigate = useNavigate();

  const circleClass = (field) => {
    return !!field ? (
      <CheckCircleFill className="text-success me-3" />
    ) : (
      <CircleFill className="text-secondary me-3" />
    );
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(`${baseUrl}/demo_access`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username, password: password }),
      });
      const data = await response.json();
      if (data.success) {
        authCtx.authenticate({
          mail: data.email,
        });
        console.log("Logging in as:", authCtx.token);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return authCtx.isAuthenticated ? (
    <Navigate to="/wall" />
  ) : (
    <Container>
      <Row className="justify-content-md-center my-4">
        <Col md={4}>
          <div className="d-flex justify-content-center px-2">
            <img
              className="lightbulb"
              alt="logo"
              src={logoLightbulbBlue}
              style={{ width: "35%", height: "auto" }}
            />
          </div>
          <h2 className="mt-4 text-center fw-bold mb-3 text-pongo">
            {t(`demo.Demo Access`)}
          </h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUsername">
              <Form.Label>
                {circleClass(username)}
                {t(`demo.Username`)}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t(`demo.Enter a username`)}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label>
                {circleClass(password)}
                {t(`demo.Password`)}
              </Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder={t(`demo.Enter a password`)}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  variant="secondary"
                  onClick={togglePasswordVisibility}
                  className="ms-2">
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </div>
            </Form.Group>

            {error && (
              <p className="text-danger text-center mt-2">
                * {t(`demo.Invalid`)} *
              </p>
            )}
            <div className="d-flex flex-row align-items-center mt-3">
              <Button
                variant="success"
                onClick={handleSubmit}
                disabled={!username || !password}
                type="submit">
                {t(`navbar.Login`)}
              </Button>
              <div className="ms-auto">
                <a
                  href="/support"
                  className="text-success fw-bold me-auto m-0 cursor-pointer">
                  {t(`demo.Request Access`)}
                </a>
              </div>
            </div>

            <div onClick={() => navigate("/")} className="mt-5 cursor-pointer">
              <p className="text-pongo fw-bold">{t(`demo.Home Page`)}</p>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default DemoLogin;
