import React, { useContext, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SearchContext } from "../../contexts/SearchContext";
import {
  fetchGroupings,
  selectGroupNames,
  selectGroupings,
} from "../../reducers/groupingsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";

export default function SearchBar() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const groupNames = useSelector(selectGroupNames);
  const groupings = useSelector(selectGroupings);
  const { query, setQuery } = useContext(SearchContext);
  const { setQueriedStudents } = useContext(SearchContext);
  const groupingsStatus = useSelector((state) => state.groupings.status);
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      return false;
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value.toLowerCase());
  };

  const onClick = (e) => {
    if (groupingsStatus === "idle") {
      dispatch(fetchGroupings(user.id));
    }
  };

  const isQueryingByGroup = (string) => {
    for (let group of groupNames) {
      if (string && string.toLowerCase().includes(group.name.toLowerCase())) {
        return true;
      }
    }
  };

  const getGroupingId = (string) => {
    for (let group of groupNames) {
      if (string.toLowerCase().includes(group.name.toLowerCase())) {
        return group.id;
      }
    }
  };

  useEffect(() => {
    let list = [];
    teacherPermissions.map((student) => {
      let name = `${student?.first_name} ${student?.last_name}`;

      return (
        student && name.toLowerCase().includes(query) && list.push(student?.id)
      );
    });
    try {
      setQueriedStudents(list);
    } catch (error) {
      console.log(error);
    }
  }, [query, setQueriedStudents, teacherPermissions]);

  let groupingId = isQueryingByGroup(query) && getGroupingId(query);
  useEffect(() => {
    let list = [];
    groupings.map(
      (grouping) =>
        groupingId === grouping.grouping_id && list.push(grouping.student_id)
    );
    try {
      setQueriedStudents(list);
    } catch (error) {
      console.log(error);
    }
  }, [groupings, groupingId, setQueriedStudents]);

  return (
    <Row>
      <input
        id="searchBar"
        list="groupNames"
        type="search"
        style={{ width: "280px", border: "none", backgroundColor: "#F2F2F2" }}
        onChange={handleChange}
        onClick={onClick}
        placeholder={t("navbar.Search")}
        className="rounded-pill p-2"
        onKeyDown={handleKeyPress}
        aria-label="Search Pongo"
      />
      {groupNames.length !== 0 && (
        <datalist id="groupNames">
          {groupNames.map((item) => {
            return <option key={item.id} value={item.name} />;
          })}
        </datalist>
      )}
    </Row>
  );
}
