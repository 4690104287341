import React from "react";
import Select from "react-select";

export default function SelectSchool(props) {
  let schoolsList = props.schoolsList;
  schoolsList = [...schoolsList]?.sort((a, b) =>
    a.school_name?.localeCompare(b.school_name)
  );

  const schoolsOptions = schoolsList?.map((school) => ({
    label: school.school_name,
    value: school.school_id,
  }));
  const content =
    props.edit === true || props.edit === props.id ? (
      <Select
        className="search-select me-2"
        name="school_id"
        onChange={props.onChange}
        instanceId="schoolName"
        placeholder={"Select School Name"}
        isClearable
        options={schoolsOptions}
        value={schoolsOptions.filter((option) => option.value === props.value)}
      />
    ) : (
      <>
        <span className="m-0">
          {
            schoolsList?.find((school) => school.school_id === props.savedValue)
              ?.school_name
          }
        </span>
      </>
    );
  return content;
}
