import React, { useEffect } from "react";

export default function TextFilter({ list, setList, filter, setFilter }) {
  useEffect(() => {
    setList(list);
    const filteredList = list.filter(
      (value) =>
        value?.last_name?.toLowerCase().includes(filter.toLowerCase()) ||
        value?.first_name?.toLowerCase().includes(filter.toLowerCase()) ||
        value?.school_name?.toLowerCase().includes(filter.toLowerCase()) ||
        value?.teacher_email?.toLowerCase().includes(filter.toLowerCase()) ||
        value?.level?.toLowerCase().includes(filter.toLowerCase())
    );
    setList(filteredList);
  }, [filter, list, setList]);
  return (
    <>
      <input
        id="filter"
        name="filter"
        type="text"
        className="form-control w-25 mb-2 ms-auto"
        aria-describedby="filter"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
        placeholder="Type here to filter"
      />
      <p id="filter" className="d-none">
        Type here to filter
      </p>
    </>
  );
}
