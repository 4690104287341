import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (user_id) => {
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${baseUrl}/get_notifications/${user_id}`,
      settings
    );
    const notifications = await response.json();
    return notifications;
  }
);

export const markNotificationRead = createAsyncThunk(
  "notifications/markNotificationRead",
  async (notification_id) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${baseUrl}/mark_notification_read/${notification_id}`,
      settings
    );
    const notifications = await response.json();
    return notifications;
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",
  async (notification_id) => {
    const settings = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${baseUrl}/delete_notification/${notification_id}`,
      settings
    );
    const notifications = await response.json();
    return notifications;
  }
);

const initialState = {
  notifications: [],
  status: "idle",
  error: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    clearNotifications: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications.push(...action.payload);
        // Sort with newest first
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter(
          (notification) => notification.id !== action.payload.id
        );
      })
      .addCase(markNotificationRead.fulfilled, (state, action) => {
        const data = state.notifications;
        const index = state.notifications.findIndex(
          (item) => item.id === action.payload.id
        );
        const updatedNotification = {
          ...state.notifications[index],
          read: true,
        };
        return {
          ...state,
          notifications: [
            // create a new data array
            ...data.slice(0, index), // the posts before the updated comment
            updatedNotification, // the updated post
            ...data.slice(index + 1), // the posts after the updated comment
          ],
        };
      });
  },
});

export default notificationsSlice.reducer;

export const { clearNotifications } = notificationsSlice.actions;

export const selectAllNotifications = (state) =>
  state.notifications.notifications;
