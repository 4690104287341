import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Area, XAxis, YAxis, Tooltip, AreaChart } from "recharts";
import { COLORS } from "../../utils/colors";
import { RingLoader } from "react-spinners";
import { getMonthName } from "../../utils/monthNames";
import NoData from "./NoData";

const LineGraph = ({ data, loading }) => {
  const { i18n } = useTranslation();

  const monthCounts = useMemo(() => {
    if (!data) return [];
    return data.map((entry) => ({
      month: new Date(entry).getMonth(),
      year: new Date(entry).getFullYear(),
    }));
  }, [data]);

  const dataWithPosts = useMemo(() => {
    const countsMap = monthCounts.reduce((mp, o) => {
      mp.set(o.month, { ...o, posts: (mp.get(o.month)?.posts || 0) + 1 });
      return mp;
    }, new Map());
    return Array.from(countsMap.values()).sort((a, b) => {
      if (a.year === b.year) return a.month - b.month;
      return a.year - b.year;
    });
  }, [monthCounts]);

  const formattedData = useMemo(() => {
    return dataWithPosts.map((item) => ({
      ...item,
      month: getMonthName(item.month, i18n.language),
    }));
  }, [dataWithPosts, i18n.language]);

  const renderLineChart = (
    <AreaChart
      width={window.innerWidth * 0.45}
      height={300}
      data={formattedData}>
      <XAxis dataKey="month" />
      <YAxis dataKey="posts" />
      <Area
        type="monotone"
        dataKey="posts"
        stroke={COLORS[0]}
        fill={COLORS[4]}
      />
      <Tooltip content={<CustomTooltip />} />
    </AreaChart>
  );

  function CustomTooltip({ payload, label, active }) {
    if (active && payload && payload.length) {
      return (
        <div className="border border-secondary bg-white p-3">
          <p className="m-0 fw-bold text-small">{label || "Loading"}</p>
          <p className="m-0 text-small" style={{ color: COLORS[0] }}>
            # Logins: {payload[0]?.value || "Loading"}
          </p>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="card shadow h-100 me-2">
      <div className="card-header">Login Data</div>
      <div
        className="py-3 d-flex justify-content-center"
        style={{ height: 330 }}>
        {loading && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: 500, height: 300 }}>
            <RingLoader />
          </div>
        )}
        {!loading && !!data && data.length === 0 && <NoData />}
        {data.length !== 0 && renderLineChart}
      </div>
    </div>
  );
};

export default LineGraph;
