import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import CustomToggle from "../Dropdown/CustomToggle";
import CustomMenu from "../Dropdown/CustomMenu";
import { useTranslation } from "react-i18next";

import { ThreeDotsVertical, Trash, Trophy } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";

export default function PostMenuDropdown(props) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  return (
    <Dropdown className="change-goal-status-toggle">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <button
          type="button"
          className="btn btn-outline-secondary ms-2"
          onClick={props.onClick}
          aria-label="Menu">
          <ThreeDotsVertical />
        </button>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} labeledBy="Change Status">
        {user.role === "Teacher" && (
          <Dropdown.Item
            as="button"
            className="text-small"
            eventKey={0}
            onClick={props.manualPassHandler}>
            <Trophy className="me-2" /> {t("dropdowns.Change Status")}
          </Dropdown.Item>
        )}
        <Dropdown.Item
          as="button"
          eventKey={1}
          onClick={props.onHideForDelete}
          className="text-small">
          <Trash className="me-2" /> {t("dropdowns.Delete")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
