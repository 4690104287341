import React from "react";
import { useTranslation } from "react-i18next";
import { MDBTooltip } from "mdb-react-ui-kit";

export default function CefrExplained() {
  const { t } = useTranslation();
  const levelDescriptions = [
    { level: "A1", name: `${t("cefr.Basic")}`, description: "" },
    { level: "A2", name: `${t("cefr.Basic")}+`, description: "" },
    { level: "B1", name: `${t("cefr.Independent")}`, description: "" },
    { level: "B2", name: `${t("cefr.Independent")}+`, description: "" },
    { level: "C1", name: `${t("cefr.Proficient")}`, description: "" },
  ];
  return (
    <>
      <div className="d-flex flex-row justify-content-around pb-3 ">
        {levelDescriptions.map((item, i) => {
          return (
            <div key={i}>
              <MDBTooltip
                tag="div"
                placement="top"
                title={t(`cefr.${item.level} Explained`)}>
                <div className="text-center">
                  <div
                    className="mx-auto card p-2 hover-effect d-flex justify-content-center align-items-center bg-light"
                    style={{
                      borderRadius: "100%",
                      width: "50px",
                      height: "50px",
                    }}>
                    <p className="m-0 fw-bold">{item.level}</p>
                  </div>
                  <div>
                    <p className="m-0 text-xxs text-secondary text-uppercase">
                      {item.name}
                    </p>
                  </div>
                </div>
              </MDBTooltip>
            </div>
          );
        })}
      </div>
    </>
  );
}
