const appStrands = {
  en: [
    "overall",
    "reading",
    "writing",
    "listening",
    "speaking",
    "academic",
    "social",
  ],
  fr: ["globale", "lire", "écrire", "écouter", "orale", "scolaire", "social"],
};

export default appStrands;
