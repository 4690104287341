import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import CustomToggle from "../Dropdown/CustomToggle";
import { Search, Robot, ThreeDotsVertical } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import CustomMenu from "../Dropdown/CustomMenu";
import "./Styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkAddGoals,
  changeProgress,
} from "../../reducers/progressTeacherSlice";
import { selectUser } from "../../reducers/sessionSlice";
import "../../App.css";
import PostsCompletedGoalsModal from "../PostsCompletedGoals/PostsCompletedGoalsModal";

export default function GoalStatusDropdown(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [showPostsCompletedModal, setShowPostsCompletedModal] = useState(false);
  const openCompletedGoalPosts = () => {
    setShowPostsCompletedModal(true);
  };

  const closeCompletedGoalPosts = () => {
    setShowPostsCompletedModal(false);
  };
  const changeGoalStatusHandler = (status) => {
    let levelUp = false;

    if (status === "In Progress") {
      dispatch(
        bulkAddGoals({
          strand: props.strand,
          students: [props.studentId],
          cefr_custom_id: props.cefr_custom_id,
        })
      ).unwrap();
    } else if (status === "Pass") {
      dispatch(
        changeProgress({
          student_id: props.studentId,
          teacher_id: user?.id,
          level: false,
          cefr_custom_id: props.cefr_custom_id,
          level_up: levelUp,
          manual_grade_change: false,
          change_goal_status: true,
          strand: props.strand,
          status: "Pass",
        })
      ).unwrap();
    } else if (status === "Incomplete") {
      dispatch(
        changeProgress({
          student_id: props.studentId,
          teacher_id: user?.id,
          level: false,
          cefr_custom_id: props.cefr_custom_id,
          level_up: levelUp,
          manual_grade_change: false,
          change_goal_status: true,
          strand: props.strand,
          status: "Pass",
        })
      ).unwrap();
    }
  };

  return (
    <>
      <Dropdown className="change-goal-status-toggle cursor-pointer appear-on-hover">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <ThreeDotsVertical className="gear text-secondary fa-xl" />
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} labeledBy="Change Status">
          <Dropdown.ItemText className="cursor-none fw-bold text-small">
            {t("dropdowns.Posts")}
          </Dropdown.ItemText>
          <Dropdown.Item eventKey={4} onClick={openCompletedGoalPosts}>
            <Search className="text-secondary fa-fw" />
            <span className="text-small ms-2">{t("dropdowns.Show Posts")}</span>
          </Dropdown.Item>
          {/* Teacher items */}
          {user.role === "Teacher" && (
            <>
              <Dropdown.ItemText className="cursor-none fw-bold text-small">
                {t("dropdowns.Change Status")}
              </Dropdown.ItemText>

              {props.statuses.map(
                (status, i) =>
                  status !== props.currentStatus && (
                    <Dropdown.Item
                      key={i}
                      eventKey={i}
                      onClick={() => changeGoalStatusHandler(status)}>
                      {status === "In Progress" && (
                        <FontAwesomeIcon icon={faCircle} color="goldenrod" />
                      )}
                      {status === "Pass" && (
                        <FontAwesomeIcon icon={faCircleCheck} color="green" />
                      )}
                      {status === "Incomplete" && (
                        <FontAwesomeIcon icon={faCircle} color="gray" />
                      )}
                      <span className="ms-2 text-small">
                        {t(`progress.${status}`)}
                      </span>
                    </Dropdown.Item>
                  )
              )}

              <Dropdown.ItemText className="cursor-none fw-bold text-small">
                {t("dropdowns.AI Activities")}
              </Dropdown.ItemText>
              <Dropdown.Item eventKey={5} onClick={props.findActivityHandler}>
                <Robot className="fa-fw" />
                <span className="ms-2 text-small">
                  {t("dropdowns.Generate")}
                </span>
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {showPostsCompletedModal && (
        <PostsCompletedGoalsModal
          show={showPostsCompletedModal}
          handleClose={closeCompletedGoalPosts}
          activeGoal={props.cefr_custom_id}
          activeStudent={props.studentId}
        />
      )}
    </>
  );
}
