import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import ProfileCard from "../profile/ProfileCard";
import ProgressTab from "./ProgressTab";
import GoalsAccordion from "./GoalsAccordion";
import {
  fetchProgressTeacher,
  selectAllProgressTeacher,
} from "../../reducers/progressTeacherSlice";
import { useTranslation } from "react-i18next";
import Permissions from "./Permissions/Permissions";
import { fetchTeachersList } from "../../reducers/teachersListSlice";
import {
  clearSession,
  selectUser,
  setSession,
} from "../../reducers/sessionSlice";
import {
  clearStudentPermissions,
  fetchStudentPermissions,
  selectStudentPermissions,
} from "../../reducers/studentPermissionsSlice";
import StudentIndex from "./StudentIndex";
import { Button } from "react-bootstrap";
import { clearNotifications } from "../../reducers/notificationsSlice";
import { clearPosts } from "../../reducers/postsSlice";
import { clearProgress } from "../../reducers/progressSlice";
import { useNavigate } from "react-router";

export default function ProfileInfoModal(props) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const teachersListStatus = useSelector((state) => state.teachersList.status);
  // Sets active field to edit data
  const dispatch = useDispatch();
  const [activeProfileField, setActiveProfileField] = useState(false);
  const [activeStudent, setActiveStudent] = useState(props.studentId);
  const [activeLevelChange, setActiveLevelChange] = useState(false);
  // Filters data from store specific to clicked student
  let allStudents = useSelector(selectTeacherPermissions);
  allStudents = allStudents
    .slice()
    .sort((a, b) => a.last_name.localeCompare(b.last_name));

  const student = allStudents.filter((item) => item.id === activeStudent)[0];

  // Catch error if at the end or beginning of a list
  const [progressData, setProgressData] = useState(null);
  let progressObj = useSelector(selectAllProgressTeacher);
  progressObj = progressObj.filter((item) => item.studentId === activeStudent);
  // Select last entry in case goals were completed
  progressObj = progressObj[progressObj.length - 1];
  const progress = progressObj?.data;
  const studentPermissions = useSelector(selectStudentPermissions);
  const teacherPermissions = useSelector(selectTeacherPermissions);

  useEffect(() => {
    if (activeStudent) {
      if (!progress) {
        dispatch(fetchProgressTeacher({ userId: activeStudent })).unwrap();
      }
      setProgressData(progress);
    }
  }, [progress, activeStudent, dispatch]);

  const handleSelect = (key) => {
    if (key === "permissions") {
      if (teachersListStatus === "idle") {
        dispatch(
          fetchTeachersList({
            user_id: user?.id,
            search_by_school_or_district: "school",
            id: user?.school_id,
          })
        );
      }
      // See if permissions for student already exist
      // If not, fetch them
      if (
        studentPermissions.filter((item) => item.id === activeStudent)
          .length === 0
      ) {
        dispatch(fetchStudentPermissions(activeStudent));
      }
    }
  };
  const navigate = useNavigate();
  const chameleonHandler = () => {
    const studentEmail = teacherPermissions.find(
      (student) => student.id === activeStudent
    ).email;
    dispatch(clearSession());
    navigate("/");
    dispatch(setSession(studentEmail));
    dispatch(clearNotifications());
    dispatch(clearProgress());
    dispatch(clearStudentPermissions());
    dispatch(clearPosts());
  };

  return (
    <Modal
      scrollable={false}
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className="m-1">
        <StudentIndex
          student={student}
          allStudents={allStudents}
          activeStudent={activeStudent}
          setActiveStudent={setActiveStudent}
          setActiveLevelChange={setActiveLevelChange}
          setActiveProfileField={setActiveProfileField}
        />
        <hr className="m-0 mb-2" />
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={handleSelect}>
          <Tab eventKey="profile" title={t("progress.Profile")}>
            <ProfileCard
              modal={true}
              user={student}
              active={activeProfileField}
              setactive={setActiveProfileField}
            />
            <div className="d-flex justify-content-center mt-2">
              <Button
                className="btn-success"
                onClick={() => chameleonHandler()}>
                Chameleon Mode
              </Button>
            </div>
          </Tab>
          <Tab eventKey="progress" title={t("progress.Progress")}>
            {progressData?.error && (
              <div className="alert alert-warning" role="alert">
                {t("progress.This student has no grade entries")}.
              </div>
            )}

            <ProgressTab
              progressData={progressData}
              studentId={activeStudent}
              activeLevelChange={activeLevelChange}
              setActiveLevelChange={setActiveLevelChange}
            />
          </Tab>
          <Tab eventKey="goals" title={t("progress.Goals")}>
            <GoalsAccordion
              progressData={progressData}
              studentId={activeStudent}
            />
          </Tab>
          <Tab eventKey="permissions" title={"Permissions"}>
            <Permissions studentId={activeStudent} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}
