import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/esm/Button";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";

export const handleLogout = (instance, logoutType) => {
  if (logoutType === "popup") {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  } else if (logoutType === "redirect") {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }
};

export const SignOutButton = () => {
  const { i18n } = useTranslation();
  const { instance } = useMsal();
  const authCtx = useContext(AuthContext);

  return (
    <Button
      onClick={() => {
        authCtx.logout(instance);
      }}
      className={"btn-success"}
      style={{ fontWeight: "bold" }}>
      {i18n.language === "en" ? "Logout" : "Se déconnecter"}
    </Button>
  );
};
