import React from "react";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";

export default function StudentIndex(props) {
  // Find current index of student
  const studentIndex = props.allStudents.findIndex(
    (item) => item.id === props.activeStudent
  );

  const leftButtonHandler = () => {
    try {
      props.setActiveStudent(props.allStudents[studentIndex - 1].id);
    } catch {
      props.setActiveStudent(
        props.allStudents[props.allStudents.length - 1].id
      );
    }
    props.setActiveLevelChange(false);
    props.setActiveProfileField(false);
  };

  const rightButtonHandler = () => {
    try {
      props.setActiveStudent(props.allStudents[studentIndex + 1].id);
    } catch {
      props.setActiveStudent(props.allStudents[0].id);
    }
    props.setActiveLevelChange(false);
    props.setActiveProfileField(false);
  };

  const student = props.student;

  return (
    <div className="d-flex flex-row justify-content-center">
      <div
        className="col-2 col-lg-1 text-center cursor-pointer"
        onClick={leftButtonHandler}>
        <ArrowLeftCircle />
      </div>
      <div className="col-8 col-lg-6">
        <h5 className="text-center fw-bold mb-2">
          {student.first_name} {student.last_name}
        </h5>
      </div>
      <div className="col-2 col-lg-1 text-center cursor-pointer">
        <ArrowRightCircle onClick={rightButtonHandler} />
      </div>
    </div>
  );
}
