import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { selectDistrictGoals } from "../../reducers/sessionSlice";

export default function StrandSelect({ onChange, formData }) {
  const { t } = useTranslation();

  const [strandOptions, setStrandOptions] = useState(null);
  const districtGoals = useSelector(selectDistrictGoals);
  useEffect(() => {
    const strandsEn = [...new Set(districtGoals?.map((item) => item.strand))];
    const options = strandsEn?.map((strand) => {
      return {
        label: t(`addStudent.${strand}`),
        value: strand,
      };
    });
    setStrandOptions(options);
  }, [districtGoals, setStrandOptions, t]);
  return (
    <Select
      name="strand"
      className="text-start w-100"
      options={strandOptions}
      placeholder={t("addGoal.Strand").toUpperCase()}
      onChange={onChange}
      value={strandOptions?.filter(
        (option) => option.value === formData.strand
      )}
    />
  );
}
