import React from "react";
import "./MakePostStyles.css";

export default function StrandIcon(props) {
  const styles = {
    image: {
      height: 70,
      width: 70,
      marginTop: "auto",
    },
    text: {
      textAlign: "center",
      padding: 5,
      fontSize: 16,
      margin: 0,
    },
  };

  var url = `https://language-profile.azurewebsites.net/static/images/goal_icons/${props.strand}_goals/${props.strand}1.png`;

  //

  return (
    <div
      className={
        props.active
          ? "container-active strandContainer hover-effect my-4 "
          : "strandContainer hover-effect my-4 "
      }
      onClick={props.onClick}>
      <div className="d-flex flex-row justify-content-center pt-2 ">
        <img alt="strand" style={styles.image} src={url} />
      </div>
      <p style={styles.text} className="mb-0">
        {props.title}
      </p>
    </div>
  );
}
