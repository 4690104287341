import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Card from "react-bootstrap/Card";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";

export default function OverallProgressCard(props) {
  const { i18n } = useTranslation();

  const style = {
    width: props.size === "sm" ? "10rem" : "28rem",
    height: props.size === "sm" ? "10rem" : "28rem",
    borderRadius: "25px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  };
  const iconSize = props.size === "sm" ? "2x" : "5x";
  const text =
    props.size === "sm" ? (
      <>
        <span className="text-center text-xs mt-1 fw-bold m-0">
          {props?.overall_cefr_grade}
        </span>
        <span className="text-xs m-0">
          {i18n.language === "en" ? "Overall" : "Globale"}
        </span>
      </>
    ) : (
      <>
        <h1 className="text-center mt-3 fw-bold">
          {props?.overall_cefr_grade}
        </h1>
        <h3> {i18n.language === "en" ? "Overall" : "Globale"}</h3>
      </>
    );
  return (
    <Card style={style}>
      <Card.Body
        className="my-auto d-flex flex-column justify-content-center align-items-center"
        style={{ height: "10%" }}>
        <CircularProgressbarWithChildren value={props?.percentage}>
          <FontAwesomeIcon
            icon={faTrophy}
            style={{ color: "goldenrod" }}
            size={iconSize}
          />
          {text}
        </CircularProgressbarWithChildren>
      </Card.Body>
    </Card>
  );
}
