import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export default function SignUpButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate("/support")}
      className={"btn btn-sm btn-secondary me-2 rounded-pill border-0"}
      style={{ fontWeight: "bold" }}>
      {t("navbar.Register")}
    </Button>
  );
}
