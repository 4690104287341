import React from "react";
import "./ButtonStyles.css";
import ProfilePicture from "../../profilePicture/ProfilePicture";

export default function ProfilePicIcon(props) {
  return (
    <div className="icon-wrapper picture-icon ms-1">
      <div className="icon-flex px-2">
        <div id="notificationTooltip" className="icon-wrapper">
          <a href="/" style={{ textDecoration: "none" }}>
            <ProfilePicture size="40px" src={props.src} />
          </a>
        </div>
      </div>
    </div>
  );
}
