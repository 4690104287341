import React from "react";
import { Bullseye, Pencil, PersonCircle, Trophy } from "react-bootstrap-icons";
import { COLORS } from "../../utils/colors";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

export default function MetricsCards(props) {
  const { t } = useTranslation();
  const iconClass = "fa-3x text-secondary";
  const user = useSelector(selectUser);
  const cardDetails = [
    {
      label: "Active Users",
      icon: <PersonCircle className={iconClass} color={COLORS[0]} />,
      data: props.userCount,
      role: ["Administrator"],
    },
    {
      label: t(`progress.Goals Completed`),
      icon: <Trophy className={iconClass} color={COLORS[1]} />,
      data: props.goalsCompleted,
      role: ["Teacher", "Administrator"],
    },
    {
      label: t(`progress.In Progress`),
      icon: <Bullseye className={iconClass} color={COLORS[2]} />,
      data: props.goalsInProgress,
      role: ["Teacher", "Administrator"],
    },
    {
      label: "Publications",
      icon: <Pencil className={iconClass} color={COLORS[3]} />,
      data: props.postCount,
      role: ["Teacher", "Administrator"],
    },
  ];

  return (
    <div className="container">
      <div className="row justify-content-center justify-content-lg-between">
        {cardDetails.map((card, i) => {
          let content = card?.role?.includes(user?.role) && (
            <div
              key={i}
              style={{ width: 200 }}
              className="d-flex flex-row align-items-center col-3 p-3 ">
              <div className="icon-container d-flex align-items-center">
                {card.icon}
              </div>
              <div className="ms-2 d-flex flex-column justify-content-center label-container p-2">
                <p className="text-xxs text-uppercase m-0">{card.label}</p>
                <h3 className="m-0 text-uppercase">
                  {props.flashing ? (
                    // <span className="blink-effect">#</span>
                    <Skeleton className="w-100" height={"1.5rem"} />
                  ) : (
                    card.data
                  )}
                </h3>
              </div>
            </div>
          );
          return content;
        })}
      </div>
    </div>
  );
}
