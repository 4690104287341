import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GoalsCarousel from "../../GoalsCarousel/GoalsCarousel";
import TextArea from "./TextArea";
import { useDispatch, useSelector } from "react-redux";
import { addNewPost } from "../../../reducers/postsSlice";
import ImageUpload from "../../ImageUpload/ImageUpload";
import CameraButton from "../../ImageUpload/CameraButton";
import Recorder from "../../Audio/Recorder";
import RecorderContainer from "../../Audio/RecorderContainer";
import $ from "jquery";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import { selectAllGoals } from "../../../reducers/goalsSlice";
import { selectUser } from "../../../reducers/sessionSlice";
import baseUrl from "../../../utils/baseUrl";
import SelfEvaluationSlider2 from "../../SelfEvaluationSlider/SelfEvaluationSlider2";
import {
  BlobServiceClient,
  AnonymousCredential, // Use AnonymousCredential for SAS token
} from "@azure/storage-blob";
import audiobufferToWav from "audiobuffer-to-wav";

export default function MakePostModal(props) {
  const { t } = useTranslation();
  const [selfEval, setSelfEval] = useState(1);
  const [goalSelectedForm, setGoalSelectedForm] = useState(null);
  const [reflection, setReflection] = useState("");
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [images, setImages] = useState([]);
  const [recorder, setRecorder] = useState(false);
  const dispatch = useDispatch();
  let containerId = "postContainer";
  const recordButtonId = `recordButton${props.id}`;
  const multimediaContainerId = `multimediaContainer${containerId}`;
  const replyCameraButton = `replyCameraButton${props.id}`;
  const goals = useSelector(selectAllGoals);
  const [statement, setStatement] = useState(null);
  const [statement_fr, setStatement_fr] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    const selectedGoal = goals?.filter((item) => item.id === goalSelectedForm);
    setStatement(selectedGoal[0]?.statement);
    setStatement_fr(selectedGoal[0]?.statement_fr);
  }, [goalSelectedForm, goals]);

  const handleClose = () => props.setshow(false);

  const onGoalChanged = (value) => {
    try {
      setGoalSelectedForm(value);
    } catch {
      return null;
    }
  };

  const addPost = (postId, imageBoolean, audioBoolean) => {
    const primary_teacher_id =
      user.role === "Student" ? user.primary_teacher_id : null;
    dispatch(
      addNewPost({
        postId: postId,
        first_name: user.first_name,
        last_name: user.last_name,
        user_id: user.id,
        role: user.role,
        primary_teacher_id: primary_teacher_id,
        goalId: goalSelectedForm,
        content: reflection,
        selfEval: selfEval,
        imageBoolean: imageBoolean,
        audioBoolean: audioBoolean,
        statement: statement,
        statement_fr: statement_fr,
        profile_pic_src: user.profile_pic_src,
      })
    ).unwrap();
  };

  const onPost = async () => {
    const postId = nanoid();
    if (selfEval && goalSelectedForm && (reflection || images)) {
      let audioBool = false;
      let imageBool = false;
      if (recorder) {
        try {
          var audio_url = document.getElementById(
            `${props.id}AudioRecording`
          ).src;
          if (audio_url) {
            audioBool = true;
          }
          const audioContext = new AudioContext();
          const response = await fetch(audio_url);
          const audioData = await response.arrayBuffer();
          const audioBuffer = await audioContext.decodeAudioData(audioData);
          const wavData = audiobufferToWav(audioBuffer);
          const your_sas_token_here = process.env.REACT_APP_SAS_TOKEN;
          // Initialize BlobServiceClient with AnonymousCredential for SAS token
          const blobServiceClient = new BlobServiceClient(
            `https://languageprofile.blob.core.windows.net?${your_sas_token_here}`, // Replace with your SAS token
            new AnonymousCredential()
          );

          // Get a reference to a container
          const containerClient = blobServiceClient.getContainerClient("audio");

          // Create a unique name for the blob
          const blobName = postId + ".wav";

          // Get a block blob client
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);

          // Upload wav data to the blob
          const uploadBlobResponse = await blockBlobClient.upload(wavData, 1);
          console.log(
            `Upload block blob ${blobName} successfully`,
            uploadBlobResponse.requestId
          );
        } catch {
          console.log("Post audio could not be uploaded.");
        }
      }
      if (images.length !== 0) {
        imageBool = true;
        try {
          const imageUrl = images[0].data_url;
          document.getElementById(`removeAllImages${props.id}`).click();
          setShowImageUpload(false);
          fetch(imageUrl)
            .then((r) => r.blob())
            .then((blob) => {
              if (blob) {
                var formData = new FormData();
                formData.append("file", blob, postId);
                fetch(`${baseUrl}/store_multimedia/image/post/${postId}`, {
                  method: "POST",
                  body: formData,
                })
                  .then((response) => response.json())
                  .then((json) => {
                    addPost(postId, imageBool, audioBool);
                  });
              }
            });
        } catch {
          console.log("Post has no image.");
        }
      } else {
        addPost(postId, imageBool, audioBool);
      }
      handleClose();
      setGoalSelectedForm("");
      setReflection("");
      setSelfEval("");
    }
  };

  const deleteAudio = () => {
    setRecorder(false);
  };

  useEffect(() => {
    setGoalSelectedForm(props.preselectedgoal);
  }, [props.preselectedgoal]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("makePostModal.How are you meeting your goals?")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-2">
          <label htmlFor="selectGoal" className="fw-bold mb-3">
            1. {t("makePostModal.Select a goal")}
          </label>
          <div className="mb-5 d-flex justify-content-center align-items-center">
            <GoalsCarousel
              onClick={onGoalChanged}
              settings={props.settings}
              goalselected={goalSelectedForm}
              setgoalselected={setGoalSelectedForm}
            />
          </div>
          {goalSelectedForm && (
            <>
              <TextArea reflection={reflection} setReflection={setReflection} />
              <div
                id={multimediaContainerId}
                className="d-flex flex-row justify-content-end align-items-center">
                <RecorderContainer
                  id={props.id}
                  delete={deleteAudio}
                  containerId={containerId}
                  setrecorder={setRecorder}
                  recorder={recorder}
                  loading={props.loading}
                  setloading={props.setloading}
                  post={true}
                />
                <div
                  id={recordButtonId}
                  className="ms-2"
                  onClick={() => {
                    $(`#multimediaContainer${containerId}`).removeClass(
                      "ms-auto"
                    );
                  }}>
                  <Recorder
                    id={props.id}
                    containerId={containerId}
                    setrecorder={setRecorder}
                    recorder={recorder}
                  />
                </div>
                <div id={replyCameraButton}>
                  <CameraButton
                    showimageupload={showImageUpload}
                    setshowimageupload={setShowImageUpload}
                  />
                </div>
              </div>
              <RecorderContainer
                id="post"
                containerId="postRecorder"
                post={true}
              />

              {showImageUpload && (
                <ImageUpload id="post" images={images} setimages={setImages} />
              )}
            </>
          )}
          {goalSelectedForm && (reflection.length >= 15 || !!images) && (
            <div className="mt-5">
              <label htmlFor="selfEvaluationStrand" className="fw-bold">
                3. {t("makePostModal.Update your grade")}
              </label>
              <div className="pt-4 px-5">
                <SelfEvaluationSlider2
                  setSelfEval={setSelfEval}
                  selfEval={selfEval}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onPost}
          disabled={!selfEval || !goalSelectedForm || (!reflection && !images)}>
          {t("makePostModal.Add Post")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
