import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProgress,
  selectAllProgress,
} from "../../../reducers/progressSlice";
import { MDBTooltip } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../reducers/sessionSlice";

export default function ProgressButton(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const progress = useSelector((state) => selectAllProgress(state, user.id));
  const progressStatus = useSelector((state) => state.progress.status);

  useEffect(() => {
    if (progressStatus === "idle" && user?.role === "Student") {
      dispatch(fetchProgress({ userId: user?.id, role: user?.role })).unwrap();
    }
  }, [progressStatus, dispatch, user]);

  return (
    <MDBTooltip
      id="#progressButton"
      tag="div"
      placement="bottom"
      title={t("navbar.Progress")}>
      <div className={"icon-flex px-1"}>
        <div className={"icon-wrapper"}>
          <Link to="/progress">
            <CircularProgressbarWithChildren
              key="0"
              value={progress[progress.length - 1]?.data[0].percentage}>
              <FontAwesomeIcon icon={faTrophy} style={{ color: "#38344c" }} />
            </CircularProgressbarWithChildren>
          </Link>
        </div>
      </div>
    </MDBTooltip>
  );
}
