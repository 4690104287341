import React from "react";
import ClassListTable from "../../components/ClassListTable/ClassListTable";
import GroupingsTable from "../../components/Groupings/GroupingsTable";

export default function ClassList() {
  return (
    <>
      <ClassListTable />
      <GroupingsTable />
    </>
  );
}
