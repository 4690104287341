import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { addNewGoal } from "../../reducers/goalsSlice";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { selectAllProgress } from "../../reducers/progressSlice";
import StatementSelect from "./StatementSelect";
import StrandIcon from "../MakePost/modal/StrandIcon";
import { selectUser } from "../../reducers/sessionSlice";
import { useTranslation } from "react-i18next";
import SelfEvaluationSlider2 from "../SelfEvaluationSlider/SelfEvaluationSlider2";

export const evalScaleEn = {
  1: "I am just starting",
  2: "On the way",
  3: "I'm almost there",
  4: "Ready to move on",
};

export const evalScaleFr = {
  1: "Je débute",
  2: "Je commence à atteindre cet objectif",
  3: "Avec de l'aide, je peux atteindre cet objectif",
  4: "J'ai atteint cet objectif",
};

export default function AddGoalForm(props) {
  const { t, i18n } = useTranslation();
  const [strand, setStrand] = useState(null);
  const [statement, setStatement] = useState(null);
  const [goalInfo, setGoalInfo] = useState(null);
  const [selfEval, setSelfEval] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  let progress = useSelector(selectAllProgress);
  progress = progress[0].data;

  const onStrandChanged = (value) => {
    try {
      setStrand(value);
      setStatement("");
    } catch {
      setStrand("");
      setStatement("");
    }
  };

  const onStatementChanged = (e) => {
    try {
      setStatement({ value: e.value, label: e.label });
    } catch {
      setStatement("");
    }
  };

  // const onSelfEvalChanged = (value) => {
  //   setSelfEval(value);
  // };

  useEffect(() => {
    const findGoalInfo = (cefr_custom_id) => {
      for (let item of progress) {
        if (item.strand === strand) {
          const remainingGoals = item?.remaining_goals;
          for (let goal of remainingGoals) {
            if (goal.id === cefr_custom_id) {
              return goal;
            }
          }
        }
      }
    };
    setGoalInfo(findGoalInfo(statement?.value));
  }, [statement, progress, strand]);

  const onSaveGoalClicked = async () => {
    if (strand && statement && selfEval) {
      await dispatch(
        addNewGoal({
          id: nanoid(),
          user_id: user.id,
          strand: goalInfo.strand,
          strand_fr: goalInfo.strand_fr,
          cefr_custom_id: statement.value,
          statement: goalInfo.statement,
          statement_fr: goalInfo.statement_fr,
          selfEval: selfEval,
        })
      ).unwrap();
      setStrand("");
      setStatement("");
      setSelfEval(1);
      props.showhandler(false);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title>{t("addGoal.Add Goal")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-3">
        <form>
          <p className="fw-bold">{t("addGoal.Select a strand")}.</p>

          <div className="d-flex flex-row justify-content-center p-2">
            {progress?.slice(1).map((item) => {
              return (
                <div
                  key={item.strand}
                  className="col-4 col-lg-2 d-flex justify-content-center">
                  <StrandIcon
                    title={
                      i18n.language === "en"
                        ? item.strand
                        : t(`addStudent.${item.strand}`)
                    }
                    strand={item.strand}
                    active={strand === item.strand ? true : false}
                    onClick={() => onStrandChanged(item.strand)}></StrandIcon>
                </div>
              );
            })}
          </div>

          {strand && (
            <div className="pb-5">
              <label htmlFor="goalStrand" className="mt-3 fw-bold pb-3">
                {t("addGoal.Which goal would you like to meet")}?
              </label>
              <StatementSelect
                progress={progress}
                onChange={onStatementChanged}
                strand={strand}
                statement={statement}
              />
            </div>
          )}
          {strand && statement && (
            <div>
              <label htmlFor="selfEvaluationStrand" className="my-3 fw-bold">
                {t("addGoal.Give yourself a starting grade")}.
              </label>
              {/* <SelfEvaluationSlider
                onChange={onSelfEvalChanged}
                selfEval={selfEval}
              /> */}
              <SelfEvaluationSlider2
                selfEval={selfEval}
                setSelfEval={setSelfEval}
              />
            </div>
          )}
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onSaveGoalClicked}
          disabled={!strand || !statement || !selfEval}>
          {t("addGoal.addGoalButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
