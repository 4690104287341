import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

export default function LanguageToggle() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language, setSelectedLanguage]);

  return (
    <Form.Select
      size="sm"
      aria-label="Language Select"
      value={selectedLanguage}
      className="me-2"
      style={{ width: "70px" }} // Adjust width as needed
      onChange={handleChange}>
      <option defaultValue={selectedLanguage === "en"} value="en">
        EN
      </option>
      <option defaultValue={selectedLanguage === "fr"} value="fr">
        FR
      </option>
    </Form.Select>
  );
}
