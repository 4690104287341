import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async (obj) => {
  const settings = {
    body: JSON.stringify(obj),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${baseUrl}/get_posts`, settings);
  const posts = await response.json();
  return posts;
});

export const fetchTeacherPosts = createAsyncThunk(
  "posts/fetchTeacherPosts",
  async (obj) => {
    const settings = {
      body: JSON.stringify(obj),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(`${baseUrl}/get_posts`, settings);
    let posts = await response.json();

    return posts;
  }
);

export const addNewPost = createAsyncThunk(
  "posts/addNewPost",
  async (initialPost) => {
    try {
      const response = await fetch(`${baseUrl}/add_post`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(initialPost),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addNewReply = createAsyncThunk(
  "posts/addNewReply",
  async (initialReply) => {
    try {
      const response = await fetch(`${baseUrl}/add_reply`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(initialReply),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deletePost = createAsyncThunk(
  "posts/deletePost",
  async (postId) => {
    try {
      await fetch(`${baseUrl}/delete_post/${postId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      return postId;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteReply = createAsyncThunk(
  "posts/deleteReply",
  async (replyId) => {
    try {
      const response = await fetch(`${baseUrl}/delete_reply/${replyId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const like = createAsyncThunk("posts/like", async (obj) => {
  const settings = {
    body: JSON.stringify(obj),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(`${baseUrl}/like`, settings);
    const data = await response.json();
    data.postId = obj.post_or_reply_id;
    return data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  posts: [],
  status: "idle",
  error: null,
};

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    postAdded(state, action) {
      state.posts.push(action.payload);
    },
    postDeleted(state, action) {
      const postId = action.payload;
      state.posts = state.posts.filter((item) => item.post.id !== postId);
    },
    clearPosts: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        const existing_post_ids = state.posts.map((item) => item.post.id);
        for (let new_post of action.payload) {
          if (!existing_post_ids.includes(new_post.post.id)) {
            state.posts.push(new_post);
          }
        }
        state.status = "succeeded";
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder.addCase(addNewPost.fulfilled, (state, action) => {
      state.posts.unshift(action.payload);
    });
    builder.addCase(addNewReply.fulfilled, (state, action) => {
      let index = state.posts.findIndex(
        (item) => item.post.id === action.payload.post_id
      );
      state.posts[index].replies.push(action.payload);
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.posts = state.posts.filter(
        (item) => item.post.id !== action.payload
      );
    });
    builder.addCase(like.fulfilled, (state, action) => {
      const data = state.posts;
      let postIndex = state.posts.findIndex(
        (item) => item.post.id === action.payload.postId
      );
      const post = data[postIndex];
      const updatedPost = {
        ...post,
        post_likes: action.payload,
      };
      return {
        ...state,
        posts: [
          // create a new data array
          ...data.slice(0, postIndex), // the posts before the updated comment
          updatedPost, // the updated post
          ...data.slice(postIndex + 1), // the posts after the updated comment
        ],
      };
    });
    builder.addCase(deleteReply.fulfilled, (state, action) => {
      const data = state.posts;
      // Find Post Index
      let postIndex = state.posts.findIndex(
        (item) => item.post.id === action.payload.post_id
      );
      // Get the post
      const post = data[postIndex];

      // create an updated comment with filtered replies array
      const updatedPost = {
        ...post,
        replies: post.replies.filter((reply) => reply.id !== action.payload.id),
      };

      // create a new state using object spread or assign
      return {
        ...state,
        posts: [
          // create a new data array
          ...data.slice(0, postIndex), // the comments before the updated comment
          updatedPost, // the updated comment
          ...data.slice(postIndex + 1), // the comments after the updated comment
        ],
      };
    });
  },
});

export const { postAdded, postDeleted, clearPosts } = postsSlice.actions;

export default postsSlice.reducer;

export const selectAllPosts = (state) => state.posts.posts;
