import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import React from "react";

export default function StatusColours() {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-row justify-content-center align-items-center py-3">
      <div>
        <FontAwesomeIcon icon={faCircle} color={"goldenrod"} />

        <span className="text-muted ms-1 text-sm me-3">
          {t("progress.In Progress")}
        </span>
      </div>
      <div>
        <FontAwesomeIcon icon={faCircleCheck} color={"green"} />

        <span className="text-muted ms-1 text-sm me-3">
          {t("progress.Pass")}
        </span>
      </div>
      <div>
        <FontAwesomeIcon icon={faCircle} color={"grey"} />
        <span className="text-muted ms-1 text-sm me-3">
          {t("progress.Incomplete")}
        </span>
      </div>
    </div>
  );
}
