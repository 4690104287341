import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const setSession = createAsyncThunk(
  "session/setSession",
  async (email) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const url = `${baseUrl}/set_session_data/${email}`;
    console.log(typeof url);
    console.log(url);
    const response = await fetch(url, settings);
    const session = await response.json();
    console.log(session);
    return session;
  }
);

export const changeTextField = createAsyncThunk(
  "session/changeTextField",
  async (object) => {
    try {
      const response = await fetch(`${baseUrl}/change_text_field`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  session: [],
  status: "idle",
  error: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    switchActiveSchool: (state) => {
      const user = state.session[state.session.length - 1];
      const originalSchool = user.school;
      const originalSchoolId = user.school_id;
      const originalSchool1 = user.school_1;
      const originalSchool1Id = user.school_1_id;
      user.school = originalSchool1;
      user.school_id = originalSchool1Id;
      user.school_1 = originalSchool;
      user.school_1_id = originalSchoolId;
    },
    clearSession: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSession.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setSession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.session = state.session.concat(action.payload);
      })
      .addCase(setSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(changeTextField.fulfilled, (state, action) => {
        state.status = "succeeded";
        let newEntry = {
          ...state.session[state.session.length - 1],
          ...action.payload,
        };

        state.session = state.session.concat(newEntry);
      });
  },
});

export default sessionSlice.reducer;

export const selectUser = (state) =>
  state.session.session[state.session.session.length - 1];

export const selectAllUsers = (state) => state.session.session;

export const selectDistrictGoals = (state) => {
  let session = state.session.session[state.session.session.length - 1];
  return session?.district_goals;
};

export const { switchActiveSchool, clearSession } = sessionSlice.actions;
