import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import { ArrowClockwise } from "react-bootstrap-icons";

export function EnlargeModal(props) {
  const [rotation, setRotation] = useState(0);
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        {...props}
        size="xl"
        scrollable
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="m-1">
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-end">
              <Button onClick={() => setRotation(rotation + 90)}>
                <ArrowClockwise />
              </Button>
            </div>
            <div>
              <img
                src={props.src}
                style={{
                  width: "100%",
                  height: "100%",
                  transform: `rotate(${rotation}deg)`,
                }}
                className="p-2 img-fluid"
                loading="lazy"
                title="uploaded image"
                alt="upload"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default function ShowImage(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="mx-auto cursor-pointer" onClick={() => setShowModal(true)}>
      <img
        src={props.src}
        style={{ width: "300px" }}
        className="py-2"
        loading="lazy"
        title="uploaded image"
        alt="upload"
      />
      <EnlargeModal
        src={props.src}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
}
