import React from "react";

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const value = "";

    return (
      <div ref={ref} className={className} aria-labelledby={labeledBy}>
        <ul className="list-unstyled" style={{ margin: 0 }}>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export default CustomMenu;
