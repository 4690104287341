import React, { useContext, useEffect, useState } from "react";
import MakePost from "../MakePost/MakePost";
import Posts from "../Posts/Posts";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts, selectAllPosts } from "../../reducers/postsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import LeftPanel from "../TeacherWall/LeftPanel";
import { TourContext } from "../../contexts/TourContext";
import { joyrideStyles, studentTour } from "../../utils/tour";
import { selectAllGoals } from "../../reducers/goalsSlice";
import ReactJoyride from "react-joyride";

export default function StudentWall(props) {
  const { tourSwitch, setTourSwitch } = useContext(TourContext);
  const goals = useSelector(selectAllGoals);
  useEffect(() => {
    if (goals.length === 0) {
      setTourSwitch(true);
    } else {
      setTourSwitch(false);
    }
  }, [setTourSwitch, goals]);

  const dispatch = useDispatch();
  const [filterPosts, setFilterPosts] = useState({
    strand: "All",
    goalId: null,
  });
  const postStatus = useSelector((state) => state.posts.status);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (postStatus === "idle") {
      dispatch(
        fetchPosts({
          role: user.role,
          user_id: user.id,
          student_id: null,
          permissions: null,
          offset: 0,
        })
      ).unwrap();
    }
  }, [postStatus, dispatch, user]);
  const posts = useSelector(selectAllPosts);

  return (
    <>
      <div className="row">
        <div className="col-lg-3 d-none d-lg-block">
          <LeftPanel />
        </div>
        <div className="col-12 col-lg-6">
          <MakePost
            goals={props.goals}
            user={props.user}
            name={props.name}
            filterposts={filterPosts}
            setfilterposts={setFilterPosts}
          />
          <Posts
            posts={posts}
            postStatus={postStatus}
            filterposts={filterPosts}
            setfilterposts={setFilterPosts}
          />
        </div>
        <div className="col-lg-3 d-none d-lg-block"></div>
      </div>
      <ReactJoyride
        steps={studentTour}
        debug={true}
        continuous
        run={tourSwitch}
        showOverlay={true}
        showProgress
        hideCloseButton
        showStepsProgress={true}
        styles={joyrideStyles}
        callback={() => console.log("done")}
      />
    </>
  );
}
