import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { selectUser, setSession } from "../reducers/sessionSlice";
import { GraphContext } from "../contexts/GraphContext";
import { AuthContext } from "../contexts/AuthContext";

export default function PrivateRoutes(props) {
  const authCtx = useContext(AuthContext);
  const isAuthenticated = authCtx.isAuthenticated;
  const graphData = authCtx.token;
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const sessionStatus = useSelector((state) => state.session.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && graphData) {
      if (sessionStatus === "idle" || sessionStatus === "failed") {
        // If session status is idle or failed, set the session
        dispatch(setSession(graphData.mail));
      }
    }
  }, [isAuthenticated, graphData, sessionStatus, dispatch]);

  useEffect(() => {
    if (user && user.error) {
      // If there's an error in user data, navigate to "user_not_found"
      navigate("/user_not_found");
    }
  }, [user, navigate]);

  return isAuthenticated ? (
    <GraphContext.Provider value={{ graphData }}>
      <Outlet />
    </GraphContext.Provider>
  ) : (
    <Navigate to="/" />
  );
}
