import React from "react";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DeleteMessage(props) {
  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <p className="fw-bold text-medium text-dark fw-bold">Delete</p>
        <button
          type="button"
          className="btn btn-secondary ms-auto fw-bold"
          aria-label="Cancel"
          onClick={props.onCancel}>
          Cancel
        </button>
      </div>

      <hr />
      <div
        className="hover-effect border-radius-none d-flex flex-row p-2"
        onClick={props.onDelete}>
        <FontAwesomeIcon
          icon={faTrashCan}
          className="me-2"
          size="xl"
          color="#616161"
        />
        <p className="m-0 text-secondary">
          Are you sure you want to delete? All pictures, recordings and replies
          will be permanently deleted.
        </p>
      </div>
    </>
  );
}
