import React, { useEffect, useState } from "react";
import TutorialsBreadcrumb from "../../components/Tutorials/TutorialsBreadcrumb";
import TutorialsLanding from "../../components/Tutorials/TutorialsLanding";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import TutorialVideoModal from "../../components/Tutorials/TutorialVideoModal";
import VideosMenu from "../../components/Tutorials/VideosMenu";

export default function Tutorials() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [activeInterface, setActiveInterface] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  useEffect(() => {
    setActiveInterface(null);
  }, []);
  const navigate = useNavigate();
  const backButtonHandler = () => {
    activeInterface ? setActiveInterface(null) : navigate("/");
  };
  useEffect(() => {
    activeVideo && setShowModal(true);
  }, [activeVideo, setShowModal]);

  return (
    <>
      <TutorialsBreadcrumb
        activeInterface={activeInterface}
        setActiveInterface={setActiveInterface}
      />
      <div className="bg-main vh-min-60 d-flex justify-content-center align-items-center">
        {!activeInterface && (
          <TutorialsLanding setActiveInterface={setActiveInterface} />
        )}
        {activeInterface && (
          <VideosMenu
            setActiveVideo={setActiveVideo}
            activeInterface={activeInterface}
          />
        )}
      </div>
      <div className="d-flex justify-content-center">
        <Button variant="outline-dark" onClick={backButtonHandler}>
          Back
        </Button>
      </div>
      <TutorialVideoModal
        showModal={showModal}
        activeVideo={activeVideo}
        activeInterface={activeInterface}
        onHide={() => {
          setShowModal(false);
          setActiveVideo(null);
        }}
      />
    </>
  );
}
