import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import PostHeader from "./PostHeader";
import PostBody from "./PostBody";
import PostCommentCounter from "./PostCommentCounter";
import PostLikeComment from "./PostLikeComment";
import MakeReply from "../MakeReply/MakeReply";
import SeeReply from "../SeeReply/SeeReply";
import { useDispatch } from "react-redux";
import { deletePost } from "../../reducers/postsSlice";
import DeleteMessage from "./DeleteMessage";

export default function PostCard(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hidePost, setHidePost] = useState(null);

  const onHidePost = (id) => {
    setHidePost(id);
  };
  const onDelete = (postId) => {
    dispatch(deletePost(postId));
  };

  const orderedReplies =
    props.replies &&
    props.replies
      .slice()
      .sort((reply1, reply2) => reply1.timestamp - reply2.timestamp);

  const replies = orderedReplies?.map((item) => (
    <SeeReply
      key={item.id}
      profile_pic_src={item.profile_pic_src}
      replyId={item.id}
      postId={props.id}
      content={item.content}
      first_name={item.first_name}
      last_name={item.last_name}
      timestamp={item.timestamp}
      audioboolean={item.audio_boolean}
      imageboolean={item.image_boolean}
      loading={loading}
      statement={props.statement}
      statement_fr={props.statement_fr}
      setloading={setLoading}
    />
  ));
  return (
    <Card id={props.id} key={props.id} className={`mb-3 shadow-custom`}>
      <Card.Body>
        {hidePost === props.id ? (
          <DeleteMessage
            onCancel={() => setHidePost(null)}
            onDelete={() => onDelete(props.id)}
          />
        ) : (
          <>
            <PostHeader
              id={props.id}
              author_id={props.author_id}
              first_name={props.first_name}
              last_name={props.last_name}
              datetime={props.datetime}
              statement={props.statement}
              statement_fr={props.statement_fr}
              selfeval={props.selfeval}
              profile_pic_src={props.profile_pic_src}
              onHideForDelete={() => onHidePost(props.id)}
            />
            <PostBody
              id={props.id}
              reflection={props.reflection}
              statement={props.statement}
              statement_fr={props.statement_fr}
              imageboolean={props.imageboolean}
              audioboolean={props.audioboolean}
              strand={props.strand}
              strand_fr={props.strand_fr}
              level={props.level}
              timestamp={props.datetime}
            />
            {!props.introCard && (
              <>
                <PostCommentCounter id={props.id} />
                {props.status === "In Progress" && (
                  <PostLikeComment id={props.id} liked={props.liked} />
                )}
                {replies}
                {props.status === "In Progress" && (
                  <MakeReply
                    id={props.id}
                    loading={loading}
                    setloading={setLoading}
                  />
                )}
              </>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}
