import React from "react";
import Select from "react-select";
import schoolLevelsList from "../../../utils/schoolLevels";

export default function SchoolLevelInput(props) {
  const content =
    props.edit === true || props.edit === props.id ? (
      <Select
        className="search-select me-2"
        isClearable
        placeholder="Select Level"
        name="level"
        options={schoolLevelsList}
        value={schoolLevelsList.filter(
          (option) => option.value === props.value
        )}
        onChange={props.onChange}
      />
    ) : (
      <>
        <span className="m-0">{props.savedValue}</span>
      </>
    );
  return content;
}
