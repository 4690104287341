import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { MDBCheckbox } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import { selectDistrictGoals } from "../../reducers/sessionSlice";
import { useState } from "react";
import {
  bulkAddGoals,
  fetchAllProgressTeacher,
  selectAllProgressTeacher,
} from "../../reducers/progressTeacherSlice";
import LevelSelect from "./LevelSelect";
import GroupSelect from "./GroupSelect";
import StrandSelect from "./StrandSelect";

export default function TeacherAddGoalModal(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const districtGoals = useSelector(selectDistrictGoals);
  const emptyForm = {
    level: "",
    strand: "",
    group: "",
    cefr_custom_id: "",
    students: "",
  };
  let progressObj = useSelector(selectAllProgressTeacher);
  const [formData, setFormData] = useState(emptyForm);
  let allStudentIds = [];

  teacherPermissions?.map((student) => allStudentIds.push(student.student_id));

  let fetchedStudentIds = [];
  progressObj?.map((student) => fetchedStudentIds.push(student.studentId));
  let studentIds = allStudentIds.filter(
    (val) => !fetchedStudentIds.includes(val)
  );
  const progressStatus = useSelector((state) => state.progressTeacher.status);

  useEffect(() => {
    if (progressStatus === "idle" && formData.strand !== "") {
      dispatch(fetchAllProgressTeacher({ studentIds: studentIds }));
    }
  }, [progressStatus, dispatch, formData.strand, studentIds]);

  const formHandler = (e, action) => {
    try {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } catch {
      // For select dropdowns
      try {
        setFormData({ ...formData, [action.name]: e.value });
      } catch {
        setFormData({ ...formData, [action.name]: "" });
      }
    }
    if (action.name === "strand" || action.name === "level") {
      setFormData({
        ...formData,
        [action.name]: e.value,
        students: "",
        cefr_custom_id: "",
      });
    }
    if (action.name === "cefr_custom_id") {
      setFormData({
        ...formData,
        [action.name]: e.value,
        students: "",
      });
    }
  };

  const checkHandler = (e) => {
    e.target.checked
      ? setFormData({
          ...formData,
          students: [...formData.students, e.target.value],
        })
      : setFormData({
          ...formData,
          students: formData.students?.filter(
            (item) => item !== e.target.value
          ),
        });
  };

  const selectAllHandler = () => {
    setFormData({
      ...formData,
      students: filteredStudents0.map((id) => {
        return id;
      }),
    });
  };

  const deselectAllHandler = () => {
    setFormData({
      ...formData,
      students: [],
    });
  };

  // GOALS
  const filteredGoals = districtGoals?.filter(
    (item) => item.strand === formData.strand && item.level === formData.level
  );
  const goalOptions = filteredGoals?.map((item) => ({
    value: item.id,
    label: i18n.language === "en" ? item.statement : item.statement_fr,
  }));

  // STUDENT NAMES
  let filteredStudents0 = progressObj?.map((item) => {
    {
      let remaining_goals = item?.data?.find(
        (x) => x.strand === formData.strand && x.grade === formData.level
      )?.remaining_goals;
      if (remaining_goals) {
        for (let goal of remaining_goals) {
          if (goal.id === formData.cefr_custom_id) {
            if (item.studentId) {
              return item.studentId;
            }
          }
        }
      }
    }
    return false;
  });

  console.log(filteredStudents0);
  console.log(formData);

  const onBulkAddClicked = async () => {
    if (formData.strand && formData.students && formData.cefr_custom_id) {
      await dispatch(
        bulkAddGoals({
          strand: formData.strand,
          students: formData.students,
          cefr_custom_id: formData.cefr_custom_id,
        })
      ).unwrap();
      setFormData(emptyForm);
      props.handleClose();
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("addGoal.Add Goal")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="m-0 mt-2 fw-bold">1. {t("addGoal.Select...")}</p>
        <div className="card p-2">
          <div className="row">
            <div className="col-12 col-lg-4">
              <StrandSelect formData={formData} onChange={formHandler} />
            </div>
            <div className="col-12 col-lg-4">
              <LevelSelect formData={formData} onChange={formHandler} />
            </div>
            <div className="col-12 col-lg-4">
              <GroupSelect formData={formData} onChange={formHandler} />
            </div>
          </div>
          {formData.strand !== "" && formData.level !== "" && (
            <div className="row mt-2">
              <Select
                name="cefr_custom_id"
                className="text-start w-100"
                placeholder={t("addGoal.Objective").toUpperCase()}
                isClearable={false}
                options={goalOptions}
                onChange={formHandler}
                disabled={goalOptions.length === 0}
                value={goalOptions?.filter(
                  (option) => option.value === formData.cefr_custom_id
                )}
              />
            </div>
          )}
        </div>
        {formData.strand !== "" &&
          formData.level !== "" &&
          formData.cefr_custom_id !== "" && (
            <>
              <p className="m-0 mt-4">
                <span className="fw-bold me-2">
                  2. {t("addGoal.Select students")}
                </span>
                <span
                  onClick={selectAllHandler}
                  className="text-primary me-1 cursor-pointer">
                  {t("addGoal.Select All")}
                </span>
                <span className="me-1">/</span>
                <span
                  onClick={deselectAllHandler}
                  className="text-primary cursor-pointer">
                  {t("addGoal.Deselect All")}
                </span>
              </p>
              <div className="card p-2">
                <div style={{ columns: "3 auto" }}>
                  {filteredStudents0?.map((id, i) => {
                    let nameData = teacherPermissions?.find((x) => x.id === id);
                    let element = (
                      <div key={i} className="mb-2">
                        <MDBCheckbox
                          key={id}
                          onChange={checkHandler}
                          name="students"
                          value={id}
                          id={id}
                          checked={formData.students.includes(id)}
                          label={`${nameData?.last_name}, ${nameData?.first_name}`}
                        />
                      </div>
                    );
                    return nameData ? element : null;
                  })}
                </div>
              </div>
            </>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {t("classList.Close")}
        </Button>
        <Button
          variant="primary"
          onClick={onBulkAddClicked}
          disabled={
            formData.students.length === 0 ||
            formData.strand === "" ||
            formData.cefr_custom_id === "" ||
            formData.level === ""
          }>
          {t("addGoal.addGoalButton")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
