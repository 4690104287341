const getCurrentStrands = (language, posts, goals) => {
  let currentStrandsEn = [];
  let currentStrandsFr = [];

  for (let i = 0; i < posts.length; i++) {
    for (let j = 0; j < goals.length; j++) {
      if (posts[i].post.goal_id === goals[j].id) {
        currentStrandsEn.push(goals[j].strand);
        currentStrandsFr.push(goals[j].strand_fr);
      }
    }
  }
  currentStrandsEn = [...new Set(currentStrandsEn)];
  currentStrandsEn.unshift("All");
  currentStrandsFr = [...new Set(currentStrandsFr)];
  currentStrandsFr.unshift("Voir Tout");

  const currentStrands =
    language === "en" ? currentStrandsEn : currentStrandsFr;

  return currentStrands;
};

export const getDistrictStrands = (language, goals) => {
  let currentStrandsEn = [];
  let currentStrandsFr = [];

  for (let j = 0; j < goals.length; j++) {
    currentStrandsEn.push(goals[j].strand);
    currentStrandsFr.push(goals[j].strand_fr);
  }

  currentStrandsEn = [...new Set(currentStrandsEn)];
  currentStrandsEn.unshift("All");
  currentStrandsFr = [...new Set(currentStrandsFr)];
  currentStrandsFr.unshift("Voir Tout");

  const districtStrands =
    language === "en" ? currentStrandsEn : currentStrandsFr;

  return districtStrands;
};

export default getCurrentStrands;
