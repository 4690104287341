import React, { useState, useMemo } from "react";

export default function ProfilePicture(props) {
  const srcError =
    "https://language-profile.azurewebsites.net/static/images/profile.png";
  const [src, setSrc] = useState(srcError);
  useMemo(() => {
    if (props.src) {
      setSrc(
        `https://language-profile.azurewebsites.net${props.src}` || srcError
      );
    } else {
      setSrc(srcError);
    }
  }, [setSrc, props.src]);

  const onError = () => {
    setSrc(srcError);
  };

  return (
    <div
      style={{ display: "inline-block", position: "relative" }}
      className={props.className}>
      <img
        alt="profile"
        src={src}
        onError={onError}
        style={{
          borderRadius: "50%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: props.size,
          height: props.size,
        }}
      />

      {props.children}
    </div>
  );
}
