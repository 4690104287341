import React, { useState, useEffect } from "react";
import ProfilePicture from "../profilePicture/ProfilePicture";
import ProfileInfoModal from "./ProfileInfoModal";
import { selectUser } from "../../reducers/sessionSlice";
import { useSelector } from "react-redux";

export default function StudentCarouselCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [studentProfile, setStudentProfile] = useState(null);
  const [unreadRing, setUnreadRing] = useState(false);
  const name = `${props.last_name}, ${props.first_name}`;
  const truncatedName = name.length > 16 ? `${name.substring(0, 13)}...` : name;
  const user = useSelector(selectUser);

  useEffect(() => {
    const hasUnreadPosts = (studentId) => {
      const unreadList = user.unread_data;
      return unreadList.some((item) => item === studentId);
    };
    setUnreadRing(hasUnreadPosts(props.studentId));
  }, [user, props.studentId]);

  const ringClass =
    props.studentId === props.activeStudent
      ? "ring-active"
      : unreadRing
      ? "ring-unread"
      : "ring-none";

  return (
    <div className="d-flex flex-column justify-content-center align-items-center carousel-student-card">
      <div
        className={`ring-container ${ringClass}`}
        onClick={() => {
          if (props.studentId === props.activeStudent) {
            props.setActiveStudent(null);
          } else {
            props.setActiveStudent(props.studentId);
          }
        }}>
        <ProfilePicture className="pic-ring" src={props.src} size="70px" />
      </div>
      <p
        className="m-0  text-muted text-xs cursor-pointer text-center"
        onClick={() => {
          setStudentProfile(props.studentId);
          setShowModal(true);
        }}>
        {truncatedName}
      </p>
      {showModal && studentProfile && (
        <ProfileInfoModal
          activeStudent={props.activeStudent}
          studentId={studentProfile}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
