import React from "react";
import { useDispatch } from "react-redux";
import { changeTextField } from "../../reducers/sessionSlice";
import { changeTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import { useTranslation } from "react-i18next";

export default function EditField(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = async (e) => {
    props.setvalue(e.target.value);
  };
  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  const onSave = () => {
    props.modal
      ? dispatch(
          changeTeacherPermissions({
            id: props.id,
            attribute_name: props.attributeName,
            content: props.value,
          })
        ).unwrap()
      : dispatch(
          changeTextField({
            id: props.id,
            attribute_name: props.attributeName,
            content: props.value,
          })
        ).unwrap();
    props.setactive(false);
  };

  props.value === "" && !props.active && props.setvalue("[Insert text here]");

  return (
    <div>
      {props.active.attribute !== props.attributeName && (
        <p
          className="m-0 cursor-pointer text-muted"
          onClick={(event) => {
            event.stopPropagation();
            props.setactive({ attribute: props.attributeName });
          }}>
          {props.value}
        </p>
      )}
      {props.active.attribute === props.attributeName && (
        <div className="form-group">
          <label className="d-none" htmlFor={props.attributeName}>
            {props.label}
          </label>
          <input
            id={props.attributeName}
            value={props.value}
            type="text"
            className="form-control text-end"
            maxLength={30}
            placeholder={props.placeholder}
            style={{ width: "280px" }}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary btn-sm mt-2"
              onClick={() => {
                onSave();
                props.setactive(false);
              }}>
              {t("profile.Save")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
