import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

function BreadcrumbExample(props) {
  const linkHandler = () => {
    props.activeInterface && props.setActiveInterface(null);
  };
  return (
    <>
      <h5 className="text-secondary text-uppercase text-center p-3">
        {props.activeInterface === null && "Tutorials Page"}
        {props.activeInterface === "student" && "Student Interface"}{" "}
        {props.activeInterface === "teacher" && "Teacher Interface"}
      </h5>
      <div className="d-flex justify-content-center">
        <Breadcrumb className="text-center">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={linkHandler}
            active={props.activeInterface === null ? true : false}
            linkAs={Link}
            linkProps={{ to: "/tutorials" }}>
            Tutorials Page
          </Breadcrumb.Item>
          {props.activeInterface === "student" && (
            <Breadcrumb.Item active>Student Interface</Breadcrumb.Item>
          )}
          {props.activeInterface === "teacher" && (
            <Breadcrumb.Item active>Teacher Interface</Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
    </>
  );
}

export default BreadcrumbExample;
