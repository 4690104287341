import { faImage, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ImageUploading from "react-images-uploading";
import { useTranslation } from "react-i18next";

export default function ImageUpload(props) {
  const { t } = useTranslation();
  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    props.setimages(imageList);
  };
  const removeAllImagesId = `removeAllImages${props.id}`;

  return (
    <div className="ImageUploader">
      <ImageUploading
        id="test"
        value={props.images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        maxFileSize={4000000}
        acceptType={["jpeg", "jpg", "png", "heic"]}>
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          // onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper d-flex justify-content-center align-items-center">
            <button
              id={removeAllImagesId}
              onClick={onImageRemoveAll}
              className="d-none">
              Remove all images
            </button>

            {imageList.length < 1 && (
              <>
                <button
                  className="uploadButton mt-3"
                  style={
                    isDragging
                      ? {
                          color: "green",
                          fontWeight: "bold",
                          borderColor: "green",
                        }
                      : null
                  }
                  onClick={() => {
                    onImageUpload();
                  }}
                  {...dragProps}>
                  <FontAwesomeIcon
                    icon={faImage}
                    size={"3x"}
                    className="mb-2"
                    style={
                      isDragging
                        ? {
                            color: "green",
                          }
                        : null
                    }
                  />
                  <h5>{t("posts.Click or Drop Image Here")}</h5>
                </button>
              </>
            )}

            {imageList.map((image, index) => (
              <div
                key={index}
                className="image-item mt-3 d-flex flex-row justify-content-center">
                <div>
                  <img
                    src={image.data_url}
                    alt="image_upload"
                    height="150px"
                    width="auto"
                  />
                </div>
                <div className="image-item__btn-wrapper">
                  <FontAwesomeIcon
                    id="imageDelete"
                    icon={faXmark}
                    size={"lg"}
                    className="hover-red"
                    onClick={() => onImageRemove(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
