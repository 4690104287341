import React from "react";
import {
  CheckCircleFill,
  CircleFill,
  HourglassBottom,
  SlashCircle,
} from "react-bootstrap-icons";
import { COLORS } from "../../utils/colors";
import MetricsCards from "../../components/Metrics/MetricsCards";
import { CircleLoader } from "react-spinners";
import { riskActivities } from "./risks";

export default function RiskPortfolio() {
  const iconClass = "fa-3x text-secondary";
  const cards = [
    {
      label: "Terminé",
      icon: <CheckCircleFill className={iconClass} color={"green"} />,
      data: 5,
      role: ["Student"],
    },
    {
      label: "En cours",
      icon: <HourglassBottom className={iconClass} color={"goldenrod"} />,
      data: 2,
      role: ["Student"],
    },
    {
      label: "Incomplète",
      icon: <SlashCircle className={iconClass} color={"gray"} />,
      data: 1,
      role: ["Student"],
    },
  ];
  return (
    <div className="d-flex flex-column justify-content-center">
      <h3 className="fw-300 my-2 text-center text-code text-uppercase">
        Mes prises de risques linguistiques
      </h3>
      <div className="d-flex flex-row justify-content-center">
        {cards.map((card, index) => (
          <div
            key={index}
            style={{ width: 200 }}
            className="d-flex flex-row align-items-center col-3 p-3">
            <div className="icon-container d-flex align-items-center">
              {card.icon}
            </div>
            <div className="ms-2 d-flex flex-column justify-content-center label-container p-2">
              <p className="text-xxs text-uppercase m-0">{card.label}</p>
              <h3 className="m-0 text-uppercase">{card.data}</h3>
            </div>
          </div>
        ))}
      </div>

      <div className="mx-auto col-6 bg-white p-2 rounded">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <p class="nav-link active" aria-current="page">
              Oral
            </p>
          </li>
          <li class="nav-item ms-1">
            <p
              class="nav-link cursor-pointer text-secondary"
              aria-current="page">
              Écoute
            </p>
          </li>
          <li class="nav-item ms-1">
            <p
              class="nav-link cursor-pointer text-secondary"
              aria-current="page">
              Écriture
            </p>
          </li>
          <li class="nav-item ms-1">
            <p
              class="nav-link text-secondary cursor-pointer"
              aria-current="page">
              Lecture
            </p>
          </li>
        </ul>
        {/* 
        <div className="row mt-2">
          <div className="col-2 text-uppercase">Nº</div>
          <div className="col-8 text-uppercase">Risque</div>
          <div className="col-2 text-uppercase">Statut</div>
        </div> */}

        {riskActivities.map(
          (activity, i) =>
            activity.strand === "Speaking" && (
              <div className="row">
                <div className="col-2 my-2">{activity.risk}</div>
                <div className="col-8 my-2">{activity.risk_fr}</div>
                <div className="col-2 my-2">
                  {activity.status === 0 && <SlashCircle color="gray" />}
                  {activity.status === 1 && (
                    <HourglassBottom color="goldenrod" />
                  )}
                  {activity.status === 2 && <CheckCircleFill color="green" />}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}
