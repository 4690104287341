import React, { createContext, useState } from "react";

export const TourContext = createContext({
  tourSwitch: false,
  setTourSwitch: () => {},
  userRole: "",
  setUserRole: () => {},
});

export const TourContextProvider = ({ children }) => {
  const [tourSwitch, setTourSwitch] = useState(false);
  const [userRole, setUserRole] = useState("");

  return (
    <TourContext.Provider
      value={{ tourSwitch, setTourSwitch, userRole, setUserRole }}>
      {children}
    </TourContext.Provider>
  );
};
