import {
  Book,
  CameraVideo,
  GraphUp,
  Lightning,
  PersonAdd,
  Trophy,
} from "react-bootstrap-icons";

export const studentTour = [
  {
    content: (
      <div>
        <h2>Let's get started!</h2>
        <img
          alt="Logo"
          id="main-image"
          className="mx-auto d-block pt-4"
          src="https://language-profile.azurewebsites.net/static/images/main.png"
        />
      </div>
    ),
    placement: "center",
    target: "body",
  },
  {
    content: (
      <div>
        <h4 className="pb-2">Video tutorials answer all your questions.</h4>
        <CameraVideo className="fa-3x mt-3" />
      </div>
    ),
    placement: "bottom",
    target: "#instructions",
  },
  {
    content: (
      <div>
        <h4 className="pb-2">See your progress here.</h4>
        <GraphUp className="fa-3x mt-3" />
      </div>
    ),
    placement: "bottom",
    target: "#progressButton",
  },

  {
    content: (
      <div>
        <h4>Get started by adding your goals.</h4>
        <Trophy className="fa-3x mt-3" />
      </div>
    ),
    placement: "bottom",
    target: "#addGoalButton",
  },
];

export const teacherTour = [
  {
    content: (
      <div>
        <h2>Let's get started!</h2>
        <img
          alt="Logo"
          id="main-image"
          className="mx-auto d-block pt-4"
          src="https://language-profile.azurewebsites.net/static/images/main.png"
        />
      </div>
    ),
    placement: "center",
    target: "body",
  },
  {
    content: (
      <div>
        <h4 className="pb-2">Video tutorials answer all your questions.</h4>
        <CameraVideo className="fa-3x mt-3" />
      </div>
    ),
    placement: "bottom",
    target: "#instructions",
  },
  {
    content: (
      <div>
        <h4 className="pb-2">
          Printable quickstart guides for easy step-by-step startup
          instructions.
        </h4>
        <Book className="fa-3x mt-3" />
      </div>
    ),
    placement: "bottom",
    target: "#quickstart",
  },
  {
    content: (
      <div>
        <h4>Get started by adding students here, or...</h4>
        <PersonAdd className="fa-3x mt-3" />
      </div>
    ),
    placement: "bottom",
    target: "#addStudentButton",
  },
  {
    content: (
      <div>
        <h4>
          If your students know their CEFR levels and can add themselves, use
          "Quick Add".
        </h4>
        <Lightning className="fa-3x mt-3" />
      </div>
    ),
    placement: "bottom",
    target: "#accountButton",
  },
];

export const joyrideStyles = {
  options: {
    //button color
    primaryColor: "green",
  },
};
