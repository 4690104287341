import React from "react";
import { languageListEn, languageListFr } from "./languageList";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { changeTextField } from "../../../reducers/sessionSlice";
import { changeTeacherPermissions } from "../../../reducers/teacherPermissionsSlice";
import { useTranslation } from "react-i18next";

export default function LanguageDropdown(props) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const languageArray =
    i18n.language === "en"
      ? Object.entries(languageListEn)
      : Object.entries(languageListFr);
  const options = [];
  for (let item of languageArray) {
    let i = { value: item[0], label: item[1] };
    options.push(i);
  }

  const handleChange = async (e) => {
    try {
      props.setvalue(e.value);
    } catch {
      props.setvalue(null);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      onSave();
    }
  };

  const onSave = () => {
    dispatch(
      props.modal
        ? changeTeacherPermissions({
            id: props.id,
            attribute_name: props.attributeName,
            content: props.value,
          })
        : changeTextField({
            id: props.id,
            attribute_name: props.attributeName,
            content: props.value,
          })
    ).unwrap();

    props.setactive(false);
  };

  return (
    <div className="w-100 d-flex flex-column align-items-end">
      {props.active.attribute !== props.attributeName && (
        <p
          className="m-0 cursor-pointer text-muted"
          onClick={(event) => {
            event.stopPropagation();
            props.setactive({ attribute: props.attributeName });
          }}>
          {props.value === null
            ? t("profile.Select Language")
            : i18n.language === "en"
            ? languageListEn[props.value]
            : languageListFr[props.value]}
        </p>
      )}
      {props.active.attribute === props.attributeName && (
        <>
          <Select
            defaultValue={options.filter(
              (option) => option.value === props.value
            )}
            options={options}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={"text-start w-50 form-control"}
            styles={{ width: "500px" }}
            placeholder={t("profile.Search Language...")}
          />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary btn-sm mt-2"
              onClick={() => {
                onSave();
                props.setactive(false);
              }}>
              {t("profile.Save")}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
