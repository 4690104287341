import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";

export default function LevelSelect({ onChange, formData, className }) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const appLevels = user?.district_levels;

  // LEVELS
  let cefrLevels = [];
  if (appLevels) {
    for (const [number, cefrLevel] of Object.entries(appLevels)) {
      cefrLevels.push({ label: cefrLevel.toLocaleUpperCase(), value: number });
    }
  }
  return (
    <div className={className}>
      <Select
        name="level"
        className="text-start w-100"
        options={cefrLevels}
        placeholder={t("addGoal.Level").toUpperCase()}
        onChange={onChange}
        value={cefrLevels?.filter((option) => option.value === formData?.level)}
      />
    </div>
  );
}
