import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const fetchGoals = createAsyncThunk(
  "goals/fetchGoals",
  async (student_id) => {
    const response = await fetch(`${baseUrl}/get_goals/${student_id}`);
    const goals = await response.json();
    return goals;
  }
);

export const addNewGoal = createAsyncThunk(
  "goals/addNewGoal",
  // The payload creator receives the partial `{title, content, user}` object
  // We send the initial data to the fake API server
  // The response includes the complete post object, including unique ID
  async (initialGoal) => {
    try {
      const response = await fetch(`${baseUrl}/add_goal`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(initialGoal),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  goals: [],
  status: "idle",
  error: null,
};

const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    goalAdded(state, action) {
      state.goals.push(action.payload);
    },
    clearGoals: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGoals.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.goals = state.goals.concat(action.payload);
      })
      .addCase(fetchGoals.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder.addCase(addNewGoal.fulfilled, (state, action) => {
      // We can directly add the new post object to our posts array
      state.goals.push(action.payload);
    });
  },
});

export const { goalAdded, clearGoals } = goalsSlice.actions;

export default goalsSlice.reducer;

export const selectAllGoals = (state) => state.goals.goals;

export const selectGoalsByStatus = createSelector(
  [selectAllGoals, (state, status) => status],
  (goals, status) => goals.filter((item) => item.status === status)
);
