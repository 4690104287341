import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";

export const handleMicrosoftLogin = (instance, loginType) => {
  if (loginType === "popup") {
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
  } else if (loginType === "redirect") {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }
};

export const SignInButton = () => {
  const { t } = useTranslation();

  const { instance } = useMsal();

  return (
    <Button
      onClick={() => handleMicrosoftLogin(instance, "redirect")}
      className={"btn-success btn-sm rounded-pill"}
      style={{ fontWeight: "bold" }}>
      {t("navbar.Login")}
    </Button>
  );
};
