import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import Select from "react-select";

export default function LevelSelect(props) {
  const user = useSelector(selectUser);
  const appLevels = props.appLevels || user?.district_levels;
  let cefrLevels = [];
  for (const [number, cefrLevel] of Object.entries(appLevels)) {
    cefrLevels.push({ label: cefrLevel.toLocaleUpperCase(), value: number });
  }

  return (
    <>
      <Select
        options={cefrLevels}
        className={props.className}
        name={props.name}
        onChange={props.onChange}
        isClearable={false}
        placeholder={props.placeholder}
        value={cefrLevels.filter(
          (option) => parseInt(option.value) === parseInt(props.getValue)
        )}
      />
    </>
  );
}
