import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Filter } from "react-bootstrap-icons";
import Select from "react-select";
import {
  selectGroupNames,
  selectGroupings,
} from "../../reducers/groupingsSlice";
import { Button, Modal } from "react-bootstrap";
import StrandSelect from "../TeacherAddGoal/StrandSelect";
import LevelSelect from "../TeacherAddGoal/LevelSelect";
import { selectDistrictGoals } from "../../reducers/sessionSlice";
import baseUrl from "../../utils/baseUrl";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import loading from "../../assets/icons/loading.png";

export default function ClassListFilter({
  setSelectedGroup,
  setGroupFilter,
  selectedGroup,
  setProgressByGoal,
  progressByGoal,
}) {
  const { t, i18n } = useTranslation();
  const districtGoals = useSelector(selectDistrictGoals);
  const groupNames = useSelector(selectGroupNames);
  const [options, setOptions] = useState([]);
  const groupings = useSelector(selectGroupings);
  const [showModal, setShowModal] = useState(false);
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const [isLoading, setIsLoading] = useState(false);
  const isEmpty = (obj) => {
    return Object.entries(obj).length === 0;
  };

  //TEMPLATE FOR EMPTY FORM
  const emptyForm = {
    level: "",
    strand: "",
    group: "",
    cefr_custom_id: "",
    studentIds: [],
  };
  const [formData, setFormData] = useState(emptyForm);

  //Adds student ids to form
  useEffect(() => {
    if (showModal) {
      if (teacherPermissions && teacherPermissions.length > 0) {
        const studentIds = teacherPermissions.map(
          (student) => student?.student_id
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          studentIds: studentIds,
        }));
      }
    }
  }, [teacherPermissions, showModal]);

  // STORES FORM DATA
  const formHandler = (e, action) => {
    if (action.name === "strand" || action.name === "level") {
      setFormData({
        ...formData,
        [action.name]: e.value,

        cefr_custom_id: "",
      });
    }
    if (action.name === "cefr_custom_id") {
      setFormData({
        ...formData,
        [action.name]: e.value,
      });
    }
  };

  // GOALS
  const filteredGoals = districtGoals?.filter(
    (item) => item.strand === formData.strand && item.level === formData.level
  );
  const goalOptions = filteredGoals?.map((item) => ({
    value: item.id,
    label: i18n.language === "en" ? item.statement : item.statement_fr,
  }));

  //GROUP FILTERS
  const onGroupSelect = (selectedOption) => {
    if (selectedOption) {
      setSelectedGroup(selectedOption.value);
    } else {
      setSelectedGroup([]);
    }
  };
  useEffect(() => {
    let x = groupNames.map((item) => {
      return { value: item.id, label: item.name.toUpperCase() };
    });
    x.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    setOptions(x);
  }, [setOptions, groupNames]);

  useEffect(() => {
    let list = [];
    groupings.map(
      (grouping) =>
        selectedGroup === grouping.grouping_id && list.push(grouping.student_id)
    );
    try {
      setGroupFilter(list);
    } catch (error) {
      console.log(error);
    }
  }, [groupings, selectedGroup, setGroupFilter]);

  //HANDLES FORM SUBMIT
  const handleSubmit = () => {
    const getProgressByGoal = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${baseUrl}/get_progress_by_goal`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Adjust content type if needed
          },
          body: JSON.stringify(formData),
        });
        if (!response.ok) {
          console.log(response);
          throw new Error("Failed to fetch progress by goal");
        }
        const data = await response.json();
        setProgressByGoal(data);
        setShowModal(false);
        setFormData(emptyForm);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching progress by goal:", error);
        setIsLoading(false);
      }
    };

    getProgressByGoal();
  };

  return (
    <>
      <div className="d-flex flex-row mb-1 align-items-center mb-2">
        <Filter className="me-1" />
        <p className="m-0 me-2 text-small">{t(`classList.Filter by`)}</p>
        <Button
          variant={!isEmpty(progressByGoal) ? "outline-dark" : "dark"}
          className={`rounded-pill me-1`}
          onClick={() => setProgressByGoal([])}>
          {t(`addGoal.Strand`)}
        </Button>
        <Button
          variant={isEmpty(progressByGoal) ? "outline-dark" : "dark"}
          className={`rounded-pill`}
          onClick={() => setShowModal(true)}>
          {t(`passModal.Goal`)}
        </Button>

        <Select
          isClearable={true}
          className={"text-start text-small w-25 ms-auto"}
          options={options}
          placeholder={t("addStudent.Group").toUpperCase()}
          styles={{ border: "none" }}
          onChange={onGroupSelect}
        />
      </div>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
          keyboard={false}>
          <Modal.Header>
            <Modal.Title> {t(`classList.Filter Options`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div className="py-5 d-flex flex-column justify-content-center align-items-center blink-effect">
                <img
                  alt="Logo"
                  id="main-image"
                  className="vw-10"
                  src={loading}
                />
                Loading/Chargement
              </div>
            ) : (
              <div>
                <p className="fw-bold">
                  {t(`classList.Filter by`)} {t(`passModal.Goal`).toLowerCase()}
                </p>
                {/* SET THE VALUE! */}
                <LevelSelect
                  className="mb-1"
                  onChange={formHandler}
                  formData={formData}
                />
                <StrandSelect onChange={formHandler} formData={formData} />
                {formData.strand !== "" && formData.level !== "" && (
                  <div className="row mt-2">
                    <Select
                      name="cefr_custom_id"
                      className="text-start w-100"
                      placeholder={t("addGoal.Objective").toUpperCase()}
                      isClearable={false}
                      options={goalOptions}
                      onChange={formHandler}
                      disabled={goalOptions.length === 0}
                      value={goalOptions?.filter(
                        (option) => option.value === formData.cefr_custom_id
                      )}
                    />
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
          {!isLoading && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button
                disabled={!formData.cefr_custom_id}
                variant="primary"
                onClick={handleSubmit}>
                Set Filter
              </Button>

              {/* Add any additional buttons here */}
            </Modal.Footer>
          )}
        </Modal>
      )}
    </>
  );
}
