import React, { useEffect } from "react";

export default function TermsOfService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-main px-1 px-lg-5 py-5">
      <div className="text-center mb-4">
        <div className="bg-main my-auto">
          <a href="/">
            <img
              alt="Logo"
              id="main-image"
              className="mx-auto d-block"
              src="https://language-profile.azurewebsites.net/static/images/main.png"
            />
          </a>

          <h1 className="text-center title">
            <span>Terms of Service</span>
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="card shadow p-4 mx-0 mx-lg-5">
          <h2>Introduction</h2>
          <p>
            These Terms of Service (the "Terms") govern your use of our websites
            located at pongolearning.com (together with any successor or
            affiliated sites, the “Site”), any mobile application provided by us
            (the “App”), and our learning services accessible via the Site and
            App, (collectively the “Services”) provided by Pongo Learning, Inc.
            ("us", "we", "our" or the "Company") to our users ("You”).
          </p>
          <p>
            By accessing or using the Services or clicking “accept” or “agree”
            to an electronic manifestation of this Agreement, (1) you
            acknowledge that you have read, understand, and agree to be bound by
            this Agreement, and (2) you represent and warrant that you are of
            legal age and not prohibited by law from accessing or using the
            Services or that you have complied with the requirements of Section
            2 below.
          </p>
          <p>
            You should also read our Privacy Policy, which is incorporated by
            reference into these Terms and available on the Site. If you do not
            accept and agree to be bound by all the terms of this Agreement,
            including the Privacy Policy, do not access the Services through the
            Site, install the App, or use the Services.
          </p>
          <p>
            We may update or revise these Terms (including the Privacy Policy
            and any other policies) from time to time at our sole discretion.
            You agree that you will review these Terms periodically. You are
            free to decide whether or not to accept such a modified version of
            these Terms, but accepting these Terms, as modified, is required for
            you to continue using the Services. If you do not agree to these
            Terms or any modified version thereof, your sole recourse is to
            terminate your use of the Services, in which case you will no longer
            have access to your account. Except as otherwise expressly stated,
            any use of the Services is subject to the version of these Terms in
            effect at the time of use. We hereby grant you a limited,
            terminable, non-sublicensable, non-sublicensable, non-exclusive,
            royalty-free license to use the Services solely for their intended
            purpose and in a manner consistent with these Terms.
          </p>
          <p>
            You can contact us anytime with questions about these Terms at
            anthony@pongolearning.com
          </p>

          <h2>Eligibility</h2>
          <p>
            If you are under the age of majority in your jurisdiction, you must
            present these Terms to your parent or legal guardian to review and
            to enter into it on your behalf. We reserve the right to discontinue
            your use of the App and the Services if we determine that the
            foregoing age requirements have not been respected or we determine
            that users under the age of majority have not sought parental
            consent to use the Services. To the extent that any parent or
            guardian of a User under the age of majority has agreed to these
            Terms on your behalf, the parent or guardian is deemed to be the
            user for the purposes of these Terms and is jointly and severally
            liable for your obligations contained herein.
          </p>
          <p>
            By accessing and using the Services, you represent and warrant that
            you have the right, authority and capacity to enter into these Terms
            and to abide by all of the terms and conditions of these Terms.
            Using the Services may be prohibited or restricted in certain
            countries. You are responsible for complying with the laws and
            regulations of the territory from which you access or use the
            Services.
          </p>

          <h2>Digital Consent: Children</h2>
          <p>
            If you are uploading information on behalf of a user you represent
            and warrant to Pongo that prior to uploading any Personal Data, as
            defined in the Privacy Policy, of any child onto the Services, you
            have obtained the legally binding consent of the parent or legal
            guardian of each such child. In addition, you should consult with
            your school or district to ensure that you obtain proper consent to
            use the Services in the classroom consistent with your school’s or
            district’s policies.
          </p>

          <h2>Creating a Teacher Account</h2>
          <p>
            Only teachers or school administrators are permitted to create a
            class on the Services. Once the class is created, teachers can
            invite students, additional faculty, and family members to the
            class.
          </p>
          <p>
            Teachers can make changes on students’ accounts, but it is advisable
            they first ask permission.
          </p>
          <p>
            When you create an account and add students to your profile, you
            must warrant the following:
          </p>
          <ul>
            <li>Students you add are in your class;</li>
            <li>
              All teachers and family members you link to students account are
              authorized to access Personal Data of that student;
            </li>
            <li>
              You will only use the Site, App, and the Services for lawful
              purposes;
            </li>
            <li>You will abide by school and district policies; and</li>
            <li>
              You will abide by any Professional Code of Ethics applicable to
              you.
            </li>
          </ul>

          <h2>Creating a Family Member Account</h2>
          <p>
            Teachers can authorize family members to view and comment on
            information posted on their student’s profile.
          </p>
          <p>
            Your student’s teacher or administrator can request to suspend your
            account if reasonable justification is provided. They are also
            permitted to sever the family member-student account link at any
            time.
          </p>

          <h2>Creating a Student Account</h2>
          <p>
            Students may use Pongo only if their parent or legal guardian has
            given permission to their teacher or school. You agree to use the
            Services only if you have been invited to use the Services by your
            teacher.
          </p>

          <h2>Posts</h2>
          <p>
            You represent and warrant to us that you have sufficient right,
            title, and interest: (i) to post all content you post ("User
            Content”) using the Services, and (ii) to accordingly grant us the
            rights in such content contained herein. While posts are typically
            only shared amongst students, their teachers and family members, it
            is possible that developers, other members of the school district,
            and other interested parties we permit, may see posts when testing
            or evaluating the Services.
          </p>

          <h2>Communications</h2>
          <p>
            At this time, only invitations, changes to privacy or terms of
            service emails will be sent to the address used to register for your
            account. If deemed necessary, occasional emails will be sent to
            inform users of major updates.
          </p>

          <h2>Pongo's Intellectual Property</h2>
          <p>
            All content on the Services that is not User Content, including but
            not limited to logos, trademarks, copyrights, domain names, or other
            distinctive brand features (collectively, “Pongo Content’) is
            protected by copyright, trademark, and other intellectual property
            laws, is, and shall remain, the exclusive property of Pongo and its
            licensors. We and our licensors grant you a limited, non-exclusive,
            non-transferable license to view, copy, and display Pongo Content
            solely in connection with your permitted use of the Services. Any
            rights not expressly granted here are reserved. Unauthorized use of
            Pongo Content is prohibited. You agree not to remove, alter or
            obscure any copyright, trademark, service mark, or other proprietary
            rights notices incorporated in or accompanying the Services. You
            grant us a perpetual, royalty-free, worldwide license to use the
            User Content as we see fit in our sole discretion.
          </p>

          <h2>Copyright Policy</h2>
          <p>
            Pongo will terminate, in appropriate circumstances, account holders
            who repeatedly infringe or are believed to be repeatedly infringing
            the rights of copyright holders.
          </p>

          <h2>Third-Party Service Providers</h2>
          <p>
            The Services (including the App) may allow you to access third-party
            websites or other resources, for example when a teacher includes a
            link to an instructional YouTube video in an activity. We provide
            access only as a convenience and are not responsible for the
            content, products, or services on or available from those resources
            or links displayed on such websites. You acknowledge sole
            responsibility for and assume all risk arising from your use of any
            third-party resources.
          </p>

          <h2>What is Absolutely Not Permitted?</h2>
          <p>
            You must agree to not engage in the following actions, or your
            account may be terminated:
          </p>
          <ul>
            <li>
              Posting or submitting content that violates intellectual property
              rights, promotes illegal activities, or is inappropriate for the
              classroom.
            </li>
            <li>Jeopardizing the security of your account.</li>
            <li>
              Using the Services for commercial purposes or in a manner not
              permitted by these Terms.
            </li>

            <li>fraudulent, false, misleading, or deceptive;</li>
            <li>is defamatory, obscene, pornographic, vulgar, or offensive;</li>
            <li>
              promotes discrimination, bigotry, racism, hatred, harassment, or
              harm against any individual or group;
            </li>
            <li>
              is violent or threatening or promotes violence or actions that are
              threatening to any person or entity;
            </li>
            <li>promotes illegal or harmful activities or substances; or</li>
            <li>
              is inappropriate for the classroom or violates applicable school
              or school district policies;
            </li>
          </ul>

          <h2>Indemnity</h2>
          <p>
            You agree to indemnify and hold harmless Pongo from any claims,
            suits, actions, losses, costs, damages, and any other liabilities,
            including attorneys’ fees, arising out of or related to: (a) your
            use or misuse of the Services; (b) any violation of the rights of
            any other person or entity by you, including without limitation, any
            intellectual property right, publicity, confidentiality, property or
            privacy right; or (c) your breach of any part of these Terms. We
            will attempt to provide you with written notice of any such matter;
            however, any failure or delay by us to do so does not negate your
            defense or indemnification obligations or waive our rights to seek
            payment or defense or indemnification from you. We reserve the
            right, at your expense, to assume the exclusive defense and control
            of any matter for which you are required to indemnify us, and you
            agree to cooperate with us in our defense of these claims. You will
            not settle any claim that affects us or our affiliates without our
            prior written approval.
          </p>

          <h2>Limitation of Liability</h2>
          <p>
            Under no legal theory, including, but not limited to negligence,
            will Pongo or its affiliates be liable for any indirect, incidental,
            special, consequential, or exemplary damages, including, but not
            limited to, damages for loss of profits, cost of cover, goodwill,
            use, data, or other intangible losses (even if Pongo has been
            advised of the possibility of such damages), arising from or
            relating to (i) these Terms; (ii) your use or the inability to use
            the Services; or (iii) any interaction with any third-party through
            or in connection with the Services, including other users.
          </p>
          <p>
            In no event will Pongo or the Pongo parties be liable to you in the
            aggregate (for all potential claims by you) for any damages incurred
            in excess of the greater of any fees you have actually paid to us
            for use of the Services in the 12 months prior to the events giving
            rise to the claim, or one hundred dollars ($100).
          </p>
          <p>
            Certain jurisdictions do not allow limitations on implied warranties
            or the exclusion or limitation of certain damages. If you reside in
            such a jurisdiction, some or all of the above disclaimers,
            exclusions, or limitations may not apply to you, and you may have
            additional rights. In such cases, Pongo’s liability will be limited
            to the fullest extent permitted by law.
          </p>

          <h2>Informal Dispute Resolution</h2>
          <p>
            We want to address your concerns without litigation or other formal
            proceedings. Before filing a claim against Pongo, you agree to try
            to resolve the Dispute informally by contacting
            anthony@pongolearning.com and providing us with reasonably detailed
            information concerning your issue. We will try to resolve the
            Dispute informally by contacting you through email. If a dispute is
            not resolved within 15 days after submission, you or Pongo may bring
            a formal proceeding in accordance with these Terms.
          </p>
          <p>
            Nothing herein shall be interpreted as limited our right to seek
            immediate injunctive relief, when necessary, to enforce these Terms.
          </p>

          <h2>Feedback</h2>
          <p>
            Feedback is always welcome. If you choose to submit feedback, you
            agree that we are free to use it without any restriction or
            compensation to you.
          </p>

          <h2>Entire Agreement</h2>
          <p>
            These Terms (and any other policies we refer to in this document)
            make up the entire agreement between you and Pongo Learning, Inc.,
            and supersede any prior agreement. If Pongo fails to enforce any
            part of these Terms, such a failure does not constitute a waiver. If
            any provision of these Terms is held invalid or unenforceable by an
            arbitrator or a court of competent jurisdiction, that provision will
            be enforced to the maximum extent permissible, and the other
            provisions of these Terms will remain in full force and effect. You
            may not assign or transfer these Terms, by operation of law or
            otherwise, without our prior written consent. Any attempt by you to
            assign or transfer these Terms, without such consent, will be null.
            We may freely assign or transfer these Terms without restriction.
            Subject to the foregoing, these Terms will bind and inure to the
            benefit of the parties, their successors, and permitted assigns.
          </p>
        </div>
      </div>
    </div>
  );
}
