import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Gear } from "react-bootstrap-icons";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../reducers/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import GroupingsModal from "./GroupingsModal";

import {
  addGroup,
  fetchGroupings,
  selectGroupNames,
} from "../../reducers/groupingsSlice";

export default function GroupingsTable() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const groupNames = useSelector(selectGroupNames);
  const { t } = useTranslation();
  const [formData, setFormData] = useState("");
  const [groupingId, setGroupingId] = useState("");
  const groupingsStatus = useSelector((state) => state.groupings.status);

  useEffect(() => {
    if (groupingsStatus === "idle") {
      dispatch(fetchGroupings(user.id));
    }
  }, [groupingsStatus, dispatch, user.id]);

  const formHandler = (e, action) => {
    setFormData(e.target.value);
  };

  const openModalHandler = (id) => {
    setShowModal(true);
    setGroupingId(id);
  };

  const addHandler = () => {
    if (formData !== "") {
      dispatch(
        addGroup({
          name: formData,
          teacher_id: user.id,
        })
      ).unwrap();
      setFormData("");
    }
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      addHandler();
    }
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <h4 className="text-center text-uppercase mt-4 p-2">
        {t("addStudent.Group")}s
      </h4>
      <div className="container card p-2 bg-white w-50">
        <table className="table m-0 p-2">
          <thead>
            <tr>
              <th scope="col"></th>
              <th
                className="cursor-pointer user-select-none fw-normal text-uppercase"
                scope="col">
                {t("addStudent.Name")}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {groupNames.map((grouping, i) => {
              return (
                <tr
                  key={i}
                  className="text-small align-middle cursor-pointer "
                  onClick={() => openModalHandler(grouping.id)}>
                  <th>{i + 1}</th>
                  <td>{grouping.name}</td>
                  <td className="text-end">
                    <Gear className="my-auto h5" />
                  </td>
                </tr>
              );
            })}
            <tr className="text-small align-middle">
              <th>{groupNames?.length + 1}</th>
              <td>
                <input
                  type="groupName"
                  className="form-control text-small"
                  placeholder={t("addStudent.Insert new group")}
                  aria-describedby="groupName"
                  onChange={formHandler}
                  onKeyDown={keyDownHandler}
                  maxLength="15"
                  value={formData || ""}
                  name="group_name"
                />
              </td>
              <td className="text-end">
                <div onClick={addHandler}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="text-success cursor-pointer my-auto h5"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showModal && (
        <GroupingsModal
          show={showModal}
          groupingId={groupingId}
          handleClose={() => {
            setGroupingId("");
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}
