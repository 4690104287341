import { faCheck, faGlobe, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  countryListEn,
  countryListFr,
} from "../profile/CountryDropdown/countryList";
import toTitleCase from "../../utils/toTitleCase";
import {
  languageListEn,
  languageListFr,
} from "../profile/LanguageDropdown/languageList";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { addNewStudent } from "../../reducers/teacherPermissionsSlice";
import grades from "../../utils/grades";
import LevelSelect from "./LevelSelect";
import { useNavigate } from "react-router";
import baseUrl from "../../utils/baseUrl";
import { selectUser } from "../../reducers/sessionSlice";

export default function AddStudentModal({
  handleClose,
  show,
  district_levels,
  school,
  district_goals,
  presetEmail,
}) {
  const dispatch = useDispatch();
  const appLevels = district_levels;
  let cefrLevels = [];
  if (appLevels) {
    for (const [number, cefrLevel] of Object.entries(appLevels)) {
      cefrLevels.push({ label: cefrLevel.toLocaleUpperCase(), value: number });
    }
  }
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [errors, setErrors] = useState(null);
  const [isChecking, setIsChecking] = useState(null);
  const [userAlreadyAdded, setUserAlreadyAdded] = useState(false);
  const [message, setMessage] = useState("");
  const [userSearchComplete, setUserSearchComplete] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const { t, i18n } = useTranslation();
  const languageArray =
    i18n.language === "en"
      ? Object.entries(languageListEn)
      : Object.entries(languageListFr);
  const languageOptions = [];
  for (let item of languageArray) {
    let i = { value: item[0], label: item[1] };
    languageOptions.push(i);
  }
  // Remove the "overall" strand
  let strands = district_goals?.map((goal) => {
    return goal.strand;
  });
  strands = [...new Set(strands)];
  const emptyForm = {
    id: nanoid(),
    first_name: "",
    last_name: "",
    role: "Student",
    email: "",
    country0: "",
    language0: "",
    userExists: false,
    current_grade: "",
    primary_teacher_id: user.id,
    school_id: user.school_id,
    school: user.school,
    district_id: user.district_id,
  };
  strands?.map((item) => (emptyForm[item] = ""));

  const [formData, setFormData] = useState(emptyForm);
  let validationErrors = {};

  const formHandler = (e, action) => {
    try {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } catch {
      // For select dropdowns
      try {
        setFormData({ ...formData, [action.name]: e.value });
      } catch {
        setFormData({ ...formData, [action.name]: "" });
      }
    }
  };

  const applyAll = (val) => {
    let dict = {};
    strands?.map((strand) => (dict[strand] = val));
    setFormData({ ...formData, ...dict });
  };

  useEffect(() => {
    if (!!presetEmail) {
      setFormData({ ...formData, email: presetEmail });
    }
  }, [formData, presetEmail]);

  const formValidation = () => {
    const validationErrors = {}; // Initialize a new object
    if (!formData.first_name.trim()) {
      validationErrors.first_name = true;
    }
    if (!formData.last_name.trim()) {
      validationErrors.last_name = true;
    }
    if (!formData.email.trim()) {
      validationErrors.email = true;
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      validationErrors.invalidEmail = true;
    }
    if (!formData.country0) {
      validationErrors.country0 = true;
    }
    if (!formData.language0) {
      validationErrors.language0 = true;
    }

    strands?.forEach((item) => {
      if (!formData[item]) {
        validationErrors[item] = true;
      }
    });

    setErrors(validationErrors);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    formValidation();
    if (userExists || Object.keys(validationErrors).length === 0) {
      await dispatch(addNewStudent(formData)).unwrap();
      setFormData(emptyForm);
      handleClose();
      presetEmail && navigate("/");
    }
  };

  const checkUserExists = async () => {
    setIsChecking(true);
    setMessage("");
    setUserSearchComplete(false);

    try {
      const response = await fetch(
        `${baseUrl}/check_user/${encodeURIComponent(formData.email)}/${
          user.id
        }`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);

      if (data.user_found) {
        setUserExists(true);
        setFormData({ ...formData, userExists: true });
        setMessage("User exists! ✅");
      } else if (data.user_already_added) {
        setUserAlreadyAdded(true);
        setMessage("User already added to your account! Try again.");
      } else {
        setMessage("");
      }
    } catch (error) {
      setMessage("Error checking user. Please try again.");
    } finally {
      setIsChecking(false);
      setUserSearchComplete(true);
    }
  };

  const countryList = i18n.language === "en" ? countryListEn : countryListFr;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("addStudent.Add Student")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <p className="fw-bold text-muted">
              <FontAwesomeIcon icon={faUser} className={"me-2"} />
              {t("addStudent.User Information")}
            </p>
            <div className="col-12 mb-2">
              {errors?.email && !errors?.invalidEmail && (
                <p className="text-danger text-xs m-0">
                  *{t("addStudent.Required")}
                </p>
              )}
              {errors?.invalidEmail && (
                <p className="text-danger text-xs m-0">
                  * Invalid format (example@domain.com)
                </p>
              )}
              <input
                type="email"
                name="email"
                // disabled={!!presetEmail}
                placeholder={t("addStudent.Email")}
                onChange={formHandler}
                className="form-control"
                aria-describedby="email"
                value={formData.email}
                disabled={!!presetEmail || isChecking}
                onBlur={() => {
                  formData.email && checkUserExists();
                }}
                onEnter={() => {
                  formData.email && checkUserExists();
                }}
              />
              {message && <p className="text-xs m-0 text-end">{message}</p>}

              {errors?.email && (
                <div className="col-12 col-lg-6 mt-2">
                  <p className="text-danger text-xs m-0">
                    *{t("addStudent.Required")}
                  </p>
                </div>
              )}

              {presetEmail && (
                <p className="text-xs text-secondary">
                  {i18n.language === "en"
                    ? "Not your email? Logout and try again."
                    : "Ce n'est pas votre courriel ? Déconnectez-vous et réessayez."}
                </p>
              )}
            </div>
            {!!userSearchComplete && !userExists && (
              <>
                <div className="col-12 col-lg-4">
                  {errors?.first_name && (
                    <p className="text-danger text-xs m-0">
                      *{t("addStudent.Required")}
                    </p>
                  )}
                  <input
                    type="first_name"
                    name="first_name"
                    placeholder={t("addStudent.First Name")}
                    onChange={formHandler}
                    className="form-control"
                    aria-describedby="firstName"
                    value={formData.first_name}
                    autoFocus
                  />
                </div>
                <div className="col-12 col-lg-4">
                  {errors?.last_name && (
                    <p className="text-danger text-xs m-0">
                      *{t("addStudent.Required")}
                    </p>
                  )}
                  <input
                    type="last_name"
                    name="last_name"
                    onChange={formHandler}
                    placeholder={t("addStudent.Last Name")}
                    className="form-control"
                    aria-describedby="lastName"
                    value={formData.last_name}
                  />
                </div>
                <div className="col-12 col-lg-4">
                  <Select
                    options={grades}
                    onChange={formHandler}
                    name="current_grade"
                    placeholder={t("addStudent.Current Grade")}
                    className="text-start w-100"
                    isClearable={true}
                    value={grades.filter(
                      (option) => option.value === formData.current_grade
                    )}
                  />
                </div>

                <div className="col-12 mt-2">
                  <input
                    type="school"
                    name="school"
                    className="form-control"
                    aria-describedby="school"
                    disabled={true}
                    value={school}
                  />
                </div>
              </>
            )}
          </div>
          {!!userSearchComplete && !userExists && (
            <>
              <div className="row mt-3">
                <p className="fw-bold m-0 py-3 text-muted">
                  <FontAwesomeIcon icon={faGlobe} className={"me-2"} />
                  {t("addStudent.Origins")}
                </p>
                <div className="col-12 col-lg-6">
                  <label className="d-none" htmlFor="countries">
                    {t("addStudent.Home Country")}
                  </label>
                  {errors?.country0 && (
                    <p className="text-danger text-xs m-0">
                      *{t("addStudent.Required")}
                    </p>
                  )}
                  <Select
                    options={countryList}
                    onChange={formHandler}
                    name="country0"
                    value={countryList.filter(
                      (option) => option.value === formData.country0
                    )}
                    className="text-start w-100"
                    styles={{ width: "500px" }}
                    placeholder={t("addStudent.Home Country")}
                    isClearable={true}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <label className="d-none" htmlFor="languages">
                    {t("addStudent.Language")}
                  </label>
                  {errors?.language0 && (
                    <p className="text-danger text-xs m-0">
                      *{t("addStudent.Required")}
                    </p>
                  )}
                  <Select
                    options={languageOptions}
                    onChange={formHandler}
                    name="language0"
                    placeholder={t("addStudent.Home Language")}
                    className="text-start w-100"
                    styles={{ width: "500px" }}
                    isClearable={true}
                    value={languageOptions.filter(
                      (option) => option.value === formData.language0
                    )}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <p className="m-0 py-3 text-muted">
                  <FontAwesomeIcon icon={faCheck} className={"me-2"} />
                  <span className="fw-bold">{t("addStudent.Levels")}</span>
                </p>
                <p>
                  <span className="text-xs text-muted me-4">
                    {t("addStudent.Apply to all")}:
                  </span>
                  {cefrLevels?.map((item, i) => (
                    <span
                      key={i}
                      onClick={() => applyAll(item.value)}
                      className="text-xs text-muted mx-2 text-decoration-underline cursor-pointer">
                      {item.label}
                    </span>
                  ))}
                </p>
                {strands?.map((item) => (
                  <div key={item} className="col-12 col-lg-4 mb-2">
                    <label className="d-none" htmlFor={item}>
                      {t(`addStudent.${toTitleCase(item)}`)}
                    </label>
                    {errors?.[item] && (
                      <p className="text-danger text-xs m-0">
                        *{t("addStudent.Required")}
                      </p>
                    )}
                    <LevelSelect
                      name={item}
                      onChange={formHandler}
                      formData={formData}
                      getValue={formData[item]}
                      placeholder={t(`addStudent.${toTitleCase(item)}`)}
                      className="text-start w-75"
                      appLevels={appLevels}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </Modal.Body>
        {!!userSearchComplete && !userAlreadyAdded && (
          <Modal.Footer>
            <Button
              variant="secondary"
              type="reset"
              onClick={() => {
                setErrors("");
                setFormData(emptyForm);
              }}>
              {t("addStudent.Reset")}
            </Button>

            <Button variant="primary" onClick={onFormSubmit}>
              {t("addStudent.Submit")}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
