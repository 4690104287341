import React, { useState, useEffect } from "react";
import TeacherMetricsCards from "./TeacherMetricsCards";
import BarGraph from "./BarGraph";
import { useDispatch, useSelector } from "react-redux";
import { selectAllProgressTeacher } from "../../reducers/progressTeacherSlice";
import GoalsSetByStrand from "./GoalsSetByStrand";
import { fetchAllProgressTeacher } from "../../reducers/progressTeacherSlice";
import baseUrl from "../../utils/baseUrl";
import {
  fetchTeacherPermissions,
  selectTeacherPermissions,
} from "../../reducers/teacherPermissionsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import LevelBreakdown from "./LevelBreakdown";
import { useTranslation } from "react-i18next";

export default function MetricsSkeleton() {
  const { t } = useTranslation();
  const [postData, setPostData] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [flashing, setFlashing] = useState(true);
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const teacherPermissionsStatus = useSelector(
    (state) => state.teacherPermissions.status
  );
  const studentIds = teacherPermissions?.map((student) => student.student_id);
  const progressData = useSelector(selectAllProgressTeacher);
  const progressStatus = useSelector((state) => state.progressTeacher.status);

  useEffect(() => {
    if (teacherPermissionsStatus === "idle") {
      dispatch(
        fetchTeacherPermissions({
          search_by_adult_or_student: "adult",
          id: user?.id,
        })
      );
    }
  }, [teacherPermissionsStatus, dispatch, user]);

  useEffect(() => {
    if (progressStatus === "idle" && !!studentIds) {
      dispatch(fetchAllProgressTeacher({ studentIds: studentIds }));
    }
  }, [progressStatus, dispatch, studentIds]);

  useEffect(() => {
    progressStatus === "succeeded" && setFlashing(false);
  }, [progressStatus]);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getPostData = async () => {
      const rawResponse = await fetch(`${baseUrl}/get_teacher_metrics`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: user.id, permissions: studentIds }),
      });
      const content = await rawResponse.json();
      setPostData(content);
      if (content.error) {
        console.log("Error");
      } else {
        console.log("Metrics fetched successfully");
      }
    };
    if (
      progressStatus === "succeeded" &&
      teacherPermissionsStatus === "succeeded" &&
      postData.length === 0 &&
      !isFetching
    ) {
      setIsFetching(true);
      getPostData();
    }
  }, [
    postData,
    studentIds,
    user,
    isFetching,
    setIsFetching,
    setPostData,
    teacherPermissionsStatus,
    progressStatus,
  ]);
  return (
    <>
      <div className="container min-vh-90 d-flex align-items-center">
        <div className="row">
          <h3 className="text-center mb-5">{t(`data.Teacher Dashboard`)}</h3>
          <div className="col-12 col-lg-6">
            <div className="container">
              <TeacherMetricsCards
                progressData={progressData}
                flashing={flashing}
                postData={postData}
              />
            </div>
            <div className="container mt-4">
              <h5 className="fw-bold">{t(`data.Post History`)}</h5>

              <BarGraph
                label={"Student"}
                postData={postData.all_posts_timestamps}
                loading={flashing}
              />
            </div>
          </div>
          <div className="d-flex align-items-center col-12 col-lg-6">
            <div className="row">
              <div className="d-flex flex-row justify-content-center">
                <div className="col-5 me-4">
                  <p className="mb-1 fw-bold ms-auto">
                    {t(`data.Goals Set by Strand`)}
                  </p>
                  <div className="card shadow p-3 d-flex flex-row justify-content-center">
                    <GoalsSetByStrand
                      progressData={progressData}
                      isLoaded={!flashing}
                    />
                  </div>
                </div>
                <div className="col-5">
                  <p className="mb-1 fw-bold">{t(`data.Level Breakdown`)}</p>
                  <div className="card shadow p-3 d-flex flex-row justify-content-center">
                    <LevelBreakdown
                      progressData={progressData}
                      isLoaded={!flashing}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4 px-5">
                <p className="mb-1 fw-bold ms-auto">
                  {t(`data.Teacher Formative Feedback`)}
                </p>
                <div className="shadow">
                  <BarGraph
                    label={"Teacher"}
                    postData={postData.teacher_feedback_timestamps}
                    loading={flashing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
