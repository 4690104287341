import React from "react";
// import OverallProgressCard from "../Progress/OverallProgressCard";
import ProgressTable from "./ProgressTable";
import CefrExplained from "../Progress/CefrExplained";
import { useTranslation } from "react-i18next";

export default function ProgressTab(props) {
  const { t } = useTranslation();

  const content = props.progressData && (
    <>
      {/* <div className="d-flex justify-content-center col-12 mb-2">
        <OverallProgressCard
          key="overall"
          strand={props.progressData[0]?.strand}
          size="sm"
          percentage={props.progressData[0]?.percentage}
          overall_cefr_grade={props.progressData[0]?.overall_cefr_grade}
        />
      </div> */}
      <div className="card mb-2">
        {" "}
        <div className=" m-0 text-center text-uppercase text-secondary pt-2 fw-bold ">
          <h3 className="m-0">{props.progressData[0]?.overall_cefr_grade}</h3>

          <p className="text-xs">{t("cefr.Overall Level")}</p>
        </div>
        <CefrExplained />
      </div>
      <div className="col-12 d-flex align-content-center ">
        {props.progressData && (
          <ProgressTable
            progressData={props.progressData}
            studentId={props.studentId}
            activeLevelChange={props.activeLevelChange}
            setActiveLevelChange={props.setActiveLevelChange}
          />
        )}
      </div>
    </>
  );
  return content;
}
