import React, { useEffect } from "react";
import "./Styles.css";

export default function Credits() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bg-main px-1 px-lg-5 py-5">
        <div className="text-center mb-4">
          <div className="bg-main my-auto">
            <a href="/">
              <img
                id="main-image"
                className="mx-auto d-block"
                src="https://language-profile.azurewebsites.net/static/images/main.png"
                loading="lazy"
                alt="Logo"
              />
            </a>

            <h1 className="text-center title pt-4">
              <span>Credits</span>
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="card shadow p-4 mx-0 mx-lg-5">
            <div className="row">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/add.png"
                  loading="lazy"
                  className="img-credits"
                  alt="Add"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/google-plus"
                  title="google plus icons">
                  Google plus icons created by Smashicons - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/admin.png"
                  loading="lazy"
                  className="img-credits"
                  alt="Admin"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/administrator"
                  title="administrator icons">
                  Administrator icons created by Muhammad Atif - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/bugs.png"
                  loading="lazy"
                  className="img-credits"
                  alt="Bugs"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/virus"
                  title="virus icons">
                  Virus icons created by juicy_fish - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/countries.png"
                  loading="lazy"
                  className="img-credits"
                  alt="Countries"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/countries"
                  title="countries icons">
                  Countries icons created by Freepik - Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/district.png"
                  loading="lazy"
                  className="img-credits"
                  alt="district"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/buildings"
                  title="buildings icons">
                  Buildings icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/email.png"
                  loading="lazy"
                  className="img-credits"
                  alt="email"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/send"
                  title="send icons">
                  Send icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/error.png"
                  loading="lazy"
                  className="img-credits"
                  alt="Error"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/error"
                  title="error icons">
                  Error icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/fly.png"
                  loading="lazy"
                  className="img-credits"
                  alt="fly"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/fly"
                  title="fly icons">
                  Fly icons created by Freepik - Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal1.png"
                  loading="lazy"
                  className="img-credits"
                  alt="goal"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/goal"
                  title="goal icons">
                  Goal icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal2.png"
                  loading="lazy"
                  className="img-credits"
                  alt="goal"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/goal"
                  title="goal icons">
                  Goal icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal3.png"
                  loading="lazy"
                  className="img-credits"
                  alt="goal"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/success"
                  title="success icons">
                  Success icons created by Becris - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal4.png"
                  loading="lazy"
                  className="img-credits"
                  alt="goal"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/emotional-intelligence"
                  title="emotional intelligence icons">
                  Emotional intelligence icons created by Parzival’ 1997 -
                  Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/main.png"
                  loading="lazy"
                  className="img-credits"
                  alt="languages"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/languages"
                  title="languages icons">
                  Languages icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/loading.png"
                  loading="lazy"
                  className="img-credits"
                  alt="loading"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/wait"
                  title="wait icons">
                  Wait icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/school.png"
                  loading="lazy"
                  className="img-credits"
                  alt="school"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/school"
                  title="school icons">
                  School icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/student.png"
                  loading="lazy"
                  className="img-credits"
                  alt="goal"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/student"
                  title="student icons">
                  Student icons created by Freepik - Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/teacher.png"
                  loading="lazy"
                  className="img-credits"
                  alt="teacher"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/teacher"
                  title="teacher icons">
                  Teacher icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Listening_goals/Listening1.png"
                  loading="lazy"
                  className="img-credits"
                  alt="listening"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/communication"
                  title="communication icons">
                  Communication icons created by Eucalyp - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Listening_goals/Listening2.png"
                  loading="lazy"
                  className="img-credits"
                  alt="listening"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/listen"
                  title="listen icons">
                  Listen icons created by Eucalyp - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Listening_goals/Listening3.png"
                  loading="lazy"
                  className="img-credits"
                  alt="listening"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/listening"
                  title="listening icons">
                  Listening icons created by Freepik - Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Listening_goals/Listening4.png"
                  loading="lazy"
                  className="img-credits"
                  alt="listening"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/listen"
                  title="listen icons">
                  Listen icons created by Eucalyp - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Reading_goals/Reading1.png"
                  loading="lazy"
                  className="img-credits"
                  alt="reading"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/read"
                  title="read icons">
                  Read icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Reading_goals/Reading2.png"
                  loading="lazy"
                  className="img-credits"
                  alt="reading"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/read"
                  title="read icons">
                  Read icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Reading_goals/Reading3.png"
                  loading="lazy"
                  className="img-credits"
                  alt="reading"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/knowledge"
                  title="knowledge icons">
                  Knowledge icons created by Freepik - Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Reading_goals/Reading4.png"
                  loading="lazy"
                  className="img-credits"
                  alt="reading"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/reading"
                  title="reading icons">
                  Reading icons created by mangsaabguru - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Social_goals/Social2.png"
                  loading="lazy"
                  className="img-credits"
                  alt="social-emotional"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/emotional"
                  title="emotional icons">
                  Emotional icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Social_goals/Social3.png"
                  loading="lazy"
                  className="img-credits"
                  alt="social-emotional"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/emotional"
                  title="emotional icons">
                  Emotional icons created by Flat Icons - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Social_goals/Social4.png"
                  loading="lazy"
                  className="img-credits"
                  alt="social-emotional"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/emotions"
                  title="emotions icons">
                  Emotions icons created by Freepik - Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Speaking_goals/Speaking1.png"
                  loading="lazy"
                  className="img-credits"
                  alt="speaking"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/speak"
                  title="speak icons">
                  Speak icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Speaking_goals/Speaking2.png"
                  loading="lazy"
                  className="img-credits"
                  alt="speaking"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/speak"
                  title="speak icons">
                  Speak icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Speaking_goals/Speaking3.png"
                  loading="lazy"
                  className="img-credits"
                  alt="speaking"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/speak"
                  title="speak icons">
                  Speak icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Speaking_goals/Speaking4.png"
                  loading="lazy"
                  className="img-credits"
                  alt="speaking"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/speaking"
                  title="speaking icons">
                  Speaking icons created by Freepik - Flaticon
                </a>
              </div>
            </div>
            <div className="row mt-0 mt-lg-4">
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Writing_goals/Writing1.png"
                  loading="lazy"
                  className="img-credits"
                  alt="writing"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/write"
                  title="write icons">
                  Write icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Writing_goals/Writing2.png"
                  loading="lazy"
                  className="img-credits"
                  alt="writing"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/write"
                  title="write icons">
                  Write icons created by Freepik - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Writing_goals/Writing3.png"
                  loading="lazy"
                  className="img-credits"
                  alt="speaking"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/writing"
                  title="writing icons">
                  Writing icons created by Smashicons - Flaticon
                </a>
              </div>
              <div className="col-2 col-lg-1 mt-4 mt-lg-0 d-flex align-items-center justify-content-center">
                <img
                  src="https://language-profile.azurewebsites.net/static/images/goal_icons/Writing_goals/Writing4.png"
                  loading="lazy"
                  className="img-credits"
                  alt="speaking"
                />
              </div>
              <div className="col-4 col-lg-2 mt-4 mt-lg-0 d-flex align-items-center text-small">
                <a
                  href="https://www.flaticon.com/free-icons/writing"
                  title="writing icons">
                  Writing icons created by Eucalyp - Flaticon
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
