import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts } from "../../reducers/postsSlice";
import PostCard from "./PostCard";
import { SearchContext } from "../../contexts/SearchContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { selectUser } from "../../reducers/sessionSlice";
import { useTranslation } from "react-i18next";

export default function Posts(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  let posts = props.posts;
  const postStatus = props.postStatus;
  const { queriedStudents } = useContext(SearchContext);

  const hasPost = (authorId) => {
    for (let element of posts) {
      try {
        if (Object.values(element.post).indexOf(authorId) > -1) {
          return true;
        }
      } catch {
        return false;
      }
    }
  };

  const studentHasPost = hasPost(props.activeStudent);
  const allUnread = posts.every((item) => {
    try {
      return item.post.unread;
    } catch {
      return false;
    }
  });

  useEffect(() => {
    // CHANGE SO NEW STUDENT CAN HAVE A QUERY
    if (props.activeStudent) {
      if (allUnread || !studentHasPost) {
        dispatch(
          fetchPosts({
            role: user.role,
            user_id: user.id,
            student_id: props.activeStudent,
            school_id: user.school_id,
            permissions: null,
            offset: 0,
          })
        ).unwrap();
      }
    }
  }, [props.activeStudent, dispatch, user, studentHasPost, allUnread]);

  let content;

  if (postStatus === "succeeded" || posts.length !== 0) {
    posts.filter((item) => item.post.status === "In Progress");
    if (queriedStudents.length !== 0) {
      posts = posts.filter((item) =>
        queriedStudents.includes(item.post.author_id)
      );
    }
    if (props.filterposts.strand !== "All") {
      posts = posts.filter(
        (item) => item.post?.strand === props.filterposts.strand
      );
    }
    if (props.filterposts.goalId) {
      posts = posts.filter(
        (item) => item.post.goal_id === props.filterposts.goalId
      );
    }
    if (!props.activeStudent && user.role === "Teacher") {
      posts = posts.filter((item) => item.post.unread);
    }
    if (props.activeStudent && user.role === "Teacher") {
      posts = posts.filter(
        (item) => item.post.author_id === props.activeStudent
      );
    }
    // Give a "No posts found option"
    content = posts.map((item, i) => (
      <PostCard
        key={i}
        id={item.post?.id}
        author_id={item.post?.author_id}
        profile_pic_src={item.post?.profile_pic_src}
        datetime={item.post?.timestamp}
        first_name={item.post?.first_name}
        last_name={item.post?.last_name}
        statement={item.post?.statement}
        statement_fr={item.post?.statement_fr}
        strand={item.post?.strand}
        strand_fr={item.post?.strand_fr}
        reflection={item.post?.content}
        selfeval={item.post?.updated_grade}
        replies={item?.replies}
        liked={item?.post_likes.liked}
        imageboolean={item.post?.image_boolean}
        audioboolean={item.post?.audio_boolean}
        status={item.post?.status}
        level={item.post?.level}
      />
    ));
    if (
      posts.length === 0 &&
      !props.activeStudent &&
      !user.role === "Student"
    ) {
      content = (
        <div className="alert alert-success" role="alert">
          {t("posts.noUnreviewedPosts")}
        </div>
      );
    }
    if (posts.length === 0 && props.activeStudent) {
      content = (
        <div className="alert alert-warning" role="alert">
          {t("posts.noActivePosts")}
        </div>
      );
    }
  } else if (postStatus === "failed") {
    content = <div> {t("posts.Error")}</div>;
  }
  const skeletonContent = (
    <Skeleton className="mb-2" height={"20rem"} baseColor="white" />
  );

  return (
    <>
      {postStatus !== "succeeded" && posts.length === 0 && skeletonContent}
      {posts.length !== 0 && content}
      {postStatus !== "succeeded" && posts.length > 0 && skeletonContent}

      {postStatus === "succeeded" && posts.length === 0 && (
        <PostCard
          id={0}
          profile_pic_src={"/static/images/avatars/animals/hare.jpg"}
          datetime={Date.now()}
          first_name={"Mallory"}
          last_name={"Cunningham"}
          statement={"Welcome to Pongo!"}
          statement_fr={"Bienvenue à Pongo"}
          reflection={t("posts.Welcome Post")}
          selfeval={1}
          replies={null}
          liked={null}
          imageboolean={null}
          audioboolean={null}
          introCard={true}
          status={"Inactive"}
        />
      )}
    </>
  );
}
