import { MDBTooltip } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { selectUser } from "../reducers/sessionSlice";
import baseUrl from "./baseUrl";
import { Hourglass, Translate } from "react-bootstrap-icons";
import { languageListEn } from "../components/profile/LanguageDropdown/languageList";

export default function TranslationTool({
  textToTranslate,
  setTranslationOutput,
  translationOutput,
}) {
  const [isTranslating, setIsTranslating] = useState(false);
  const user = useSelector(selectUser);

  const translateText = () => {
    setIsTranslating(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        language: languageListEn[user.language0],
        input: textToTranslate,
      }),
    };

    fetch(`${baseUrl}/translate`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.parse(data.content));
        let obj = JSON.parse(data.content);
        setTranslationOutput(obj.translation);
        setIsTranslating(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setIsTranslating(false);
      });
  };
  const defaultIcon = (
    <MDBTooltip tag="div" placement="bottom" title="Translate">
      <div
        className="ms-2 ear-icon"
        onClick={
          translationOutput ? () => setTranslationOutput("") : translateText
        }>
        <Translate />
      </div>
    </MDBTooltip>
  );

  const loadingIcon = (
    <Hourglass className="blink-effect text-secondary ms-2" />
  );
  return <div>{isTranslating ? loadingIcon : defaultIcon}</div>;
}
