import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import StudentWall from "../../components/StudentWall/StudentWall";
import TeacherWall from "../../components/TeacherWall/TeacherWall";
import "react-loading-skeleton/dist/skeleton.css";
import { Navigate } from "react-router";
import DeveloperWall from "../../components/DeveloperWall/DeveloperWall";

export default function Wall(props) {
  const user = useSelector(selectUser);

  return (
    <>
      {user?.role === "Teacher" && <TeacherWall user={user} />}
      {user?.role === "Student" && <StudentWall user={user} />}
      {user?.role === "Administrator" && <Navigate to={"/admin_wall"} />}
      {user?.role === "Developer" && <DeveloperWall />}
    </>
  );
}
