import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
// import ProfilePicture from "../../profilePicture/ProfilePicture";
import "./MakePostStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faPencil } from "@fortawesome/free-solid-svg-icons";
import MakePostModal from "./modal/MakePostModal";
import GoalsCarousel from "../GoalsCarousel/GoalsCarousel";
import SortPosts from "./SortPosts";
import AddGoalForm from "../AddGoal/AddGoalForm";
import { useSelector } from "react-redux";
import { selectGoalsByStatus } from "../../reducers/goalsSlice";
import { useTranslation } from "react-i18next";

export default function MakePost(props) {
  const [makePostModalShow, setMakePostModalShow] = React.useState(false);
  const [addGoalFormShow, setAddGoalFormShow] = React.useState(false);
  const [goalIndex, setGoalIndex] = useState(null);
  const { t } = useTranslation();

  const goals = useSelector((state) =>
    selectGoalsByStatus(state, "In Progress")
  );

  const [goalSelected, setGoalSelected] = useState(null);
  const [showGoalCardModal, setShowGoalCardModal] = useState(false);
  useEffect(() => {
    let index = goals.findIndex((item) => item.id === goalSelected);
    if (index < 0) {
      index = 0;
    }
    setGoalIndex(index);
  }, [goals, goalSelected]);

  const onGoalClicked = (value) => {
    try {
      if (goalSelected === value) {
        setGoalSelected(null);
        props.setfilterposts({ strand: "All" });
      } else {
        setGoalSelected(value);
        setShowGoalCardModal(true);
      }
    } catch {
      return null;
    }
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: goalIndex || 0, //Index of selected goal or 0

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1.6,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      <Card style={{ backgroundColor: "white" }} className="text-start mb-3 ">
        <Card.Body>
          <h5 className="text-center">{t("makePost.My Goals")}</h5>
          <hr className={"p-0"} />

          <div className="d-flex justify-content-center">
            <GoalsCarousel
              user={props.user}
              goalselected={goalSelected}
              setgoalselected={setGoalSelected}
              showModal={showGoalCardModal}
              setShowModal={setShowGoalCardModal}
              onClick={onGoalClicked}
              goals={props.goals}
              setmakepostmodalshow={setMakePostModalShow}
              setfilterposts={props.setfilterposts}
            />
          </div>
          <hr className={"p-0"} />

          <section className={"mt-2"}>
            <div className="row text-muted text-center d-flex justify-content-center align-items-center">
              <div
                className="col-4 d-flex align-items-center justify-content-center hover-effect icon-container"
                onClick={() => setMakePostModalShow(true)}>
                <FontAwesomeIcon size="lg" icon={faPencil} color="green" />
                <p className="py-2 px-2 my-auto fw-bold text-secondary d-none d-lg-block">
                  {t("makePost.Post")}
                </p>
              </div>

              <div
                id="addGoalButton"
                className="col-4 d-flex align-items-center justify-content-center hover-effect icon-container"
                onClick={() => setAddGoalFormShow(true)}>
                <FontAwesomeIcon size="lg" icon={faTrophy} color="goldenrod" />
                <p className="py-2 px-2 my-auto fw-bold text-secondary d-none d-lg-block">
                  {t("makePost.Add Goal")}
                </p>
              </div>
              <div className="dropdown col-4 hover-effect icon-container">
                <SortPosts
                  filterposts={props.filterposts}
                  setfilterposts={props.setfilterposts}
                />
              </div>
            </div>
          </section>
        </Card.Body>
      </Card>
      <MakePostModal
        id="post"
        show={makePostModalShow}
        setshow={setMakePostModalShow}
        onHide={() => {
          setMakePostModalShow(false);
          setGoalSelected(null);
        }}
        settings={settings}
        preselectedgoal={goalSelected}
      />
      {addGoalFormShow && (
        <AddGoalForm
          show={addGoalFormShow}
          onHide={() => setAddGoalFormShow(false)}
          showhandler={setAddGoalFormShow}
        />
      )}
    </>
  );
}
