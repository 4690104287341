import React from "react";

export default function TextInput(props) {
  const inputType = {
    teacher_email: {
      type: "email",
      placeholder: "Enter teacher email",
      aria: "TeacherEmail",
    },
    first_name: {
      type: "text",
      placeholder: "First name",
    },
    last_name: {
      type: "text",
      placeholder: "Last name",
    },
    school_name: {
      type: "text",
      placeholder: "Enter school name",
    },
  };
  const content =
    props.edit === true || props.edit === props.id ? (
      <div className="me-2 w-100">
        <label
          id={props.name}
          className="d-none"
          htmlFor={props.name}
          aria-hidden="true">
          {inputType[props.name].placeholder}
        </label>
        <input
          className="form-control"
          type={inputType[props.name].type}
          name={props.name}
          aria-describedby={props.name}
          placeholder={inputType[props.name].placeholder}
          onChange={props.onChange}
          value={props.value || ""}
        />
      </div>
    ) : (
      <>
        <span className="m-0">{props.savedValue}</span>
      </>
    );
  return content;
}
