import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextToSpeechPlayer from "../../utils/TextToSpeechPlayer";
import TranslationTool from "../../utils/TranslationTool";

export default function Statement(props) {
  const { i18n, t } = useTranslation();
  const [translationOutput, setTranslationOutput] = useState("");
  const strand = i18n.language === "en" ? props.strand : props.strand_fr;
  const statement =
    i18n.language === "en" ? props.statement : props.statement_fr;
  return (
    <div className="border-start border-3 mt-3">
      <p className="mb-1 text-muted fw-bold">{t("posts.I am working on")}...</p>
      <div className="card">
        <div className="d-flex flex-row align-items-center p-1 py-2 bg-olive">
          <div className="mx-1 text-small d-flex">
            <div className="me-1 my-auto">
              <span
                className="fw-bold bg-white rounded p-2 me-2 text-muted"
                style={{ whiteSpace: "nowrap" }}>
                {props.level} {strand}
              </span>
            </div>
            <div>
              <span className="text-muted">
                {translationOutput ? translationOutput : statement}
              </span>
            </div>
            <div className="d-flex flex-row justify-content-end align-items-center">
              <TranslationTool
                textToTranslate={statement}
                setTranslationOutput={setTranslationOutput}
                translationOutput={translationOutput}
              />
              <TextToSpeechPlayer
                inputText={translationOutput ? translationOutput : statement}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
