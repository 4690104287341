export const languageListEn = {
  aa: "Afar",
  ab: "Abkhazian",
  ae: "Avestan",
  af: "Afrikaans",
  ak: "Akan",
  am: "Amharic",
  an: "Aragonese",
  ar: "Arabic",
  as: "Assamese",
  av: "Avaric",
  ay: "Aymara",
  az: "Azerbaijani",
  ba: "Bashkir",
  be: "Belarusian",
  bg: "Bulgarian",
  bh: "Bihari languages",
  bi: "Bislama",
  bm: "Bambara",
  bn: "Bengali",
  bo: "Tibetan",
  br: "Breton",
  bs: "Bosnian",
  ca: "Catalan",
  ce: "Chechen",
  ch: "Chamorro",
  co: "Corsican",
  cr: "Cree",
  cs: "Czech",
  cu: "Church Slavic",
  cv: "Chuvash",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  di: "Dinka",
  dv: "Maldivian",
  dz: "Dzongkha",
  ee: "Ewe",
  el: "Greek",
  en: "English",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  ff: "Fulah",
  fi: "Finnish",
  fj: "Fijian",
  fo: "Faroese",
  fr: "French",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Gaelic",
  gl: "Galician",
  gn: "Guarani",
  gu: "Gujarati",
  gv: "Manx",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  ho: "Hiri Motu",
  hr: "Croatian",
  ht: "Haitian",
  hu: "Hungarian",
  hy: "Armenian",
  hz: "Herero",
  ia: "Interlingua",
  id: "Indonesian",
  ie: "Interlingue",
  ig: "Igbo",
  ii: "Sichuan Yi",
  ik: "Inupiaq",
  io: "Ido",
  is: "Icelandic",
  it: "Italian",
  iu: "Inuktitut",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kg: "Kongo",
  ki: "Kikuyu",
  kj: "Kuanyama",
  kk: "Kazakh",
  kl: "Kalaallisut",
  km: "Central Khmer",
  kn: "Kannada",
  ko: "Korean",
  kr: "Kanuri",
  ks: "Kashmiri",
  ku: "Kurdish",
  kv: "Komi",
  kw: "Cornish",
  ky: "Kirghiz",
  la: "Latin",
  lb: "Luxembourgish",
  lg: "Ganda",
  li: "Limburgan",
  ln: "Lingala",
  lo: "Lao",
  lt: "Lithuanian",
  lu: "Luba-Katanga",
  lv: "Latvian",
  mg: "Malagasy",
  mh: "Marshallese",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  na: "Nauru",
  nb: "Norwegian",
  nd: "North Ndebele",
  ne: "Nepali",
  ng: "Ndonga",
  nl: "Dutch",
  nn: "Norwegian",
  no: "Norwegian",
  nr: "South Ndebele",
  nv: "Navajo",
  ny: "Chichewa",
  oc: "Occitan",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "Ossetic",
  pa: "Panjabi",
  pi: "Pali",
  pl: "Polish",
  ps: "Pushto",
  pt: "Portuguese",
  qu: "Quechua",
  rm: "Romansh",
  rn: "Rundi",
  ro: "Romanian",
  ru: "Russian",
  rw: "Kinyarwanda",
  sa: "Sanskrit",
  sc: "Sardinian",
  sd: "Sindhi",
  se: "Northern Sami",
  sg: "Sango",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  ss: "Swati",
  st: "Sotho, Southern",
  su: "Sundanese",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  ti: "Tigrinya",
  tk: "Turkmen",
  tl: "Tagalog",
  tn: "Tswana",
  to: "Tonga",
  tr: "Turkish",
  ts: "Tsonga",
  tt: "Tatar",
  tw: "Twi",
  ty: "Tahitian",
  ug: "Uighur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapük",
  wa: "Walloon",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  za: "Zhuang",
  zh: "Chinese",
  zu: "Zulu",
};

export const languageListFr = {
  aa: "Afar",
  ab: "abkhaze",
  ae: "Avestan",
  af: "afrikaans",
  ak: "akan",
  am: "amharique",
  an: "aragonais",
  ar: "arabe",
  as: "assamais",
  av: "Avaric",
  ay: "aymara",
  az: "azerbaïdjanais",
  ba: "bachkir",
  be: "biélorusse",
  bg: "bulgare",
  bh: "langues bihari",
  bi: "Bislama",
  bm: "bambara",
  bn: "bengali",
  bo: "tibétain",
  br: "breton",
  bs: "bosniaque",
  ca: "catalan",
  ce: "tchétchène",
  ch: "chamorro",
  co: "corse",
  cr: "cri",
  cs: "tchèque",
  cu: "slave de l'Église",
  cv: "Tchouvache",
  cy: "gallois",
  da: "danois",
  di: "Dinka",
  de: "allemand",
  dv: "maldivien",
  dz: "Dzongkha",
  ee: "Ewe",
  el: "grec",
  en: "anglais",
  eo: "espéranto",
  es: "espagnol",
  et: "estonien",
  eu: "basque",
  fa: "persan",
  ff: "Fulah",
  fi: "finnois",
  fj: "fidjien",
  fo: "féroïen",
  fr: "français",
  fy: "frison occidental",
  ga: "irlandais",
  gd: "gaélique",
  gl: "galicien",
  gn: "guarani",
  gu: "gujarati",
  gv: "Manx",
  ha: "Hausa",
  he: "hébreu",
  hi: "hindi",
  ho: "Hiri Motu",
  hr: "croate",
  ht: "haïtien",
  hu: "hongrois",
  hy: "arménien",
  hz: "Herero",
  ia: "Interlingua",
  id: "indonésien",
  ie: "Interlingue",
  ig: "Igbo",
  ii: "Sichuan Yi",
  ik: "Inupiaq",
  io: "Ido",
  is: "islandais",
  it: "italien",
  iu: "Inuktitut",
  ja: "japonais",
  jv: "javanais",
  ka: "géorgien",
  kg: "Kongo",
  ki: "Kikuyu",
  kj: "Kuanyama",
  kk: "Kazakh",
  kl: "Kalaallisut",
  km: "khmer central",
  kn: "Kannada",
  ko: "coréen",
  kr: "Kanuri",
  ks: "Kashmiri",
  ku: "kurde",
  kv: "komi",
  kw: "cornique",
  ky: "kirghize",
  la: "latin",
  lb: "luxembourgeois",
  lg: "ganda",
  li: "limbourgeois",
  ln: "Lingala",
  lo: "lao",
  lt: "lituanien",
  lu: "Luba-Katanga",
  lv: "letton",
  mg: "malgache",
  mh: "marshallois",
  mi: "Maori",
  mk: "macédonien",
  ml: "malayalam",
  mn: "mongol",
  mr: "Marathi",
  ms: "malais",
  mt: "maltais",
  my: "birman",
  na: "Nauru",
  nb: "norvégien",
  nd: "Ndebele du Nord",
  ne: "Népalais",
  ng: "Ndonga",
  nl: "néerlandais",
  nn: "norvégien",
  no: "norvégien",
  nr: "Ndebele du Sud",
  nv: "Navajo",
  ny: "Chichewa",
  oc: "occitan",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "ossète",
  pa: "Panjabi",
  pi: "Pali",
  pl: "polonais",
  ps: "Pushto",
  pt: "portugais",
  qu: "Quechua",
  rm: "romanche",
  rn: "Rundi",
  ro: "roumain",
  ru: "russe",
  rw: "Kinyarwanda",
  sa: "sanskrit",
  sc: "sarde",
  sd: "sindhi",
  se: "Sami du Nord",
  sg: "Sango",
  si: "Sinhala",
  sk: "slovaque",
  sl: "slovène",
  sm: "samoan",
  sn: "shona",
  so: "Somali",
  sq: "albanais",
  sr: "serbe",
  ss: "Swati",
  st: "Sotho, Sud",
  su: "Sundanais",
  sv: "suédois",
  sw: "swahili",
  ta: "tamoul",
  te: "Telugu",
  tg: "tadjik",
  th: "thaïlandais",
  ti: "tigrinya",
  tk: "Turkmène",
  tl: "Tagalog",
  tn: "Tswana",
  to: "Tonga",
  tr: "turc",
  ts: "Tsonga",
  tt: "Tatar",
  tw: "Twi",
  ty: "tahitien",
  ug: "Ouïghour",
  uk: "ukrainien",
  ur: "ourdou",
  uz: "Ouzbek",
  ve: "Venda",
  vi: "vietnamien",
  vo: "Volapük",
  wa: "wallon",
  wo: "wolof",
  xh: "Xhosa",
  yi: "yiddish",
  yo: "Yoruba",
  za: "Zhuang",
  zh: "chinois",
  zu: "Zoulou",
};
