import { createContext, useState } from "react";

export const AuthContext = createContext({
  token: "",
  isAuthenticated: false,
  authenticate: () => {},
  logout: () => {},
});

function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const microsoftLogoutUser = async (instance) => {
    try {
      // Sign out the user silently
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      // Optionally, perform any cleanup tasks
    } catch (error) {
      console.error("Logout failed:", error);
      // Handle error, if any
    }
  };

  function authenticate(token) {
    setAuthToken(token);
    token ? setIsAuthenticated(true) : setIsAuthenticated(false);
  }

  function logout(instance) {
    microsoftLogoutUser(instance);
    setAuthToken("");
    setIsAuthenticated(false);
    console.log("Log Out");
  }

  const value = {
    token: authToken,
    isAuthenticated: isAuthenticated,
    authenticate: authenticate,
    logout: logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
