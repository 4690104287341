import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearSession, setSession } from "../../reducers/sessionSlice";
import TextFilter from "../AdminWall/TextFilter.jsx";
import { useNavigate } from "react-router";
import "react-loading-skeleton/dist/skeleton.css";
import baseUrl from "../../utils/baseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function DeveloperWall() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [isHovered, setIsHovered] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const adminChameleonHandler = (email) => {
    dispatch(clearSession());
    navigate("/");
    dispatch(setSession(email));
  };

  useEffect(() => {
    setList([...adminList]);
  }, [adminList, setList]);

  // Function to fetch admins from the API
  const fetchAdmins = async () => {
    try {
      const response = await fetch(`${baseUrl}/get_admins`);
      if (!response.ok) {
        throw new Error("Failed to fetch admins");
      }
      const data = await response.json();
      setAdminList(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching admins:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);
  const skeletonContent = Array.from({ length: 15 }, (_, index) => (
    <div
      key={index}
      className="d-flex flex-row border-bottom text-uppercase text-secondary fw-bold py-1 m-1 my-auto text-xs">
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
    </div>
  ));
  const data = list
    .sort(
      (a, b) =>
        a.last_name?.localeCompare(b?.last_name) ||
        a.school_name?.localeCompare(b?.school_name)
    )

    .map((admin, i) => {
      return (
        <div
          key={i}
          onMouseEnter={() => setIsHovered(i)}
          onMouseLeave={() => setIsHovered("")}>
          <div>
            <div
              className={`d-flex flex-row justify-content-center align-items-center border-bottom py-1 m-1 my-auto text-xs cursor-pointer ${
                isHovered === i ? "bg-light" : ""
              }`}
              onClick={() => adminChameleonHandler(admin.admin_email)}>
              <div className="col-2">{admin.last_name}</div>

              <div className="col-2 p-1">{admin.first_name}</div>
              <div className="col-2 p-1">{admin.admin_email}</div>
              <div className="col-2 p-1">{admin.district_name}</div>
            </div>
          </div>
        </div>
      );
    });
  const loadedData = (
    <div className="card p-3 mx-4">
      <TextFilter
        list={adminList}
        setList={setList}
        filter={filter}
        setFilter={setFilter}
      />
      <div className="d-flex flex-row justify-content-center border-bottom text-uppercase text-secondary fw-bold py-1 m-1 my-auto text-xs">
        <div className="col-2 p-1">Last Name</div>
        <div className="col-2 p-1">First Name</div>
        <div className="col-2 p-1">Email</div>
        <div className="col-2 p-1">District</div>
      </div>
      {data}
    </div>
  );

  return (
    <>
      <h5 className="text-secondary text-uppercase text-center p-3">
        Developer Interface
      </h5>
      {isLoading ? (
        <div className="vh-min-60 d-flex justify-content-center align-items-center">
          {skeletonContent}
        </div>
      ) : (
        loadedData
      )}
    </>
  );
}
