import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTeachersList } from "../../../reducers/teachersListSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { selectStudentPermissions } from "../../../reducers/studentPermissionsSlice";
import {
  addPermission,
  deletePermission,
} from "../../../reducers/studentPermissionsSlice";
import { useTranslation } from "react-i18next";

export default function Permissions(props) {
  const { t } = useTranslation();
  const teachersList = useSelector(selectTeachersList);
  const dispatch = useDispatch();
  const teachersListStatus = useSelector((state) => state.teachersList.status);
  let studentPermissions = useSelector(selectStudentPermissions);
  studentPermissions = studentPermissions.filter(
    (item) => item.student_id === props.studentId
  );
  const addPermissionHandler = (adult_id, student_id) => {
    dispatch(
      addPermission({
        adult_id: adult_id,
        student_id: student_id,
      })
    ).unwrap();
  };

  const deletePermissionHandler = (adult_id, student_id) => {
    dispatch(
      deletePermission({
        adult_id: adult_id,
        student_id: student_id,
      })
    ).unwrap();
  };

  // Going to need to also get permissions for this student if not retrieved
  // Compare these permissions with the teachersList
  // Change buttons from "share"/"unshare", depending on permissions that exist in the teacherslist

  return (
    <>
      <div className="mb-3 card container p-2">
        <div className="p-2">
          <h5 className="fw-bold m-0">{t("profile.Teachers")}</h5>
          <p className="m-0 py-2 text-muted">
            {t("profile.Share student publications")}
          </p>
        </div>
        <div className="px-2">
          <div className="d-flex flex-row align-items-center p-2 border-bottom border-top p-2 fw-bold text-small">
            <div className="col-1">#</div>
            <div className="col-4">{t("profile.Name")}</div>
            <div className="col-5">{t("profile.Email")}</div>
            <div className="col-2"></div>
          </div>
          {teachersListStatus !== "succeeded" && !studentPermissions && (
            <Skeleton height={"10rem"} baseColor="white" />
          )}
          {teachersList.map((item, i) => (
            <div
              key={i}
              className="d-flex flex-row align-items-center p-2 text-xs text-muted">
              <div className="col-1">{i + 1}</div>
              <div className="col-4">
                {item?.last_name}, {item?.first_name}
              </div>
              <div className="col-5">{item?.email}</div>
              {studentPermissions && (
                <div className="col-2 d-flex justify-content-end">
                  {!studentPermissions.some((e) => e.adult_id === item.id) ? (
                    <button
                      onClick={() =>
                        addPermissionHandler(item.id, props.studentId)
                      }
                      type="button"
                      className="btn btn-success btn-sm">
                      {t("profile.Share")}
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        deletePermissionHandler(item.id, props.studentId)
                      }
                      type="button"
                      className="btn btn-danger btn-sm">
                      {t("profile.Unshare")}
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
