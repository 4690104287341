import React from "react";
import "./ButtonStyles.css";
import { useSelector } from "react-redux";
import { selectAllNotifications } from "../../../reducers/notificationsSlice";

export default function NotificationCount() {
  const notifications = useSelector(selectAllNotifications);
  const numberOfNotifications = notifications?.filter(
    (notification) => !notification.read
  ).length;
  const content = numberOfNotifications !== 0 && numberOfNotifications && (
    <>
      <span
        id="badge_notification"
        className="button__badge text-center bg-danger">
        {numberOfNotifications}
      </span>
    </>
  );

  return content;
}
