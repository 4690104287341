import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../../App.css";
import "../Navbar/buttons/ButtonStyles.css";
import CustomToggle from "../Dropdown/CustomToggle";
import CustomMenu from "../Dropdown/CustomMenu";
import { useTranslation } from "react-i18next";
import { Check, ThreeDotsVertical, XSquare } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import {
  deleteNotification,
  markNotificationRead,
} from "../../reducers/notificationsSlice";

export default function NotificationsDropdown(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteNotificationHandler = () => {
    dispatch(deleteNotification(props.notification_id));
  };

  return (
    <Dropdown className="change-goal-status-toggle cursor-pointer icon-flex">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <div className="icon-wrapper gear">
          <ThreeDotsVertical className="text-secondary fa-xl" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} labeledBy="Notifications Settings">
        {!props.read && (
          <Dropdown.Item>
            <div
              onClick={() =>
                !props.read &&
                dispatch(markNotificationRead(props.notification_id))
              }
              className="d-flex align-items-center py-1">
              <Check className="fa-lg fa-fw" />
              <span className="ms-2">{t("dropdowns.Mark as Read")}</span>
            </div>
          </Dropdown.Item>
        )}
        <Dropdown.Item>
          <div
            className="d-flex align-items-center py-2"
            onClick={deleteNotificationHandler}>
            <XSquare className="fa-lg fa-fw" />
            <span className="ms-2 ">{t("dropdowns.Delete")}</span>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
