export const getMonthName = (month, language) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthsFr = [
    "jan",
    "fév",
    "mar",
    "avr",
    "mai",
    "jui",
    "jul",
    "aoû",
    "sep",
    "oct",
    "nov",
    "déc",
  ];
  if (language === "en") {
    return months[month] || "";
  } else {
    return monthsFr[month] || "";
  }
};
