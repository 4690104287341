import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";

export const fetchProgress = createAsyncThunk(
  "progress/fetchProgress",
  async (obj) => {
    const response = await fetch(`${baseUrl}/get_progress/${obj.userId}`);
    const progress = await response.json();
    return progress;
  }
);

const initialState = {
  progress: [],
  status: "idle",
  error: null,
};

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    clearProgress: () => {
      return { ...initialState };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchProgress.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchProgress.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched posts to the array
        state.progress = state.progress.concat(action.payload);
      })
      .addCase(fetchProgress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default progressSlice.reducer;

export const { clearProgress } = progressSlice.actions;

export const selectAllProgress = (state) => state.progress.progress;

// export const selectAllProgress = (state, studentId) => {
//   let progress = state.progress.progress;
//   return progress;
// };
