import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import baseUrl from "../../utils/baseUrl";
import { useTranslation } from "react-i18next";
import {
  Clipboard,
  HourglassSplit,
  Printer,
  Robot,
} from "react-bootstrap-icons";
import { appLevelsReverse } from "../../utils/levels";
import { useReactToPrint } from "react-to-print";
import { Button } from "react-bootstrap";
import LessonPlanTemplate from "./LessonPlanTemplate";

export default function ActivityFinderModal(props) {
  const [activity, setActivity] = useState("");
  const [criteria, setCriteria] = useState("");
  const { t, i18n } = useTranslation();
  const [searchActive, setSearchActive] = useState(false);
  const statement =
    i18n.language === "fr"
      ? props.objective?.statement_fr
      : props.objective?.statement;

  const strand =
    i18n.language === "fr"
      ? props.objective?.strand_fr
      : props.objective?.strand;

  const activityFinderHandler = async () => {
    setSearchActive(true);
    try {
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          language: i18n.language,
          statement: statement,
          criteria: criteria,
        }),
      };
      const data = await fetch(`${baseUrl}/activity_finder`, settings);
      if (!data.ok) {
        throw new Error(`HTTP error! Status: ${data.status}`);
      }
      const response = await data.json();
      if (response.error) {
        throw new Error(`Server error: ${response.error}`);
      }
      const jsonString = response.content.replace(/```json|```/g, "");
      const parsedContent = JSON.parse(jsonString);
      setActivity(parsedContent);
      setSearchActive(false);
    } catch (error) {
      console.error(error);
      setSearchActive(false);
    }
  };

  const onHide = () => {
    props.handleClose();
    setActivity("");
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getPageMargins = () => {
    return `@page { margin: 2 2 2 2 !important; }`;
  };

  const copyDivToClipboard = () => {
    var range = document.createRange();
    range.selectNode(document.getElementById("divToCopy"));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
  };
  console.log(activity);
  return (
    <Modal
      show={props.show}
      onHide={onHide}
      size="lg"
      centered
      backdrop="static">
      <Modal.Header closeButton={!searchActive}>
        <Modal.Title>
          <div className="d-flex flex-row">
            <div className="d-flex align-items-center">
              <Robot className="me-2 my-auto" color="Navy" />
              {t("lessonGenerator.AI Lesson Generator")}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-main">
        <>
          <div className="p-2 mx-2 ">
            <div className="d-flex justify-content-end py-2">
              <Button
                onClick={copyDivToClipboard}
                variant="light"
                className="me-2">
                <Clipboard className="me-1" /> {t("lessonGenerator.Copy")}
              </Button>
              <Button onClick={handlePrint} variant="light">
                <Printer className="me-1" /> {t("lessonGenerator.Print")}
              </Button>
            </div>
            <div id="divToCopy" className="pt-2" ref={componentRef}>
              <LessonPlanTemplate
                setCriteria={setCriteria}
                criteria={criteria}
                searchActive={searchActive}
                statement={statement}
                level={`${appLevelsReverse[
                  props.objective?.level
                ]?.toUpperCase()} ${strand}`}
                activity={activity}
              />
              <style>{getPageMargins()}</style>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column m-0 p-0">
        {searchActive && (
          <div className="text-center blink-effect py-3">
            <HourglassSplit className="p-2 fa-4x" />
            <p className="m-0">
              {t("lessonGenerator.This will take a minute")}.
            </p>
            <p className="m-0">{t("lessonGenerator.Do not close")}.</p>
          </div>
        )}
        {!searchActive && (
          <div className="pt-4">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={activityFinderHandler}>
                {t("lessonGenerator.Search")}
              </button>
            </div>
            <p className="text-center pt-1 text-xs">
              {t("lessonGenerator.This will take a minute")}
            </p>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
