import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { selectGoalsByStatus } from "../../reducers/goalsSlice";
import { useTranslation } from "react-i18next";

export default function StatementSelect(props) {
  const { i18n, t } = useTranslation();
  const [statementListEn, setStatementListEn] = useState([]);
  const [statementListFr, setStatementListFr] = useState([]);
  const [goalIds, setGoalIds] = useState("");

  const goals = useSelector((state) =>
    selectGoalsByStatus(state, "In Progress")
  );

  useMemo(() => {
    let goalIdsArray = [];
    for (let goal of goals) {
      goalIdsArray.push(goal.cefr_custom_id);
    }
    setGoalIds(goalIdsArray);
  }, [goals]);

  useEffect(() => {
    let optionsEn = [];
    let optionsFr = [];
    for (let item of props.progress?.slice(1)) {
      if (props.strand === item.strand) {
        for (let goal of item.remaining_goals) {
          if (!goalIds.includes(goal.id)) {
            optionsEn.push({
              value: goal.id,
              label: goal.statement,
            });
            optionsFr.push({
              value: goal.id,
              label: goal.statement_fr,
            });
          }
        }
      }
    }
    setStatementListEn(optionsEn);
    setStatementListFr(optionsFr);
  }, [props.strand, goalIds, props.progress]);

  return (
    <>
      <Select
        className="search-select"
        instanceId="goalStrand"
        placeholder={t("addGoal.Select...")}
        isClearable
        value={props.statement}
        options={i18n.language === "en" ? statementListEn : statementListFr}
        onChange={props.onChange}
      />
    </>
  );
}
