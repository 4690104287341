import React, { useEffect, useState, useRef } from "react";
import baseUrl from "./baseUrl";
import { MDBTooltip } from "mdb-react-ui-kit";
import { Ear, Hourglass, PlayFill, StopFill } from "react-bootstrap-icons";

const TextToSpeechPlayer = ({ inputText }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (inputText) {
      setAudioUrl(null);
    }
  }, [inputText]);

  const defaultIcon = (
    <>
      {isLoading ? (
        <Hourglass className="blink-effect ms-2" />
      ) : (
        <MDBTooltip tag="div" placement="bottom" title="Listen">
          <div
            className={`ms-2 ear-icon`}
            onClick={() => !isLoading && handleTextToSpeech()}>
            <Ear />
          </div>
        </MDBTooltip>
      )}
    </>
  );

  const handleTextToSpeech = () => {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        input: inputText,
      }),
    };

    fetch(`${baseUrl}/text_to_speech`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        console.log("Audio blob retrieved successfully.");
        setAudioUrl(url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching audio:", error);
      });
  };

  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  }, [audioUrl]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("play", () => {
        setIsPlaying(true);
      });
      audioRef.current.addEventListener("pause", () => {
        setIsPlaying(false);
      });

      audioRef.current.addEventListener("ended", () => {
        setIsPlaying(false); // Update state when audio has finished playing
      });
    }
  }, []);
  const handleStopClick = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset audio playback position
      setIsPlaying(false);
    }
  };

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div>
      {!audioUrl ? (
        defaultIcon
      ) : (
        <>
          <div className="d-none">
            <audio controls ref={audioRef} src={audioUrl} />
          </div>
          {isPlaying ? (
            <StopFill
              className="ms-2 cursor-pointer text-danger blink-effect"
              onClick={handleStopClick}
            />
          ) : (
            <PlayFill
              className="ms-2 cursor-pointer text-success"
              onClick={handlePlayClick}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TextToSpeechPlayer;
