import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpWideShort } from "@fortawesome/free-solid-svg-icons";
import CustomMenu from "../Dropdown/CustomMenu";
import CustomToggle from "../Dropdown/CustomToggle";
import "../Navbar/NavBar.css";
import { useSelector } from "react-redux";
import { selectAllPosts } from "../../reducers/postsSlice";
import { selectGoalsByStatus } from "../../reducers/goalsSlice";
import { useTranslation } from "react-i18next";
import getCurrentStrands from "../../utils/getCurrentStrands";

export default function SortPosts(props) {
  const posts = useSelector(selectAllPosts);
  const { t, i18n } = useTranslation();
  const goals = useSelector((state) =>
    selectGoalsByStatus(state, "In Progress")
  );

  const currentStrands = getCurrentStrands(i18n.language, posts, goals);

  const checkHandler = (strand) => {
    props.setfilterposts({ strand: strand });
  };

  const dropdownItems = currentStrands.map((strand, i) => (
    <div key={strand} className="dropdown-item">
      <input
        id={i}
        value={strand}
        type="checkbox"
        onChange={() => checkHandler(strand)}
        checked={props.filterposts.strand === strand}
      />
      <label htmlFor={i} className="ms-2">
        {strand}
      </label>
    </div>
  ));

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <div
            className={
              "h-100 w-100 d-flex align-items-center justify-content-center"
            }>
            <FontAwesomeIcon size="lg" icon={faArrowUpWideShort} color="red" />
            <p
              className={
                "py-2 px-2 my-auto fw-bold text-secondary d-none d-lg-block"
              }>
              {t("makePost.Sort Posts")}
            </p>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} labeledBy="Account">
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
