import * as React from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import $ from "jquery";
export default function Recorder(props) {
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  const addAudioElement = (blob) => {
    props.setrecorder(true);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.id = `${props.id}AudioRecording`;
    audio.src = url;
    audio.controls = true;
    document.getElementById(props.containerId)?.appendChild(audio);
    $(`#audioControls${props.id}`).toggleClass("d-none");
    $(`#recordButton${props.id}`).toggleClass("d-none");
  };

  return (
    <>
      {recorderControls.recordingTime > 120 && recorderControls.stopRecording()}
      <AudioRecorder
        classes
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
        // downloadOnSavePress={true}
        // downloadFileExtension="mp3"
        showVisualizer={true}
      />
    </>
  );
}
