import React from "react";
import OverallProgressCard from "../../components/Progress/OverallProgressCard";
import { useSelector } from "react-redux";
import { selectAllProgress } from "../../reducers/progressSlice";
import StrandProgressCard from "../../components/Progress/StrandProgressCard";
import { selectUser } from "../../reducers/sessionSlice";
import CefrExplained from "../../components/Progress/CefrExplained";
import { useTranslation } from "react-i18next";

export default function Progress() {
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const progress = useSelector((state) => selectAllProgress(state, user.id));
  const currentProgress = progress[progress.length - 1]?.data[0];

  return (
    <div className="container">
      <div className="row d-flex justify-content-center container align-items-center">
        <div className="col-12 col-lg-5 my-auto mx-auto d-flex flex-column align-items-center ">
          <OverallProgressCard
            key="overall"
            strand={currentProgress.strand}
            strand_fr={currentProgress.strand_fr}
            percentage={currentProgress.percentage}
            overall_cefr_grade={currentProgress.overall_cefr_grade}
          />
          <div
            className="card col-12 mt-2"
            style={{
              borderRadius: "25px",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}>
            <p className="m-0 text-center text-uppercase text-secondary pt-3 pb-2 fw-bold">
              {t("cefr.CEFR Scale")}
            </p>
            <CefrExplained />
          </div>
        </div>
        <div className="col-12 col-lg-7 my-auto">
          <div className="row ">
            {progress[progress.length - 1]?.data.slice(1).map((item) => (
              <div
                key={item.strand}
                className="col-6 col-lg-4 mt-2 d-flex align-items-center justify-content-center">
                <StrandProgressCard
                  strand={item.strand}
                  strand_fr={item.strand_fr}
                  percentage={item.percentage}
                  overall_cefr_grade={item.overall_cefr_grade}
                  goals_completed={item.goals_completed}
                  total_goals={item.total_goals}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
