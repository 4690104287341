import React, { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faComment } from "@fortawesome/free-solid-svg-icons";
import "../MakePost/MakePostStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { like } from "../../reducers/postsSlice";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../reducers/sessionSlice";

export default function PostLikeComment(props) {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const PostLikeCommentIconSize = "1x";
  const [liked, setLiked] = useState(props.liked);
  const onLikeClicked = (postId) => {
    dispatch(
      like({
        post_or_reply: "post",
        post_or_reply_id: postId,
        user_id: user.id,
      })
    ).unwrap();
    setLiked(!liked);
  };

  let likeStyles;

  liked ? (likeStyles = styles.iconLiked) : (likeStyles = styles.icon);

  return (
    <>
      <hr style={styles.horizontalRule} className="pb-1" />
      <div className="d-flex flex-row pb-1" style={styles.container}>
        <div
          className="d-flex flex-row justify-content-center hover-effect p-1"
          style={styles.button}
          onClick={() => onLikeClicked(props.id)}>
          <FontAwesomeIcon
            icon={faThumbsUp}
            size={PostLikeCommentIconSize}
            style={likeStyles}
          />
          <p className="ps-2" style={{ ...styles.buttonText, ...likeStyles }}>
            {t("posts.Like")}
          </p>
        </div>

        <div
          className="d-flex flex-row justify-content-center hover-effect p-1"
          style={styles.button}>
          <FontAwesomeIcon
            icon={faComment}
            size={PostLikeCommentIconSize}
            style={styles.icon}
          />
          {/* // Going to need a container Id */}
          <div
            onClick={() => {
              document.getElementById(`commentField_${props.id}`).focus();
              document
                .getElementById(`commentField_${props.id}`)
                .scrollIntoView();
            }}>
            <p className="ps-2" style={styles.buttonText}>
              {t("posts.Comment")}
            </p>
          </div>
        </div>
      </div>
      <hr style={styles.horizontalRule} />
    </>
  );
}

const styles = {
  button: {
    flexDirection: "row",
    alignItems: "center",
  },
  buttonText: {
    color: "gray",
    fontSize: 16,
    margin: 0,
    fontWeight: "bold",
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  icon: {
    color: "gray",
  },
  iconLiked: {
    color: "#0165E1",
  },
  horizontalRule: {
    padding: "0px",
    margin: "0px",
  },
};
