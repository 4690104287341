import { configureStore } from "@reduxjs/toolkit";
import goalsReducer from "../reducers/goalsSlice";
import progressReducer from "../reducers/progressSlice";
import postsReducer from "../reducers/postsSlice";
import sessionReducer from "../reducers/sessionSlice";
import teacherPermissionsReducer from "../reducers/teacherPermissionsSlice";
import progressTeacherReducer from "../reducers/progressTeacherSlice";
import teachersListReducer from "../reducers/teachersListSlice";
import studentPermissionsReducer from "../reducers/studentPermissionsSlice";
import notificationsReducer from "../reducers/notificationsSlice";
import groupingsReducer from "../reducers/groupingsSlice";
import adminSchoolsListReducer from "../reducers/adminSchoolsListSlice";
import adminTeachersListReducer from "../reducers/adminTeachersListSlice";
import adminMetricsReducer from "../reducers/adminMetricsSlice";

import adminDistrictsListReducer from "../reducers/adminDistrictsListSlice";

const store = configureStore({
  reducer: {
    goals: goalsReducer,
    progress: progressReducer,
    posts: postsReducer,
    session: sessionReducer,
    teacherPermissions: teacherPermissionsReducer,
    progressTeacher: progressTeacherReducer,
    teachersList: teachersListReducer,
    studentPermissions: studentPermissionsReducer,
    notifications: notificationsReducer,
    groupings: groupingsReducer,
    adminSchoolsList: adminSchoolsListReducer,
    adminDistrictsList: adminDistrictsListReducer,
    adminTeachersList: adminTeachersListReducer,
    adminMetrics: adminMetricsReducer,
  },
});

export default store;
