import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import CustomMenu from "../../Dropdown/CustomMenu";
import CustomToggle from "../../Dropdown/CustomToggle";
import { MDBTooltip } from "mdb-react-ui-kit";
import "../NavBar.css";
import { Link } from "react-router-dom";
import {
  Bullseye,
  Envelope,
  GraphUpArrow,
  QuestionCircle,
} from "react-bootstrap-icons";
import {
  faFlagCheckered,
  faSchool,
  faUser,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonTemplate } from "./ButtonTemplate";

export const AdminPages = () => {
  const iconClass = "me-2 fa-fw";
  const adminButtons = [
    {
      name: "Metrics",
      label: "Metrics",
      href: "/admin_wall",
      icon: <GraphUpArrow className={iconClass} />,
    },
    {
      name: "Schools",
      label: "Schools",
      href: "/add_schools",
      icon: <FontAwesomeIcon className={iconClass} icon={faSchool} />,
    },
    {
      name: "Teachers",
      label: "Teachers",
      href: "/add_teachers",
      icon: <FontAwesomeIcon className={iconClass} icon={faUser} />,
    },
    {
      name: "Goals",
      label: "Goals",
      href: "/activity_finder",
      icon: <Bullseye className={iconClass} />,
    },
    {
      name: "Quickstart",
      label: "Quickstart",
      href: "/resources",
      icon: <FontAwesomeIcon className={iconClass} icon={faFlagCheckered} />,
    },
    {
      name: "Instructions",
      label: "Instructions",
      href: "/tutorials",
      icon: <QuestionCircle className={iconClass} />,
    },
    {
      name: "Contact",
      label: "Contact",
      href: "/support",
      icon: <Envelope className={iconClass} />,
    },
  ];
  return (
    <>
      <Dropdown
        align={{ sm: "start" | "start" }}
        drop={"up"}
        className="account-toggle">
        <MDBTooltip tag="div" placement="bottom" title="Admin Pages">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <ButtonTemplate id="Admin Options" icon={faGear} />
          </Dropdown.Toggle>
        </MDBTooltip>

        <Dropdown.Menu as={CustomMenu} labeledBy="Account">
          {adminButtons.map((item, i) => (
            <Dropdown.Item
              as={Link}
              to={item.href}
              eventKey={i}
              key={i}
              className="text-small">
              {item.icon} {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
