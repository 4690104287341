import React, { useEffect, useState } from "react";
import ProfilePicture from "../profilePicture/ProfilePicture";
import TimeAgo from "../Posts/TimeAgo";
import { deleteReply } from "../../reducers/postsSlice";
import { useDispatch, useSelector } from "react-redux";
import AudioPlayer from "../Audio/AudioPlayer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ShowImage from "../ImageUpload/ShowImage";
import "./Styles.css";
import { Trash } from "react-bootstrap-icons";
import { MDBTooltip } from "mdb-react-ui-kit";
import TextToSpeechPlayer from "../../utils/TextToSpeechPlayer";
import TranslationTool from "../../utils/TranslationTool";

export default function SeeReply(props) {
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [translationOutput, setTranslationOutput] = useState("");

  useEffect(() => {
    if (props.audioboolean) {
      setAudioSrc(
        `https://languageprofile.blob.core.windows.net/audio/${props.replyId}`
      );
    }
  }, [setAudioSrc, props.audioboolean, props.replyId]);

  const imageSrc = `https://languageprofile.blob.core.windows.net/image/${props.replyId}`;
  const dispatch = useDispatch();
  const onDelete = (replyId) => {
    dispatch(deleteReply(replyId));
  };
  const replyStatus = useSelector((state) => state.posts.status);

  return (
    <>
      {deleteMessage ? (
        <div className="mt-3 d-flex flex-column align-items-center border justify-content-center p-3">
          <p className="mb-1">Are you sure you want to delete this comment?</p>
          <div className="d-flex flex-row">
            <button
              className="btn btn-danger me-2"
              onClick={() => onDelete(props.replyId)}>
              Delete
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setDeleteMessage(false)}>
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="row pt-3">
          {replyStatus === "loading" && <h1>Loading</h1>}
          <div className="d-flex flex-row col-lg-12 align-items-center">
            <div>
              <ProfilePicture size="30px" src={props.profile_pic_src} />
            </div>

            <div
              className="my-auto ms-2 p-2"
              style={{
                backgroundColor: "#f5f5f5",
                borderRadius: 10,
                minWidth: "140px",
              }}>
              <p className="mb-0 ps-2 text-start fw-bold">
                {props.first_name} {props.last_name}
              </p>
              <p className="mb-0 ps-2 text-start">{props.content}</p>
              {translationOutput && (
                <p className="mb-0 ps-2 text-start mt-1">{translationOutput}</p>
              )}
              {props.audioboolean && audioSrc && (
                <AudioPlayer
                  src={audioSrc}
                  timestamp={props.timestamp}
                  statement={props.statement}
                />
              )}
              {props.imageboolean && <ShowImage src={imageSrc} />}
              {props.loading && <Skeleton height={"5rem"} baseColor="white" />}
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <div style={{ width: "46px" }}></div>
            <TimeAgo timestamp={props.timestamp} />
            {/* //Translate and text to speech */}
            {!props.audioboolean && !props.imageboolean && (
              <>
                <TranslationTool
                  textToTranslate={props.content}
                  setTranslationOutput={setTranslationOutput}
                  translationOutput={translationOutput}
                />

                <TextToSpeechPlayer
                  inputText={
                    translationOutput ? translationOutput : props.content
                  }
                />
              </>
            )}
            {/* Delete message option */}
            {
              <MDBTooltip tag="div" placement="bottom" title="Delete">
                <div
                  className="ms-2 garbage-icon"
                  onClick={() => setDeleteMessage(true)}>
                  <Trash />
                </div>
              </MDBTooltip>
            }
          </div>
        </div>
      )}
    </>
  );
}
