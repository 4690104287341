import React from "react";
import { formatDistanceToNow } from "date-fns";
import frCA from "date-fns/locale/fr-CA";
import enCA from "date-fns/locale/en-CA";
import { useTranslation } from "react-i18next";

const TimeAgo = ({ timestamp }) => {
  const { t, i18n } = useTranslation();
  let timeAgo;
  if (timestamp) {
    const date = new Date(parseInt(timestamp));
    const locale = i18n.language === "en" ? { locale: enCA } : { locale: frCA };
    const timePeriod = formatDistanceToNow(date, locale);
    timeAgo = `${timePeriod} ${t("posts.ago")}`;
  }
  return <p className="text-xs m-0">{timeAgo}</p>;
};

export default TimeAgo;
