import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewReply } from "../../reducers/postsSlice";
import $ from "jquery";
import { nanoid } from "@reduxjs/toolkit";
import { selectUser } from "../../reducers/sessionSlice";
import {
  BlobServiceClient,
  AnonymousCredential, // Use AnonymousCredential for SAS token
} from "@azure/storage-blob";
import audiobufferToWav from "audiobuffer-to-wav";

export default function RecorderContainer(props) {
  console.log(props.id);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const replyId = nanoid();

  const onCancel = () => {
    $(`#${props.containerId}`).html("");
    $(`#audioControls${props.id}`).toggleClass("d-none");
    $(`#recordButton${props.id}`).toggleClass("d-none");
    $(`#commentField_${props.id}`).removeClass("d-none");
    $(`#multimediaContainer${props.containerId}`).addClass("ms-auto");
    $(`#replyCameraButton${props.id}`).removeClass("d-none");
  };

  const onCheck = async () => {
    const post_id = props.id;
    const primary_teacher_id =
      user.role === "Student" ? user.primary_teacher_id : null;
    try {
      props.setloading(true);
      var audio_url = document.getElementById(`${props.id}AudioRecording`).src;
      const audioContext = new AudioContext();
      const response = await fetch(audio_url);
      const audioData = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);
      const wavData = audiobufferToWav(audioBuffer);

      const your_sas_token_here = process.env.REACT_APP_SAS_TOKEN;

      // Initialize BlobServiceClient with AnonymousCredential for SAS token
      const blobServiceClient = new BlobServiceClient(
        `https://languageprofile.blob.core.windows.net?${your_sas_token_here}`, // Replace with your SAS token
        new AnonymousCredential()
      );

      // Get a reference to a container
      const containerClient = blobServiceClient.getContainerClient("audio");

      // Create a unique name for the blob
      const blobName = replyId + ".wav";

      // Get a block blob client
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      // Upload wav data to the blob
      const uploadBlobResponse = await blockBlobClient.upload(wavData, 1);
      console.log(
        `Upload block blob ${blobName} successfully`,
        uploadBlobResponse.requestId
      );

      onCancel();
      dispatch(
        addNewReply({
          user_id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          profile_pic_src: user.profile_pic_src,
          primary_teacher_id: primary_teacher_id,
          role: user.role,
          post_id: post_id,
          reply_id: replyId,
          content: "",
          audio_boolean: true,
          id: replyId,
          image_boolean: false,
        })
      ).unwrap();
      props.setloading(false);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const postRecordingControls = (
    <div className="upload-icon">
      {props.post !== true && (
        <FontAwesomeIcon
          icon={faCheck}
          className="px-1 hover-green cursor-pointer"
          onClick={() => onCheck()}
        />
      )}
      <FontAwesomeIcon
        icon={faXmark}
        className="hover-red px-1 cursor-pointer"
        onClick={() => {
          onCancel();
        }}
      />
    </div>
  );
  const audioControlsId = `audioControls${props.id}`;

  return (
    <div
      id={audioControlsId}
      className="d-flex justify-content-center align-items-center pt-2 d-none ms-2">
      {props.post && postRecordingControls}
      <span id={props.containerId}></span>
      {!props.post && postRecordingControls}
    </div>
  );
}
