import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ButtonStyles.css";
import { Link } from "react-router-dom";
import { MDBTooltip } from "mdb-react-ui-kit";
import NotificationCount from "./NotificationCount";

export const ButtonTemplate = (props) => {
  return (
    <MDBTooltip tag="div" placement="bottom" title={props.title}>
      <div className={"icon-flex px-1"} onClick={props.onClick} id={props.id}>
        <div className={"icon-wrapper"}>
          {props.href ? (
            <Link to={props.href}>
              <FontAwesomeIcon
                icon={props.icon}
                className={"icon "}
                style={{ color: "#38344c" }}
              />
            </Link>
          ) : (
            <>
              <FontAwesomeIcon
                icon={props.icon}
                className={"icon "}
                style={{ color: "#38344c" }}
              />
            </>
          )}
          {props.title === "Notifications" && <NotificationCount />}
        </div>
      </div>
    </MDBTooltip>
  );
};
