import React from "react";

export default function TutorialsLanding(props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 pb-4 pb-lg-0">
          <div
            className="card shadow hover-effect mx-5 py-5"
            onClick={() => props.setActiveInterface("student")}>
            <img
              className="vw-10 d-block mx-auto"
              src="https://language-profile.azurewebsites.net/static/images/student.png"
              loading="lazy"
              alt="student interface"
            />
            <h3 className="text-center fw-bold pt-3">Student Interface</h3>
          </div>
        </div>
        <div
          className="col-12 col-lg-6 pb-4 pb-lg-0"
          onClick={() => props.setActiveInterface("teacher")}>
          <div className="card shadow hover-effect mx-5 py-5">
            <img
              className="vw-10 d-block mx-auto"
              src="https://language-profile.azurewebsites.net/static/images/teacher.png"
              loading="lazy"
              alt="teacher interface"
            />
            <h3 className="text-center fw-bold pt-3">Teacher Interface</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
