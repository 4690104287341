import React, { useState, useEffect } from "react";
import {
  MDBInput,
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";

import { useTranslation } from "react-i18next";
import { ArrowLeftCircle, Envelope } from "react-bootstrap-icons";
import baseUrl from "../../utils/baseUrl";
import { useNavigate } from "react-router";
import { selectUser } from "../../reducers/sessionSlice";
import { useSelector } from "react-redux";

export default function ContactForm() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const sessionStatus = useSelector((state) => state.session.status);
  const [presetEmail, setPresetEmail] = useState("");
  const emptyForm = { name: "", email: presetEmail, subject: "", message: "" };
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyForm);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStatus === "succeeded") {
      setPresetEmail(user?.email);
    }
  }, [setPresetEmail, user, sessionStatus]);

  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendEmail();
    }
    setValidated(true);
  };

  const refresh = () => {
    setEmailSent(false);
    setFormData(emptyForm);
    setValidated(false);
    navigate("/");
  };

  const sendEmail = async () => {
    setEmailSent("Pending");
    const rawResponse = await fetch(`${baseUrl}/send_email`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const content = await rawResponse.json();
    if (content.error) {
      setEmailSent("error");
    } else {
      setEmailSent(true);
    }
  };

  return (
    <div className="m-1 m-lg-3 d-flex flex-column align-items-center">
      {emailSent === "pending" && <Envelope className="fa-3x blink-effect" />}

      <div className="card col-12 col-lg-6 p-1 p-lg-4 shadow">
        {!emailSent ? (
          <div className="p-2">
            <MDBValidation
              noValidate
              id="form"
              style={{ width: "100%" }}
              validated={validated.toString()}
              onSubmit={handleSubmit}>
              <img
                alt="Logo"
                id="main-image"
                className="mx-auto d-block"
                src="https://language-profile.azurewebsites.net/static/images/main.png"
              />
              <div className="py-4">
                <h2 className="text-center">
                  {t("technicalSupport.Technical Support")}
                </h2>
                <h5 className="text-center text-secondary m-0">
                  {t("technicalSupport.Subheader")}
                </h5>
              </div>

              <MDBValidationItem
                invalid
                feedback={t("technicalSupport.Name Validate")}>
                <MDBInput
                  label={t("technicalSupport.Name")}
                  v-model="name"
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                  wrapperClass="mb-4"
                  required
                />
              </MDBValidationItem>

              <MDBValidationItem
                invalid
                feedback={t("technicalSupport.Email Validate")}>
                <MDBInput
                  type="email"
                  label={t("technicalSupport.Email")}
                  v-model="email"
                  wrapperClass="mb-4"
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                  required
                />
              </MDBValidationItem>

              <MDBValidationItem
                invalid
                feedback={t("technicalSupport.Subject Validate")}>
                <MDBInput
                  label={t("technicalSupport.Subject")}
                  v-model="subject"
                  wrapperClass="mb-4"
                  name="subject"
                  value={formData.subject}
                  onChange={changeHandler}
                  required
                />
              </MDBValidationItem>

              <MDBValidationItem
                invalid
                feedback={t("technicalSupport.Message Validate")}>
                <MDBTextArea
                  wrapperClass="mb-4"
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={changeHandler}
                  required
                />
              </MDBValidationItem>

              <div className="text-center">
                <MDBBtn type="submit" color="primary" className="my-4">
                  <Envelope className="my-auto me-2" />
                  {t("technicalSupport.Send")}
                </MDBBtn>
              </div>
            </MDBValidation>
          </div>
        ) : (
          <>
            <div>
              <img
                alt="Logo"
                id="main-image"
                className="mx-auto d-block"
                src="https://language-profile.azurewebsites.net/static/images/main.png"
              />
              {emailSent === "error" ? (
                <div className="py-4">
                  <h2 className="text-center">
                    {t("technicalSupport.Error Header")}
                  </h2>
                  <h5 className="text-center text-secondary m-0">
                    {t("technicalSupport.Error Subheader")}
                  </h5>
                </div>
              ) : (
                <div className="py-4">
                  <h2 className="text-center">
                    {t("technicalSupport.Success Header")}
                  </h2>
                  <h5 className="text-center text-secondary m-0">
                    {t("technicalSupport.Success Subheader")}
                  </h5>
                </div>
              )}
            </div>
            <div className="text-center cursor-pointer" onClick={refresh}>
              <ArrowLeftCircle className="fa-2x mb-2" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
