import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const AiInformationModal = (props) => {
  const [replay, setReplay] = useState(false);
  const [imageSrc, setImageSrc] = useState(props.modalData?.gif);

  const handleGifEnd = () => {
    props.modalData?.gif && (props.modalData.gif.currentTime = 0);
    setReplay(!replay);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReplay(!replay);
    }, 14000);

    return () => {
      clearInterval(intervalId);
    };
  }, [replay]);

  useEffect(() => {
    // Update image source with a random query parameter to force reload
    setImageSrc(`${props.modalData?.gif}?${Math.random()}`);
  }, [replay, props.modalData?.gif]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{props.modalData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.modalData?.studentAccountOnly && (
            <p className="text-danger text-small">
              *Available on student account only.
            </p>
          )}
          <img
            key={replay}
            className="img-fluid mb-4 w-100"
            src={imageSrc}
            alt="qr code poster"
            onEnded={handleGifEnd}
          />
          <p className="fw-bold">Instructions</p>
          {props.modalData?.steps?.map((step, i) => (
            <p key={i}>
              <span className="fw-bold">{i + 1}.</span> {step}
            </p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AiInformationModal;
