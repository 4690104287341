import React, { useState, useEffect } from "react";
import "./Styles.css";
import {
  Clipboard,
  Clipboard2Check,
  Clipboard2CheckFill,
} from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import { MDBTooltip } from "mdb-react-ui-kit";
import baseUrl from "../../utils/baseUrl";
import { thresholdDateAudioUpdate1 } from "../../utils/updates";

export default function AudioPlayer(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [findFeedback, setFindFeedback] = useState(false);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const user = useSelector(selectUser);
  const meetsUpdateThreshold = props.timestamp > thresholdDateAudioUpdate1;
  const [aiResponse, setAiResponse] = useState("");
  const [transcription, setTranscription] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const src = meetsUpdateThreshold ? `${props.src}.wav` : props.src;

  useEffect(() => {
    // Make API request when the modal is open
    if (findFeedback) {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          language: language,
          statement: props.statement,
          level: "A2",
          audio_file: src,
        }),
      };

      fetch(`${baseUrl}/feedback_on_audio`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setTranscription(data.transcription);
          setAiResponse(JSON.parse(data.content));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("API Error:", error);
          setTranscription("Somethings not right. Please try again later.");
          setIsLoading(false);
        });
    }
  }, [findFeedback, language, props.statement, src]);

  const audioFeedbackHandler = () => {
    // Add your logic for audio feedback here
  };

  const handleClipboardClick = () => {
    setFindFeedback(true);
  };

  const handleButtonClick = (i) => {
    // Get the text to be copied
    const textToCopy = aiResponse?.feedback[i];

    // Create a temporary textarea element to copy the text
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);

    // Select the text in the textarea
    tempTextArea.select();
    tempTextArea.setSelectionRange(0, 99999); // For mobile devices

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary textarea element
    document.body.removeChild(tempTextArea);

    // Update state to indicate the button click and trigger a re-render
    setIsCopied(i);
  };

  const audioPlayer = (
    <audio
      className="audio-player"
      src={src}
      preload="none"
      style={{ width: "300px" }}
      controls
    />
  );

  return (
    <>
      <div
        className="pt-2 audio-player-container d-flex flex-row align-items-center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {audioPlayer}
        {user.role === "Teacher" &&
          !isLoading &&
          isHovered &&
          meetsUpdateThreshold && (
            <MDBTooltip tag="div" placement="bottom" title="AI Assessment Tool">
              <div onClick={audioFeedbackHandler}>
                <Clipboard2Check
                  className={"ms-1 text-green clipboard-icon"}
                  onClick={handleClipboardClick}
                />
              </div>
            </MDBTooltip>
          )}
      </div>

      <hr className="mt-3" />
      {isLoading && (
        <>
          <p className="blink-effect text-secondary">Content Loading...</p>
        </>
      )}
      {findFeedback && !isLoading && (
        <div>
          <p className="text-center fw-bold text-secondary p-2">
            AI Formative Feedback
          </p>
          <div className="d-flex flex-row justify-content-center">
            <div className="col-6 bg-light card me-2 p-2">
              <p className="fw-bold mt-1 mb-0">Suggested Feedback</p>
              {aiResponse?.feedback?.map((item, i) => (
                <div className={`d-flex flex-row  p-3 align-items-center `}>
                  <div className="col-2">
                    <Button
                      size={"sm"}
                      variant={isCopied === i ? "success" : "primary"}
                      onClick={() => handleButtonClick(i)}
                      // Set a fixed width for consistency
                    >
                      <div>
                        {isCopied === i ? (
                          <Clipboard2CheckFill />
                        ) : (
                          <Clipboard />
                        )}
                      </div>
                    </Button>
                  </div>
                  <div className="copy-here col-10">
                    <p className="text-small m-0">{item}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-6 bg-light card p-2">
              <p className="fw-bold mt-1 mb-0">Transcription</p>

              <p className="text-small text-secondary fst-italic">
                {transcription}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
