import React from "react";
import MetricsCards from "./MetricsCards";

export default function TeacherMetricsCards(props) {
  // Take the user role, and modify data inputs
  let goalsCompleted = 0;
  let goalsInProgress = 0;
  const postCount = props.postData?.all_posts_timestamps?.length || 0;
  const data = props.progressData.map((entry) => entry.data);

  if (props.progressData !== null) {
    for (let entry of data) {
      for (let category of entry) {
        try {
          if (category.goals_completed) {
            goalsCompleted += parseInt(category.goals_completed);
          }
          if (category.in_progress) {
            goalsInProgress += category?.in_progress?.length;
          }
        } catch {
          continue;
        }
      }
    }
  }

  return (
    <MetricsCards
      flashing={props.flashing}
      goalsCompleted={goalsCompleted}
      goalsInProgress={goalsInProgress}
      postCount={postCount}
    />
  );
}
