import {
  faFlagCheckered,
  faSchool,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Bullseye,
  Envelope,
  GraphUpArrow,
  QuestionCircle,
} from "react-bootstrap-icons";

export default function AdminButtons(props) {
  const iconClass = "fa-xl fa-fw text-secondary"; // Remove text-secondary from the default icon class
  const selectedIconClass = "text-black"; // Define a class for the selected icon

  const adminButtons = [
    {
      name: "Metrics",
      label: "Metrics",
      icon: (
        <GraphUpArrow
          className={`${iconClass} ${
            props.activeTable === "Metrics" && selectedIconClass
          }`}
        />
      ),
    },
    {
      name: "Schools",
      label: "Schools",
      icon: (
        <FontAwesomeIcon
          className={`${iconClass} ${
            props.activeTable === "Schools" && selectedIconClass
          }`}
          icon={faSchool}
        />
      ),
    },
    {
      name: "Teachers",
      label: "Teachers",
      icon: (
        <FontAwesomeIcon
          className={`${iconClass} ${
            props.activeTable === "Teachers" && selectedIconClass
          }`}
          icon={faUser}
        />
      ),
    },
    {
      name: "Goals",
      label: "Goals",
      icon: (
        <Bullseye
          className={`${iconClass} ${
            props.activeTable === "Goals" && selectedIconClass
          }`}
        />
      ),
    },
    {
      name: "Quickstart",
      label: "Quickstart",
      icon: (
        <FontAwesomeIcon
          className={`${iconClass} ${
            props.activeTable === "Quickstart" && selectedIconClass
          }`}
          icon={faFlagCheckered}
        />
      ),
    },
    {
      name: "Instructions",
      label: "Instructions",
      icon: (
        <QuestionCircle
          className={`${iconClass} ${
            props.activeTable === "Instructions" && selectedIconClass
          }`}
        />
      ),
    },
    {
      name: "Contact",
      label: "Contact",
      icon: (
        <Envelope
          className={`${iconClass} ${
            props.activeTable === "Contact" && selectedIconClass
          }`}
        />
      ),
    },
  ];

  return (
    <div className="d-flex flex-column justify-content-center pt-4">
      <p className="text-muted fw-bold mb-3 px-4">Navigation</p>
      {adminButtons.map((button) => {
        const buttonTextClass = `ms-3 mb-0 ${
          props.activeTable === button.name && "fw-bold"
        }`;
        return (
          <div
            key={button.name}
            onClick={() => props.setActiveTable(button.name)}
            className="d-flex flex-row align-items-center py-3 panel-hover px-4">
            <div className="col-auto ">{button.icon}</div>

            <div className="col-auto p-1 d-none d-lg-block">
              <p className={buttonTextClass}>{button.label}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
