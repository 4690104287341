import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import { useTranslation } from "react-i18next";
import {
  addStudentToGroup,
  removeGroup,
  removeStudentFromGroup,
  selectGroupNames,
  selectGroupings,
} from "../../reducers/groupingsSlice";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import ProfilePicture from "../profilePicture/ProfilePicture";

export default function GroupingsModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const groupingsStatus = useSelector((state) => state.groupings.status);
  const user = useSelector(selectUser);
  const [deleteActive, setDeleteActive] = useState(false);
  const groupNames = useSelector(selectGroupNames);
  const teacherPermissions = useSelector(selectTeacherPermissions);

  const groupings = useSelector(selectGroupings);

  const deleteHandler = () => {
    dispatch(removeGroup(props.groupingId));
    setDeleteActive(false);
    props.handleClose();
  };
  const deleteMessage = (
    <div className="text-center">
      <p className="fw-bold"> {t(`classList.Delete Group`)}?</p>
      <button
        type="button"
        className="btn btn-danger ms-auto"
        aria-label="Delete"
        onClick={deleteHandler}>
        {t(`classList.Delete`)}
      </button>
      <div className="mt-1">
        <button
          type="button"
          className="btn btn-outline-secondary ms-auto"
          aria-label="Cancel"
          onClick={() => setDeleteActive(false)}>
          {t(`classList.Cancel`)}
        </button>
      </div>
    </div>
  );

  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {groupNames.find((item) => item.id === props.groupingId)?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deleteActive ? (
          deleteMessage
        ) : (
          <>
            {teacherPermissions.map((item, i) => {
              return (
                <div
                  key={i}
                  className="row py-2 px-3 border-top border-bottom align-middle">
                  <div className="col-2 my-auto">
                    <ProfilePicture src={item?.profile_pic_src} size={50} />
                  </div>
                  <div className="col-8 my-auto">
                    {item.last_name}, {item.first_name}
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    {groupings.find(
                      (group) =>
                        group.grouping_id === props.groupingId &&
                        group.student_id === item.student_id
                    ) && (
                      <button
                        type="button"
                        className="btn btn-outline-danger "
                        aria-label="Remove from group"
                        onClick={() => {
                          if (groupingsStatus !== "loading") {
                            dispatch(
                              removeStudentFromGroup({
                                student_id: item.student_id,
                                teacher_id: user.id,
                              })
                            ).unwrap();
                          }
                        }}>
                        {t(`classList.Remove`)}
                      </button>
                    )}
                    {groupings.find(
                      (group) => group.student_id === item.student_id
                    ) && <p></p>}
                    {!groupings.find(
                      (group) => group.student_id === item.student_id
                    ) && (
                      <button
                        type="button"
                        className="btn btn-outline-success "
                        aria-label="Add to group"
                        onClick={() => {
                          if (groupingsStatus !== "loading") {
                            dispatch(
                              addStudentToGroup({
                                student_id: item.student_id,
                                teacher_id: user.id,
                                grouping_id: props.groupingId,
                              })
                            ).unwrap();
                          }
                        }}>
                        {t(`classList.Add`)}
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!deleteActive && (
          <>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              aria-label="Delete"
              onClick={() => setDeleteActive(true)}>
              {t(`classList.Delete Group`)}
            </button>{" "}
            <button
              type="button"
              className="btn btn-primary btn-sm"
              aria-label="Okay"
              onClick={() => props.handleClose()}>
              {t(`classList.Close`)}
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
