import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

function CarouselCard(props) {
  const truncate = (input) =>
    input?.length > 70 ? `${input.substring(0, 65)}...` : input;
  var url = `https://language-profile.azurewebsites.net/static/images/goal_icons/${props.title}_goals/${props.title}1.png`;
  const { i18n } = useTranslation();

  return (
    <div onClick={props.onClick} className="cursor-pointer">
      <Card
        className={
          props.active
            ? "container-active hover-effect mb-3 mx-auto carousel-card"
            : "hover-effect mb-3 mx-auto carousel-card"
        }
        onClick={props.onClick}
        key={props.id}>
        <div className="d-flex justify-content-center align-items-center">
          <Card.Body style={{ height: "120px", padding: "10px" }}>
            <img
              alt="goal"
              className="mx-auto p-2"
              variant="top"
              src={url}
              style={{ height: "120px", width: "auto" }}
            />
            <p className={"text-center text-large"}>
              {i18n.language === "en" ? props.title : props.title_fr}
            </p>
            <p className="text-xs pb-2">
              {i18n.language === "en"
                ? truncate(props.description)
                : truncate(props.description_fr)}
            </p>
          </Card.Body>
        </div>
      </Card>
    </div>
  );
}

export default CarouselCard;
