import React from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { appLevelsReverse } from "../../utils/levels";
import { COLORS } from "../../utils/colors";
import toTitleCase from "../../utils/toTitleCase";
import NoData from "./NoData";
import { RingLoader } from "react-spinners";

import { useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";

function CustomTooltip({ payload, active }) {
  if (active && payload && payload.length) {
    return (
      <div className="border border-secondary bg-white p-3">
        <p className="m-0 fw-bold text-small">{`${toTitleCase(
          payload?.[0].payload.label
        )}`}</p>

        <p className="m-0 text-small"># Students: {payload?.[0].value}</p>
      </div>
    );
  }

  return null;
}
export default function LevelBreakdown(props) {
  const cefrLevels = Object.entries(appLevelsReverse);
  const rawData = props.progressData.map((entry) => entry.data);
  const students = useSelector(selectTeacherPermissions);

  let data = [];
  cefrLevels.map(([key, value]) => {
    return data.push({ label: value, value: 0 });
  });

  if (props.progressData !== null) {
    for (let entry of rawData) {
      for (let category of entry) {
        try {
          if (category.strand === "Overall") {
            data[category.grade].value += 1;
          }
        } catch {
          continue;
        }
      }
    }
  }
  const renderLegendText = (value) => {
    return <span>{value}</span>;
  };

  const chart = (
    <>
      <PieChart width={200} height={250}>
        <Pie
          data={data.filter((entry) => entry.value !== 0)}
          labelLine={false}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />

        <Legend
          formatter={renderLegendText}
          iconSize={8}
          payload={data
            .filter((entry) => entry.value !== 0)
            .map((item, index) => ({
              id: index,
              type: "square",
              value: `${toTitleCase(item.label)}`,
              color: COLORS[index % COLORS.length],
            }))}
        />
      </PieChart>
    </>
  );

  return props.isLoaded ? (
    students?.length !== 0 ? (
      chart
    ) : (
      <NoData height={250} />
    )
  ) : (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: 250 }}>
      <RingLoader />
    </div>
  );
}
