import React, { useState } from "react";
import "./MakeReplyStyles.css";
import ProfilePicture from "../profilePicture/ProfilePicture";
import { useDispatch, useSelector } from "react-redux";
import { addNewReply } from "../../reducers/postsSlice";
import Recorder from "../Audio/Recorder";
import CameraButton from "../ImageUpload/CameraButton";
import RecorderContainer from "../Audio/RecorderContainer";
import ImageUpload from "../ImageUpload/ImageUpload";
import $ from "jquery";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../reducers/sessionSlice";
import baseUrl from "../../utils/baseUrl";

export default function MakeReply(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [recorder, setRecorder] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [images, setImages] = useState([]);
  const user = useSelector(selectUser);

  const deleteAudio = () => {
    setRecorder(false);
  };

  const handleKeyDown = async (e) => {
    const primary_teacher_id =
      user.role === "Student" ? user.primary_teacher_id : null;
    if (e.key === "Enter" && (e.target.value || images.length !== 0)) {
      let content = e.target.value;
      let image_boolean = false;
      let reply_id = nanoid();

      if (images.length !== 0) {
        image_boolean = true;
        // Make multimedia call and then set reply_id
        try {
          props.setloading(true);

          const imageUrl = images[0].data_url;
          document.getElementById(`removeAllImages${props.id}`).click();
          setShowImageUpload(false);
          fetch(imageUrl)
            .then((r) => r.blob())
            .then((blob) => {
              if (blob) {
                var formData = new FormData();
                formData.append("file", blob, reply_id);
                formData.append("reply_id", reply_id);
                fetch(`${baseUrl}/store_multimedia/image/reply/${reply_id}`, {
                  method: "POST",
                  body: formData,
                })
                  .then((response) => response.json())
                  .then((json) => {
                    dispatch(
                      addNewReply({
                        user_id: user.id,
                        primary_teacher_id: primary_teacher_id,
                        role: user.role,
                        profile_pic_src: user.profile_pic_src,
                        reply_id: reply_id,
                        post_id: props.id,
                        content: content,
                        image_boolean: image_boolean,
                        audio_boolean: false,
                      })
                    ).unwrap();
                  });
                props.setloading(false);
              }
            });
        } catch {
          console.log("Reply has no image.");
        }
      }

      if (images.length === 0) {
        dispatch(
          addNewReply({
            user_id: user.id,
            primary_teacher_id: primary_teacher_id,
            first_name: user.first_name,
            last_name: user.last_name,
            role: user.role,
            profile_pic_src: user.profile_pic_src,

            reply_id: reply_id,
            post_id: props.id,
            content: content,
            image_boolean: image_boolean,
            audio_boolean: false,
          })
        ).unwrap();
      }

      e.target.value = "";
    }
  };
  const commentFieldId = `commentField_${props.id}`;
  const containerId = `replyRecorder${props.id}`;
  const recordButtonId = `recordButton${props.id}`;
  const multimediaContainerId = `multimediaContainer${containerId}`;
  const replyCameraButton = `replyCameraButton${props.id}`;
  const placeholderText = `${t("posts.Write a comment")}...`;

  return (
    <>
      <div className="row mt-2">
        <div className="d-flex flex-row col-lg-12 align-items-center">
          <div>
            <ProfilePicture src={user?.profile_pic_src} size="30px" />
          </div>

          <input
            id={commentFieldId}
            className="form-control my-auto ms-2 bg-light"
            placeholder={placeholderText}
            onKeyDown={handleKeyDown}
          />

          <div id={multimediaContainerId} className="d-flex ms-auto">
            <RecorderContainer
              id={props.id}
              delete={deleteAudio}
              containerId={containerId}
              setrecorder={setRecorder}
              recorder={recorder}
              loading={props.loading}
              setloading={props.setloading}
            />
            <div
              id={recordButtonId}
              className="ms-2"
              onClick={() => {
                document
                  .getElementById(`commentField_${props.id}`)
                  .classList.add("d-none");
                $(`#multimediaContainer${containerId}`).removeClass("ms-auto");
                $(`#replyCameraButton${props.id}`).addClass("d-none");
              }}>
              <Recorder
                id={props.id}
                containerId={containerId}
                setrecorder={setRecorder}
                recorder={recorder}
              />
            </div>
            <div id={replyCameraButton}>
              <CameraButton
                showimageupload={showImageUpload}
                setshowimageupload={setShowImageUpload}
              />
            </div>
          </div>
        </div>
      </div>
      {showImageUpload && (
        <ImageUpload
          id={props.id}
          images={images}
          setimages={setImages}
          showimageupload={showImageUpload}
        />
      )}
    </>
  );
}
