import React, { useState } from "react";
import "./Notifications.css";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  markNotificationRead,
  selectAllNotifications,
} from "../../reducers/notificationsSlice";
import ProfilePicture from "../profilePicture/ProfilePicture";
import { ChatFill, CircleFill, HeartFill } from "react-bootstrap-icons";
import "../../App.css";
import "../Navbar/buttons/ButtonStyles.css";
import NotificationsDropdown from "./NotificationsDropdown";
import TimeAgo from "../Posts/TimeAgo";
import { useTranslation } from "react-i18next";
import ShowPostModalModal from "./ShowPostModal";

export default function NotificationsModal(props) {
  const notifications = useSelector(selectAllNotifications);
  const { t } = useTranslation();
  const [showPost, setShowPost] = useState(false);
  const [activePost, setActivePost] = useState("");
  const dispatch = useDispatch();

  const getContent = (notification) => {
    let text = "";
    let icon = "";
    const iconClass = "fa-xl text-white";
    const iconContainerClass =
      "button__badge button__notification d-flex align-items-center";
    switch (notification.type) {
      case 0:
        text = (
          <div>
            <span className="fw-bold me-1 text-small">
              {notification.sender}
            </span>
            <span className="text-small">{t("notifications.0")}</span>
          </div>
        );
        icon = (
          <div className={`${iconContainerClass} blue`}>
            <ChatFill className={iconClass} />
          </div>
        );
        break;
      case 1:
        text = (
          <div>
            <span className="fw-bold me-1 text-small">
              {notification.sender}
            </span>
            <span className="text-small">{t("notifications.1")}</span>
          </div>
        );
        icon = (
          <div className={`${iconContainerClass} red`}>
            <HeartFill className={iconClass} />
          </div>
        );
        break;
      default:
        text = "Looking forward to the Weekend";
    }
    return { text: text, icon: icon };
  };

  const seePostHandler = (post_id, notification_id, read) => {
    setActivePost(post_id);
    setShowPost(true);
    !read && dispatch(markNotificationRead(notification_id));
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-secondary fw-bold">Notifications</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {notifications?.map((notification) => {
            const content = getContent(notification);
            const containerClass =
              "row p-2 hover-effect mx-auto appear-on-hover ";
            return (
              <div className={containerClass} key={notification.id}>
                <div
                  className="col-2 notifications-list-image"
                  onClick={() =>
                    seePostHandler(
                      notification.post_id,
                      notification.id,
                      notification.read
                    )
                  }>
                  <ProfilePicture src={notification.profile_pic_src} />
                  {content.icon}
                </div>
                <div
                  onClick={() =>
                    seePostHandler(
                      notification.post_id,
                      notification.id,
                      notification.read
                    )
                  }
                  className="col-8 d-flex flex-column justify-content-center">
                  <p className="m-0 p-0">{content.text}</p>
                  <div className="text-xs m-0">
                    <TimeAgo timestamp={notification.timestamp} />
                  </div>
                </div>

                <div className="col-1 d-flex flex-row align-items-center justify-content-center ">
                  <NotificationsDropdown
                    notification_id={notification.id}
                    read={notification.read}
                  />
                </div>
                <div
                  onClick={() =>
                    seePostHandler(
                      notification.post_id,
                      notification.id,
                      notification.read
                    )
                  }
                  className="col-1 d-flex flex-row align-items-center justify-content-center">
                  {!notification.read && <CircleFill color="green" />}
                </div>
              </div>
            );
          })}
          {(notifications?.length === 0 || !notifications) && (
            <p className="fw-bold">{t("notifications.No notifications")}</p>
          )}
        </Modal.Body>
      </Modal>
      {showPost && (
        <ShowPostModalModal
          activePost={activePost}
          show={showPost}
          onHide={() => {
            setActivePost("");
            setShowPost(false);
          }}
        />
      )}
    </>
  );
}
