import React from "react";
import appStrands from "../../utils/strands";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import toTitleCase from "../../utils/toTitleCase";
import { COLORS } from "../../utils/colors";
import NoData from "./NoData";
import { RingLoader } from "react-spinners";

export default function GoalsSetByStrand(props) {
  const rawData = props.progressData.map((entry) => entry.data);

  const data = appStrands.en.slice(1).map((element) => {
    return { strand: element, count: 0 };
  });

  if (props.progressData !== null) {
    for (let entry of rawData) {
      for (let category of entry) {
        try {
          data.find(
            (item) => item.strand === category.strand.toLowerCase()
          ).count += category.in_progress.length;
        } catch {
          continue;
        }
      }
    }
  }

  const renderLegendText = (value) => {
    return <span>{value}</span>;
  };

  function CustomTooltip({ payload, active }) {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div className="border border-secondary bg-white p-3">
          <p className="m-0 fw-bold text-small">{`${toTitleCase(
            payload[0].payload.strand
          )}`}</p>

          <p className="m-0 text-small"># Goals: {payload[0].value}</p>
        </div>
      );
    }

    return null;
  }

  const chart = (
    <>
      <PieChart width={200} height={250}>
        <Pie
          data={data}
          labelLine={false}
          outerRadius={80}
          paddingAngle={5}
          fill="#8884d8"
          dataKey="count">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />

        <Legend
          formatter={renderLegendText}
          iconSize={8}
          payload={data?.map((item, index) => ({
            id: item.strand,
            type: "square",
            value: `${toTitleCase(item.strand)}`,
            color: COLORS[index % COLORS.length],
          }))}
        />
      </PieChart>
    </>
  );
  let count = 0;
  data.forEach((entry) => {
    count += entry.count;
  });

  return props.isLoaded ? (
    count !== 0 ? (
      chart
    ) : (
      <NoData height={250} />
    )
  ) : (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: 250 }}>
      <RingLoader />
    </div>
  );
}
