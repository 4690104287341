import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { MDBTooltip } from "mdb-react-ui-kit";
import { evalScaleEn, evalScaleFr } from "../AddGoal/AddGoalForm";
import { useTranslation } from "react-i18next";

export default function UpdatedGradeProgress(props) {
  const percentage = (props.selfeval * 100) / 4;
  const { i18n } = useTranslation();
  const evalScale = i18n.language === "en" ? evalScaleEn : evalScaleFr;
  const icon = props.check ? faCheck : faTrophy;
  const tooltip = props.check
    ? "Click to Pass"
    : `${props.selfeval} - ${evalScale[props.selfeval]}`;
  return (
    <>
      <div className="hover-effect" onClick={() => props.setShowModal(true)}>
        <MDBTooltip tag="div" placement="bottom" title={tooltip}>
          <CircularProgressbarWithChildren value={percentage}>
            {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
            <FontAwesomeIcon
              className="fa-2xs"
              icon={icon}
              color={`${props.check ? "green" : "goldenrod"}`}
            />
          </CircularProgressbarWithChildren>
        </MDBTooltip>
      </div>
    </>
  );
}
