import React, { useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import {
  fetchGroupings,
  selectGroupNames,
} from "../../reducers/groupingsSlice";

export default function GroupSelect(props) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // GROUPS
  const groupingsStatus = useSelector((state) => state.groupings.status);
  useEffect(() => {
    if (groupingsStatus === "idle" && user?.id) {
      dispatch(fetchGroupings(user.id));
    }
  }, [groupingsStatus, dispatch, user]);
  const groupNames = useSelector(selectGroupNames);
  const groupNamesSorted = [...groupNames].sort((a, b) =>
    a.name?.localeCompare(b.name)
  );
  const groupNamesOptions = groupNamesSorted?.map((item) => {
    return { label: item.name, value: item.id };
  });
  return (
    <Select
      name="group"
      className="text-start w-100"
      placeholder={t("addGoal.Group").toUpperCase()}
      options={groupNamesOptions}
      isClearable={true}
      onChange={props.onChange}
    />
  );
}
