import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  fetchAdminDistrictsList,
  selectAdminDistrictsList,
} from "../../../reducers/adminDistrictsListSlice";
import { selectUser } from "../../../reducers/sessionSlice";

export default function SelectDistrict(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const adminDistrictsListStatus = useSelector(
    (state) => state.adminDistrictsList.status
  );
  let districtsList = useSelector(selectAdminDistrictsList);
  districtsList = [...districtsList]?.sort((a, b) =>
    a.district_name?.localeCompare(b.district_name)
  );
  useEffect(() => {
    if (adminDistrictsListStatus === "idle") {
      dispatch(
        fetchAdminDistrictsList(
          user?.role === "Developer" ? "All" : user?.district_id
        )
      );
    }
  }, [adminDistrictsListStatus, dispatch, user]);
  const districtsOptions = districtsList?.map((district) => ({
    label: district.district_name,
    value: district.district_id,
  }));
  const content =
    props.edit === true || props.edit === props.id ? (
      <Select
        className="search-select me-2"
        name="district_id"
        onChange={props.onChange}
        instanceId="districtName"
        placeholder={"Select District Name"}
        isClearable
        options={districtsOptions}
        isDisabled={user?.role === "Developer" ? false : true}
        value={districtsOptions.filter(
          (option) => option.value === props.value
        )}
      />
    ) : (
      <>
        <span className="m-0">
          {
            districtsList?.find(
              (district) => district.district_id === props.savedValue
            )?.district_name
          }
        </span>
      </>
    );
  return content;
}
