import React, { useState } from "react";
import ProfileCard from "../../components/profile/ProfileCard";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";

export default function Profile() {
  const [active, setActive] = useState(false);
  const user = useSelector(selectUser);

  return (
    <ProfileCard
      user={user}
      onClick={() => setActive(false)}
      active={active}
      setactive={setActive}
    />
  );
}
