import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { selectUser } from "../../reducers/sessionSlice";
import baseUrl from "../../utils/baseUrl";

const SurveyModal = () => {
  const user = useSelector(selectUser);
  const isSurveyed = user.isSurveyed;
  const [showSurvey, setShowSurvey] = useState(isSurveyed);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleButtonClick = (answer) => {
    // Construct the API address based on the user's ID and the selected answer
    const apiAddress = `${baseUrl}/answer_survey/${user.id}/${answer}`;

    // Perform the API request using the fetch API
    fetch(apiAddress, {
      method: "POST", // or "GET" depending on your API endpoint
      // Add any necessary headers or body data here
    })
      .then((response) => {
        // Handle the API response as needed
        console.log("API Response:", response);
      })
      .catch((error) => {
        // Handle errors here
        console.error("API Error:", error);
      });

    // Set the state to indicate that a button has been clicked
    setIsButtonClicked(true);
  };

  // If isSurveyed is false or the button is clicked, show the thank you message
  if (isSurveyed || isButtonClicked) {
    return (
      <Modal
        centered
        show={showSurvey}
        onHide={() => {
          setShowSurvey(false);
        }}
        className="modern-modal text-center">
        <Modal.Body>
          {isButtonClicked ? (
            <h2 className="fw-bold pt-4">Thank you!</h2>
          ) : (
            <>
              <h5 className="fw-bold pt-4">Quick Question:</h5>
              <p>
                For a $15 Staples Gift Card, would you be willing to have a
                15-minute video chat to tell us about your experience with My
                Language Profile/Pongo?
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="modern-footer justify-content-center">
          {isButtonClicked ? (
            <Button
              variant="success"
              onClick={() => {
                setShowSurvey(false);
              }}>
              Done
            </Button>
          ) : (
            <>
              <Button
                variant="outline-secondary"
                size="lg"
                onClick={() => handleButtonClick("no")}>
                No
              </Button>
              <Button
                variant="primary"
                size="lg"
                onClick={() => handleButtonClick("yes")}
                className="mr-auto">
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  return null;
};

export default SurveyModal;
