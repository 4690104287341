import React from "react";
import { FileBarGraph } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function NoData(props) {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center p-3"
      style={{ height: !!props.height && props.height }}>
      <FileBarGraph className="fa-6x" style={{ color: "lightgray" }} />
      <p className="text-secondary fw-bold mt-3 text-center">
        {t(`data.No Data`)}
      </p>
    </div>
  );
}
