import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTeacherPermissions,
  selectTeacherPermissions,
} from "../../reducers/teacherPermissionsSlice";
import "react-loading-skeleton/dist/skeleton.css";
import StudentCarousel from "./StudentCarousel";
import Posts from "../Posts/Posts";
import { fetchPosts, selectAllPosts } from "../../reducers/postsSlice";
import usePageBottom from "../../utils/usePageBottom";
import { TourContext } from "../../contexts/TourContext";
import { selectUser } from "../../reducers/sessionSlice";
import SurveyModal from "../Survey/SurveyModal";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-bootstrap-icons";
import ReactJoyride from "react-joyride";
import { joyrideStyles, teacherTour } from "../../utils/tour";

export default function TeacherWall(props) {
  const user = useSelector(selectUser);
  const { tourSwitch, setTourSwitch } = useContext(TourContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const teacherPermissionsStatus = useSelector(
    (state) => state.teacherPermissions.status
  );
  const posts = useSelector(selectAllPosts);

  const [filterPosts, setFilterPosts] = useState({ strand: "All" });
  const [activeStudent, setActiveStudent] = useState(null);
  let content;
  let teacherPermissions = useSelector(selectTeacherPermissions);
  const postStatus = useSelector((state) => state.posts.status);
  const reachedBottom = usePageBottom();
  const [postLengthAfterFetch, setPostLengthAfterFetch] = useState("");
  const [postLengthBeforeFetch, setPostLengthBeforeFetch] = useState(0);

  useEffect(() => {
    console.log("Bottom of page. Fetching post.");
    if (
      posts.length !== 0 &&
      reachedBottom &&
      postStatus === "succeeded" &&
      postLengthBeforeFetch !== postLengthAfterFetch
    ) {
      setPostLengthBeforeFetch(posts.length);
      dispatch(
        fetchPosts({
          role: user.role,
          user_id: user.id,
          student_id: null,
          school_id: user.school_id,
          permissions: null,
          offset: posts.length,
        })
      ).unwrap();
    }
  }, [
    reachedBottom,
    postStatus,
    user,
    dispatch,
    posts,
    postLengthBeforeFetch,
    postLengthAfterFetch,
    setPostLengthBeforeFetch,
  ]);

  useEffect(() => {
    if (postStatus === "succeeded") {
      setPostLengthAfterFetch(posts.length);
    }
  }, [postStatus, posts]);

  useEffect(() => {
    if (!activeStudent) {
      setPostLengthBeforeFetch(0);
    }
  }, [activeStudent]);

  useEffect(() => {
    if (postStatus === "idle") {
      console.log("Teacher Wall");
      console.log(user.email);
      dispatch(
        fetchPosts({
          role: user.role,
          user_id: user.id,
          student_id: null,
          school_id: user.school_id,
          permissions: null,
          offset: 0,
        })
      ).unwrap();
    }
  }, [postStatus, dispatch, user]);

  useEffect(() => {
    if (teacherPermissionsStatus === "idle") {
      dispatch(
        fetchTeacherPermissions({
          search_by_adult_or_student: "adult",
          id: user?.id,
        })
      );
    }
  }, [dispatch, teacherPermissionsStatus, user.id]);

  useEffect(() => {
    if (teacherPermissionsStatus === "succeeded") {
      teacherPermissions?.length === 0 && setTourSwitch(true);
    }
  }, [teacherPermissions, teacherPermissionsStatus, setTourSwitch]);

  content = (
    <div className="row">
      <ReactJoyride
        steps={teacherTour}
        debug={true}
        continuous
        run={tourSwitch}
        showOverlay={true}
        showProgress
        hideCloseButton
        showStepsProgress={true}
        styles={joyrideStyles}
        callback={() => console.log("done")}
      />
      <div className="col-lg-3 d-none d-lg-block">
        <LeftPanel />
      </div>
      <div className="col-12 col-lg-6">
        <StudentCarousel
          activeStudent={activeStudent}
          setActiveStudent={setActiveStudent}
          teacherpermissions={teacherPermissions}
          posts={posts}
        />

        <Posts
          posts={posts}
          postStatus={postStatus}
          activeStudent={activeStudent}
          setActiveStudent={setActiveStudent}
          filterposts={filterPosts}
          setfilterposts={setFilterPosts}
        />

        {postLengthAfterFetch === postLengthBeforeFetch &&
        postStatus === "succeeded" ? (
          <p className="text-center text-secondary">
            {t("posts.noUnreviewedPosts")}
          </p>
        ) : (
          <>
            <p className="mb-0 text-center">{t("posts.Scroll for more")}</p>
            <ChevronDown className="text-secondary" />
          </>
        )}
      </div>
      <div className="col-lg-3 d-none d-lg-block">
        <RightPanel
          teacherPermissions={teacherPermissions}
          activeStudent={activeStudent}
          setActiveStudent={setActiveStudent}
        />
      </div>
      <SurveyModal />
    </div>
  );

  return content;
}
