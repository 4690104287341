import React from "react";
import Modal from "react-bootstrap/Modal";
import SelectDistrict from "./SelectDistrict";
import SchoolLevelInput from "./SchoolLevelInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextInput from "./TextInput";
import {
  faBuilding,
  faEnvelope,
  faLevelUp,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";
import schoolLevelsList from "../../../utils/schoolLevels";

export default function AddSchoolModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add School</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-small">
          <div className="mb-2">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <FontAwesomeIcon icon={faSchool} className="fa-fw" />
              </div>
              <div className="w-100">
                <TextInput
                  name="school_name"
                  value={props.formData.school_name}
                  onChange={props.formHandler}
                  edit={true}
                />
              </div>
            </div>
            {props.errors?.school_name && (
              <p className="text-danger text-xs m-0">* Enter a school name</p>
            )}
          </div>

          <div className="mb-2">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <FontAwesomeIcon icon={faLevelUp} className="fa-fw" />
              </div>
              <div className="w-100">
                <SchoolLevelInput
                  value={props.formData.level}
                  onChange={props.formHandler}
                  options={schoolLevelsList}
                />
              </div>
            </div>

            {props.errors?.level && (
              <p className="text-danger text-xs m-0">* Select a level</p>
            )}
          </div>
          <div className="mb-2">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <FontAwesomeIcon icon={faBuilding} className="fa-fw" />
              </div>
              <div className="w-100">
                <SelectDistrict
                  onChange={props.formHandler}
                  value={props.formData.district_id}
                />
              </div>
            </div>
            {props.errors?.district_id && (
              <p className="text-danger text-xs m-0">* Select a district</p>
            )}
          </div>

          <div className="form-group mb-1">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
              </div>
              <label className="d-none" htmlFor="TeacherEmail">
                Lead Teacher Email
              </label>

              <TextInput
                name="teacher_email"
                onChange={props.formHandler}
                value={props.formData.teacher_email}
              />
            </div>
            {props.errors?.teacher_email && (
              <p className="text-danger text-xs m-0">* Invalid Email</p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            props.onFormSubmit(e);
          }}>
          Add School
        </button>
      </Modal.Footer>
    </Modal>
  );
}
