import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import { fetchPosts, selectAllPosts } from "../../reducers/postsSlice";
import PostCard from "../Posts/PostCard";

export default function ShowPostModalModal(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const posts = useSelector(selectAllPosts);
  const activePost = props.activePost;
  const postExists = posts?.includes((item) => item.post?.id === activePost);
  const [post, setPost] = useState([]);

  useEffect(() => {
    setPost(posts?.filter((item) => item.post?.id === activePost));
  }, [setPost, posts, activePost]);

  useEffect(() => {
    if (postExists) {
      dispatch(
        fetchPosts({
          role: user.role,
          user_id: user?.id,
          student_id: null,
          school_id: user.school_id,
          goal_id: null,
          post_id: activePost,
          permissions: null,
          offset: 0,
        })
      ).unwrap();
      setPost(posts?.filter((item) => item.post?.id === activePost));
    }
  }, [
    posts,
    props.activePost,
    user,
    dispatch,
    setPost,
    activePost,
    postExists,
  ]);
  const content = postExists ? (
    <p className="text-center">There are no posts for this goal</p>
  ) : (
    post.map((item) => (
      <PostCard
        key={item.post?.id}
        id={item.post?.id}
        author_id={item.post?.author_id}
        profile_pic_src={item.post?.profile_pic_src}
        datetime={item.post?.timestamp}
        first_name={item.post?.first_name}
        last_name={item.post?.last_name}
        statement={item.post?.statement}
        statement_fr={item.post?.statement_fr}
        reflection={item.post?.content}
        selfeval={item.post?.updated_grade}
        replies={item?.replies}
        liked={item?.post_likes.liked}
        imageboolean={item.post?.image_boolean}
        audioboolean={item.post?.audio_boolean}
        status={item.post?.status}
      />
    ))
  );
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
}
