import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function GoalCardSkeleton() {
  const skeletonContent = (
    <div className="mb-3 mx-auto d-flex justify-content-center">
      {/* Skeleton Circle */}
      <Skeleton
        height={"220px"}
        width={"180px"}
        baseColor="white"
        className="carousel-card"
      />
    </div>
  );
  return skeletonContent;
}
