import React from "react";
import Likert from "react-likert-scale";
import { evalScaleEn, evalScaleFr } from "../AddGoal/AddGoalForm";
import { useTranslation } from "react-i18next";

export default function SelfEvaluationSlider2(props) {
  const { i18n } = useTranslation();
  const evalScale = i18n.language === "en" ? evalScaleEn : evalScaleFr;
  const likertOptions = {
    responses: [
      { value: 1, text: "1" },
      { value: 2, text: "2" },
      { value: 3, text: "3" },
      { value: 4, text: "4" },
    ],
    onChange: (val) => {
      props.setSelfEval(val.value);
    },
  };
  return (
    <>
      <Likert {...likertOptions} />
      <h5 className="text-center">{evalScale[props.selfEval]}</h5>
    </>
  );
}
