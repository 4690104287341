import React from "react";
import videoData from "./VideoData";

export default function VideosMenu(props) {
  return (
    <>
      <div className="container">
        <div className="row">
          {videoData[props.activeInterface]?.map((item, i) => (
            <div key={i} className="col-12 col-lg-4 mb-2 mx-auto">
              <div
                className="card shadow hover-effect video-card shadow cursor-pointer mx-2 py-5 mb-4 mb-lg-0"
                onClick={() => props.setActiveVideo(item.name)}>
                <div className="gallery-item">
                  <img
                    className="vw-10 d-block mx-auto"
                    src={item.image}
                    loading="lazy"
                    alt="goals"
                  />
                </div>
                <h5 className="text-center fw-bold pt-3">
                  {i + 1}. {item.titleEn}
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
