import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselCard from "./CarouselCards";
import "./GoalsCarouselStyles.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchGoals, selectGoalsByStatus } from "../../reducers/goalsSlice";
import GoalCardModal from "./GoalCardModal";
import GoalCardSkeleton from "./GoalCardSkeleton";

export default function GoalsCarousel(props) {
  const dispatch = useDispatch();
  const goals = useSelector((state) =>
    selectGoalsByStatus(state, "In Progress")
  );
  const goalStatus = useSelector((state) => state.goals.status);

  useEffect(() => {
    if (goalStatus === "idle") {
      dispatch(fetchGoals(props.user.id));
    }
  }, [goalStatus, dispatch, props.user]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: goals?.length < 3 ? goals?.length : 3,
    slidesToScroll: 1,
    initialSlide: 0, //Index of selected goal or 0

    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: goals?.length < 3 ? goals?.length : 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: goals?.length < 2 ? goals?.length : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1.6,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      {goals?.length !== 0 && (
        <div className="carousel-container" style={{ height: "14rem" }}>
          <Slider {...settings}>
            {goalStatus === "succeeded"
              ? goals?.map((item) => (
                  <CarouselCard
                    key={item?.id}
                    id={item?.id}
                    active={props.goalselected === item.id}
                    onClick={() => props.onClick(item.id)}
                    title={item?.strand}
                    title_fr={item?.strand_fr}
                    description={item?.statement}
                    description_fr={item?.statement_fr}></CarouselCard>
                ))
              : Array.from({ length: 3 }, (_, index) => <GoalCardSkeleton />)}

            <GoalCardModal
              goals={goals}
              showModal={props.showModal}
              setShowModal={props.setShowModal}
              goalselected={props.goalselected}
              setgoalselected={props.setgoalselected}
              show={props.showModal}
              setshow={props.setShowModal}
              setmakepostmodalshow={props.setmakepostmodalshow}
              onHide={() => {
                props.setShowModal(false);
                props.setgoalselected(null);
              }}
              setfilterposts={props.setfilterposts}
            />
          </Slider>
        </div>
      )}
    </>
  );
}
