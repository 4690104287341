import React, { useEffect, useState, useMemo } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import { selectDistrictGoals } from "../../reducers/sessionSlice";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import StatusColours from "../Progress/StatusColours";
import "./Styles.css";
import GoalStatusDropdown from "./GoalStatusDropdown";
import ActivityFinderModal from "../ActivityFinder/ActivityFinderModal";

export default function GoalsAccordion(props) {
  const statuses = ["In Progress", "Pass", "Incomplete"];
  const { t, i18n } = useTranslation();
  const districtGoals = useSelector(selectDistrictGoals);
  const sortedDistrictGoals = [...districtGoals];
  const progressData = props.progressData;
  const [goalsList, setGoalsList] = useState({
    Completed: [],
    "In Progress": [],
  });

  let content;
  const [statement, setStatement] = useState("");
  const [showActivityFinderModal, setShowActivityFinderModal] = useState(false);

  const openActivityFinder = (requested_statement) => {
    setStatement(requested_statement);
    setShowActivityFinderModal(true);
  };

  const closeActivityFinder = () => {
    setStatement("");
    setShowActivityFinderModal(false);
  };

  const [strandsEn, levelByStrand] = useMemo(() => {
    const strands = [...new Set(districtGoals?.map((item) => item.strand))];
    let levelByStrand = {};
    try {
      for (let data of progressData) {
        for (let strand of strands) {
          if (data.strand === strand) {
            levelByStrand[strand] = data.grade;
          }
        }
      }
    } catch {
      console.log("Progress data was not loaded for this student.");
    }
    return [strands, levelByStrand];
  }, [districtGoals, progressData]);

  useEffect(() => {
    let completedArray = [];
    let inProgressArray = [];
    let incompleteArray = [];

    try {
      for (let item of progressData) {
        if (item.strand !== "Overall") {
          for (let goal of item.in_progress) {
            inProgressArray.push(goal);
          }
          for (let goal of item.goals_completed_id) {
            completedArray.push(goal);
          }
        }
      }
    } catch {
      console.log("No progress data available.");
    }

    strandsEn?.map((strand) => {
      return districtGoals?.map(
        (item) =>
          !completedArray.includes(item.id) &&
          !inProgressArray.includes(item.id) &&
          item.strand === strand &&
          levelByStrand[strand] === item.level &&
          incompleteArray.push(item.id)
      );
    });

    setGoalsList({
      Pass: completedArray,
      "In Progress": inProgressArray,
      Incomplete: incompleteArray,
    });
    // setInProgressGoals(inProgressArray);
  }, [progressData, districtGoals, strandsEn, levelByStrand]);

  content = (
    <>
      <StatusColours />
      <Accordion defaultActiveKey="0">
        {statuses?.map((status, index) => {
          return (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>
                {status === "In Progress" && (
                  <FontAwesomeIcon icon={faCircle} color="goldenrod" />
                )}
                {status === "Pass" && (
                  <FontAwesomeIcon icon={faCircleCheck} color="green" />
                )}
                {status === "Incomplete" && (
                  <FontAwesomeIcon icon={faCircle} color="gray" />
                )}
                <span className="ps-3"> {t(`progress.${status}`)}</span>
              </Accordion.Header>
              <Accordion.Body key={index}>
                {progressData?.error ? (
                  <div className="alert alert-warning" role="alert">
                    {t("progress.This student has no grade entries")}.
                  </div>
                ) : (
                  sortedDistrictGoals
                    .sort((a, b) => a.strand.localeCompare(b.strand))
                    .map((item, index) => {
                      return goalsList[status]?.map(
                        (goalId) =>
                          goalId === item.id && (
                            <div
                              key={item.id}
                              className="row py-2 border-bottom">
                              <div className="col-2 d-flex justify-content-center align-items-center ">
                                <p className="m-0 fw-bold">
                                  {i18n.language === "en"
                                    ? item.strand
                                    : item.strand_fr}
                                </p>
                              </div>
                              <div className="col-9">
                                <p className="m-0 ">
                                  {i18n.language === "en"
                                    ? item.statement
                                    : item.statement_fr}
                                </p>
                              </div>
                              <div className="d-flex justify-content-center align-items-center col-1 cursor-pointer ">
                                <GoalStatusDropdown
                                  studentId={props.studentId}
                                  currentStatus={status}
                                  cefr_custom_id={item.id}
                                  strand={item.strand}
                                  statuses={statuses}
                                  findActivityHandler={() =>
                                    openActivityFinder(item.statement)
                                  }
                                />
                              </div>
                            </div>
                          )
                      );
                    })
                )}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );

  return progressData ? (
    <>
      {content}
      {showActivityFinderModal}
      <ActivityFinderModal
        show={showActivityFinderModal}
        handleClose={closeActivityFinder}
        statement={statement}
      />
    </>
  ) : (
    <p>{t("progress.This student has no grade entries")}.</p>
  );
}
