import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBListGroup } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { selectAllProgress } from "../../reducers/progressSlice";
import { selectGoalsByStatus } from "../../reducers/goalsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import { useTranslation } from "react-i18next";
import StatusColours from "./StatusColours";
import GoalStatusDropdown from "../TeacherWall/GoalStatusDropdown";

export default function ProgressModal(props) {
  const { i18n, t } = useTranslation();
  const user = useSelector(selectUser);
  const progress = useSelector((state) => selectAllProgress(state, user.id));
  const completedGoals = useSelector((state) =>
    selectGoalsByStatus(state, "Pass")
  );
  const [remainingGoals, setRemainingGoals] = useState(null);
  const goals = useSelector((state) =>
    selectGoalsByStatus(state, "In Progress")
  );

  useEffect(() => {
    let items = [];
    for (let item of progress[progress.length - 1]?.data.slice(1)) {
      if (props.strand === item.strand) {
        for (let goal of item.remaining_goals) {
          items.push({
            key: goal.id,
            id: goal.id,
            statement: goal.statement,
            statement_fr: goal.statement_fr,
          });
        }
      }
    }
    setRemainingGoals(items);
  }, [props.strand, progress]);

  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {i18n.language === "en" ? props.strand : props.strand_fr}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StatusColours />
        <MDBListGroup flush="true" className="rounded-3 mt-1">
          {completedGoals?.map(
            (item, index) =>
              item.strand === props.strand && (
                <div
                  key={index}
                  className="d-flex flex-row p-3 align-items-center justify-content-center appear-on-hover">
                  <div className="col-1" title={t("progress.Pass")}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="me-3"
                      color={"green"}
                    />
                  </div>
                  <div className="col-10">
                    {i18n.language === "en"
                      ? item.statement
                      : item.statement_fr}
                  </div>
                  <div className="col-1 d-flex justify-content-end">
                    <GoalStatusDropdown
                      studentId={user.id}
                      currentStatus={null}
                      cefr_custom_id={item.id}
                      strand={null}
                      statuses={null}
                      findActivityHandler={null}
                    />
                  </div>
                </div>
              )
          )}

          {goals?.map(
            (item, index) =>
              item.strand === props.strand && (
                <div
                  key={index}
                  className="d-flex flex-row p-3 align-items-center justify-content-center appear-on-hover ">
                  <div className="col-1" title={t("progress.In Progress")}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="me-3"
                      color={"goldenrod"}
                    />
                  </div>
                  <div className="col-10">
                    {i18n.language === "en"
                      ? item.statement
                      : item.statement_fr}
                  </div>
                  <div className="col-1 d-flex justify-content-end">
                    <GoalStatusDropdown
                      studentId={user.id}
                      currentStatus={null}
                      cefr_custom_id={item.cefr_custom_id}
                      strand={null}
                      statuses={null}
                      findActivityHandler={null}
                    />
                  </div>
                </div>
              )
          )}

          {remainingGoals?.map((item, index) => (
            <div
              key={index}
              className="d-flex flex-row p-3 align-items-center appear-on-hover ">
              <div className="col-1" title={t("progress.Incomplete")}>
                <FontAwesomeIcon
                  icon={faCircle}
                  className="me-3"
                  color={"gray"}
                />
              </div>

              <div className="col-10">
                {i18n.language === "en" ? item.statement : item.statement_fr}
              </div>
              <div className="col-1 d-flex justify-content-end">
                <GoalStatusDropdown
                  studentId={user.id}
                  currentStatus={null}
                  cefr_custom_id={item.id}
                  strand={null}
                  statuses={null}
                  findActivityHandler={null}
                />
              </div>
            </div>
          ))}
        </MDBListGroup>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
