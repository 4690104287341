import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-loading-skeleton/dist/skeleton.css";
import ProgressModal from "./ProgressModal";
import { useTranslation } from "react-i18next";

//Insert Progress Modal
//Show completed, incomplete, in progress goals

export default function StrandProgressCard(props) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Card
        onClick={() => setShowModal(true)}
        style={{
          width: "14rem",
          height: "18rem",
          borderRadius: "25px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}>
        <Card.Body
          className=" d-flex flex-column justify-content-center align-items-center hover-effect"
          style={{ height: "100%" }}>
          <CircularProgressbarWithChildren value={props.percentage}>
            <h4 className="fw-bold">{t(`addStudent.${props?.strand}`)}</h4>
          </CircularProgressbarWithChildren>
          <h5 className="text-center mt-3 mb-0">{props?.overall_cefr_grade}</h5>
          <p className="text-uppercase ">
            {props?.goals_completed} {t("progress.of")} {props.total_goals}
          </p>
        </Card.Body>
      </Card>
      <ProgressModal
        strand={props.strand}
        strand_fr={props.strand_fr}
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </>
  );
}
