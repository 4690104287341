import React from "react";
import Select from "react-select";
import { countryListEn, countryListFr } from "./countryList";
import { useDispatch } from "react-redux";
import { changeTextField } from "../../../reducers/sessionSlice";
import { changeTeacherPermissions } from "../../../reducers/teacherPermissionsSlice";
import { useTranslation } from "react-i18next";

export default function CountryDropdown(props) {
  const attributeName = "country0";
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const handleChange = async (e) => {
    try {
      props.setvalue(e.value);
    } catch {
      props.setvalue(null);
    }
  };
  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();

      onSave();
    }
  };
  const onSave = () => {
    dispatch(
      props.modal
        ? changeTeacherPermissions({
            id: props.id,
            attribute_name: attributeName,
            content: props.value,
          })
        : changeTextField({
            id: props.id,
            attribute_name: attributeName,
            content: props.value,
          })
    ).unwrap();

    props.setactive(false);
  };
  const countryList = i18n.language === "en" ? countryListEn : countryListFr;
  const selectedCountry = countryList.filter(
    (item) => item.value === props.value
  );
  const countryName = selectedCountry[0]?.label;
  return (
    <div className="w-100 d-flex flex-column align-items-end">
      {props.active.attribute !== attributeName && (
        <p
          className="m-0 cursor-pointer text-muted"
          onClick={(event) => {
            event.stopPropagation();
            props.setactive({ attribute: attributeName });
          }}>
          {countryName || t("profile.Select Country")}
        </p>
      )}
      {props.active.attribute === attributeName && (
        <>
          <Select
            options={countryList}
            defaultValue={countryList.filter(
              (option) => option.value === props.value
            )}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="text-start w-50"
            styles={{ width: "500px" }}
            placeholder={t("profile.Search Country...")}
          />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary btn-sm mt-2"
              onClick={() => {
                onSave();
                props.setactive(false);
              }}>
              {t("profile.Save")}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
