import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDistrictGoals } from "../../reducers/sessionSlice";
import { appLevelsReverse } from "../../utils/levels";
import ActivityFinderFilter from "./ActivityFinderFilter";

export default function DistrictGoalsTable(props) {
  const { t, i18n } = useTranslation();
  let districtGoalsRaw = useSelector(selectDistrictGoals);
  let districtGoals = [...districtGoalsRaw];
  i18n.language === "en"
    ? districtGoals.sort(
        (a, b) => a.level - b.level || a.strand.localeCompare(b.strand)
      )
    : districtGoals.sort(
        (a, b) => a.level - b.level || a.strand_fr.localeCompare(b.strand_fr)
      );
  const [filteredObjectives, setFilteredObjectives] = useState(districtGoals);

  return (
    <div
      className="d-flex flex-column align-items-center shadow p-2 bg-white text-small p-3"
      style={{ borderRadius: "10px" }}>
      <ActivityFinderFilter setFilteredObjectives={setFilteredObjectives} />
      <Table hover size="sm" className="m-0 p-0">
        <thead>
          <tr className="text-uppercase">
            <th className="text-secondary ">{t("addGoal.Level")}</th>
            <th className="px-3 text-secondary">{t("addGoal.Strand")}</th>
            <th className="text-secondary">{t("addGoal.Objective")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredObjectives?.map((goal, i) => {
            return (
              <tr
                key={i}
                className="cursor-pointer"
                onClick={() => {
                  props.setObjective(goal);
                  props.setShowActivityFinderModal(true);
                }}>
                <td style={{ whiteSpace: "nowrap" }}>
                  {appLevelsReverse[goal.level]?.toUpperCase()}
                </td>
                <td className="px-3">
                  {i18n.language === "fr" ? goal.strand_fr : goal.strand}
                </td>
                <td>
                  {i18n.language === "fr" ? goal.statement_fr : goal.statement}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
