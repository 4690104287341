import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import LevelSelect from "../AddStudent/LevelSelect";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import { changeProgress } from "../../reducers/progressTeacherSlice";

export default function ProgressTable(props) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const active = props.activeLevelChange;
  const setActive = props.setActiveLevelChange;
  const [formData, setFormData] = useState(null);
  const dispatch = useDispatch();
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      setActive(false);
    }
  };

  const formHandler = (e, action) => {
    setActive(false);

    setFormData({ [action.name]: e.value });
    dispatch(
      changeProgress({
        student_id: props.studentId,
        teacher_id: user?.id,
        attribute_name: "level",
        level: e.value,
        level_up: false,
        manual_grade_change: true,
        strand: action.name,
        change_goal_status: false,
        status: "Pass",
      })
    ).unwrap();
  };

  return (
    <div className="card w-100">
      <Table
        borderless
        className="text-center align-middle"
        onKeyDown={onKeyDownHandler}>
        <thead>
          <tr>
            <th></th>
            <th>{t("progress.Level")}</th>
            <th>{t("progress.Goals Completed")}</th>
          </tr>
        </thead>
        {props.progressData?.slice(1).map((item) => (
          <tbody key={item.strand}>
            <tr>
              <td className="fw-bold">{t(`addStudent.${item.strand}`)}</td>
              <td className="cursor-pointer">
                {active.strand === item.strand ? (
                  <LevelSelect
                    name={item.strand}
                    formData={formData}
                    className="text-center"
                    onChange={formHandler}
                    getValue={
                      formData?.[item.strand] ||
                      setFormData({ [item.strand]: item.grade })
                    }
                  />
                ) : (
                  <span
                    onClick={() => {
                      setActive({ strand: item.strand });
                    }}>
                    {item.overall_cefr_grade}
                  </span>
                )}
              </td>
              <td className="p-2">
                <p className="m-0">
                  {item.goals_completed} / {item.total_goals}
                </p>
              </td>
            </tr>
          </tbody>
        ))}
      </Table>
    </div>
  );
}
