function inverse(obj) {
  var retobj = {};
  for (var key in obj) {
    retobj[obj[key]] = key;
  }
  return retobj;
}

const appLevels = {
  "a1.0": 0,
  "a1.1": 1,
  "a1.2": 2,
  "a2.0": 3,
  "a2.1": 4,
  "a2.2": 5,
  "b1.0": 6,
  "b1.1": 7,
  "b1.2": 8,
  "b2.0": 9,
  "b2.1": 10,
  "b2.2": 11,
  "c1.0": 12,
  "c1.1": 13,
  "c1.2": 14,
};

export const appLevelsReverse = inverse(appLevels);

export default appLevels;
