import React, { useState } from "react";
import NavBar from "./Navbar/NavBar.jsx";
import FooterComponent from "./Footer/FooterComponent";
import "./PageLayoutStyles.css";
import { Outlet } from "react-router";
import { SearchContext } from "../contexts/SearchContext.jsx";
import "./PageLayoutStyles.css";

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */

export const PageLayout = (props) => {
  const [query, setQuery] = useState("");
  const [queriedStudents, setQueriedStudents] = useState("");

  return (
    <>
      <SearchContext.Provider
        value={{ query, setQuery, queriedStudents, setQueriedStudents }}>
        <NavBar />
        <div className="bg-main py-4 main-container">
          <Outlet />
        </div>
        <FooterComponent />
      </SearchContext.Provider>
    </>
  );
};
